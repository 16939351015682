import { isNil } from 'lodash-es';
import {
  ModQuizGetUserAttemptsData,
  ModQuizGetUserAttemptsHookArgs,
  ModQuizGetUserAttemptsQuery
} from '../moodleTypes/_functions/mod_quiz_get_user_attempts';
import { useMoodleQuery } from '../useMoodleQuery';

export function useQuizUserAttempts({ quizid, moodleConfig, ...props }: Partial<ModQuizGetUserAttemptsHookArgs>) {
  return useMoodleQuery<ModQuizGetUserAttemptsQuery, ModQuizGetUserAttemptsData>(
    {
      wsfunction: 'mod_quiz_get_user_attempts',
      quizid: quizid ?? 0,
      ...props
    },
    {
      ...moodleConfig,
      skip: isNil(quizid) || moodleConfig?.skip
    }
  );
}
