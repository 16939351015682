import { useSiteInfoData } from '../app/providers/siteInfo';
import { insertAccessKeyIntoUrl } from '../utils/insertAccessKeyIntoUrl';
import { IModuleContents } from '../store/moodleAPI/moodleTypes/Module';

export type AttachmentType<T> = T & Pick<IModuleContents, 'fileurl'>;

function convertAttachmentUrl<T>(attachment: AttachmentType<T>, accessKey?: string): AttachmentType<T>;
function convertAttachmentUrl<T>(attachment?: AttachmentType<T>, accessKey?: string): AttachmentType<T> | undefined {
  if (!attachment) return undefined;
  if (!accessKey || !attachment.fileurl) return attachment;
  return {
    ...attachment,
    fileurl: insertAccessKeyIntoUrl(attachment.fileurl, accessKey)
  };
}

export function useAttachments<T>(attachments?: AttachmentType<T>): AttachmentType<T> | undefined;
export function useAttachments<T>(attachments?: AttachmentType<T>[]): AttachmentType<T>[] | undefined;
export function useAttachments<T>(
  attachments?: AttachmentType<T> | AttachmentType<T>[]
): AttachmentType<T> | AttachmentType<T>[] | undefined {
  const { userprivateaccesskey } = useSiteInfoData();

  if (!attachments) return undefined;

  if (Array.isArray(attachments)) {
    return attachments.map((att) => convertAttachmentUrl(att, userprivateaccesskey));
  }

  return convertAttachmentUrl(attachments, userprivateaccesskey);
}
