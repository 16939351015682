import { Heading, HeadingSize } from 'components/Heading';
import { Text, TextVariant } from 'components/Text';
import { htmlToInlineText } from 'utils/html';
import s from './TeacherInfo.module.scss';

export interface TeacherInfoProps {
  description: string;
}

export const TeacherInfo = ({ description }: TeacherInfoProps) => {
  return (
    <div className={s.TeacherInfo}>
      <Heading className={s.TeacherInfo__title} component={'h2'} size={HeadingSize.H5}>
        Информация
      </Heading>
      <Text className={s.TeacherInfo__text} variant={TextVariant.BODY_S}>
        {htmlToInlineText(description)}
      </Text>
    </div>
  );
};
