import { useCallback } from 'react';
import { useMoodleMutation } from '../useMoodleMutation';
import {
  CoreCompletionUpdateActivityCompletionStatusManuallyData,
  CoreCompletionUpdateActivityCompletionStatusManuallyQuery
} from '../moodleTypes/_functions/core_completion_update_activity_completion_status_manually';
import { CompletionState } from '../moodleTypes/Module';

export const useModuleSetCompletion = () => {
  const { mutate, ...props } = useMoodleMutation();

  const run = useCallback(
    (moduleId: number, completion: CompletionState) => {
      return mutate<
        CoreCompletionUpdateActivityCompletionStatusManuallyQuery,
        CoreCompletionUpdateActivityCompletionStatusManuallyData
      >({
        wsfunction: 'core_completion_update_activity_completion_status_manually',
        cmid: moduleId,
        completed: completion
      });
    },
    [mutate]
  );

  return {
    mutate: run,
    ...props
  };
};
