import React from 'react';
import { Link, To } from 'react-router-dom';
import clsx from 'clsx';
import { IModule } from 'store/moodleAPI/moodleTypes/Module';
import { useTooltip } from 'components/Tooltip';
import { htmlToInlineText } from 'utils/html';
import { ModuleVariant } from 'utils/courses/CoursesUtils';
import s from './Module.module.scss';

export interface ModuleProps extends IModule {
  index?: number | string;
  variant?: ModuleVariant;
  to: To;
  sectionLocked?: boolean;
}

export const Module = ({
  index,
  variant = ModuleVariant.Normal,
  name,
  to,
  availabilityinfo,
  sectionLocked
}: ModuleProps) => {
  const locked = variant === ModuleVariant.Locked;

  const {
    component: tooltip,
    setTargetRef,
    targetProps
  } = useTooltip({
    placement: 'bottom',
    title: 'Заблокирован',
    description: htmlToInlineText(availabilityinfo),
    enabled: locked && !sectionLocked
  });

  return (
    <Link
      ref={setTargetRef}
      {...targetProps}
      className={clsx(s.Module, s[`Module_${variant}`], {
        [s.Module_disabled]: locked
      })}
      to={to}
      onClick={(e) => {
        if (locked) {
          e.preventDefault();
        }
      }}>
      <div className={s.Module__head}>
        <div className={s.Module__dot} />
        <div className={s.Module__title}>
          {index && <div className={s.Module__index}>{index}</div>}
          {name}
        </div>
      </div>
      {tooltip}
    </Link>
  );
};
