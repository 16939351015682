import { useState, useEffect, useRef } from 'react';
import { Swiper as SwiperType } from 'swiper';
import { useCustomCert } from 'app/providers/custom-cert';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { Heading, HeadingSize } from 'components/Heading';
import { Loader, LoaderSize } from 'components/Loader';
import { Carousel } from 'components/Carousel/Carousel';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { Icons } from 'components/Icons';
import { CardCertificate, CardCertificateVariant } from '../CardCertificate/CardCertificate';
import certError from '../../_assets/cert-error.svg';
import { MoodleAuthErrorPlaceholder } from '../../../../components/MoodleAuthErrorPlaceholder';
import { useAuth } from '../../../../app/providers/auth';
import s from './ProfileContent.module.scss';

export const ProfileContent = () => {
  const { certs, isLoading, error } = useCustomCert();
  const { logOut } = useAuth();

  const isTabletSmall = useMediaQueryResult(mediaQuery.mobileLargeUp);
  const isTabletLarge = useMediaQueryResult(mediaQuery.tabletLargeUp);
  const isDesktop = useMediaQueryResult(mediaQuery.desktopMediumUp);

  const [minCertsLength, setMinCertsLength] = useState(1);

  useEffect(() => {
    isDesktop
      ? setMinCertsLength(4)
      : isTabletLarge
      ? setMinCertsLength(3)
      : isTabletSmall
      ? setMinCertsLength(2)
      : setMinCertsLength(1);
  }, [isDesktop, isTabletLarge, isTabletSmall]);

  const plugCerts = Array.from({ length: minCertsLength - certs.length }, (v, i) => i + 1);
  const swiperRef = useRef<SwiperType>();

  return (
    <div className={s.ProfileContent}>
      <div className={s.ProfileContent__headline}>
        <Heading className={s.ProfileContent__title} component={'h3'} size={HeadingSize.H4}>
          Мои сертификаты
        </Heading>
        {certs.length + plugCerts.length > minCertsLength && (
          <div className={s.ProfileContent__navigation}>
            <IconButton
              variant={IconButtonVariant.tertiary}
              icon={Icons.ARROW_LEFT}
              rounded
              onClick={() => swiperRef.current?.slidePrev()}
            />
            <IconButton
              variant={IconButtonVariant.tertiary}
              icon={Icons.ARROW_RIGHT}
              rounded
              onClick={() => swiperRef.current?.slideNext()}
            />
          </div>
        )}
      </div>

      <div className={s.ProfileContent__content}>
        {isLoading ? (
          <Loader className={s.ProfileContent__loader} size={LoaderSize.large} />
        ) : error ? (
          <MoodleAuthErrorPlaceholder
            className={s.ProfileContent__placeholder}
            logOut={() => logOut(undefined, true)}
          />
        ) : !certs.length ? (
          <div className={s.ProfileContent__error}>
            <img className={s.ProfileContent__image} src={certError} alt="" />
            <p className={s.ProfileContent__warning}>У вас на данный момент нет сертификатов</p>
          </div>
        ) : (
          <Carousel
            slideClassName={s.ProfileContent__item}
            pagination={{
              clickable: true,
              enabled: certs.length + plugCerts.length > minCertsLength
            }}
            onBeforeInit={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 16
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 24
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 24
              }
            }}>
            {certs.map((cert, index) => (
              <CardCertificate key={`certificate-${index}`} image={cert.image} url={cert.url} />
            ))}
            {plugCerts.map((plugCert, index) => (
              <CardCertificate key={`certificate-plug-${index}`} variant={CardCertificateVariant.plug} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};
