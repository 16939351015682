import { generatePath, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { ICourseEvent } from '../../../../store/moodleAPI/moodleTypes/_functions/core_calendar_get_action_events_by_course';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { Button, ButtonSize, ButtonVariant } from '../../../../components/Button';
import { Link } from '../../../../components/Link';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { COURSE_MODULE_PAGE_ROUTE } from '../../../../app/routes/routes';
import { EIconsTypes, Icons } from '../../../../components/Icons';
import { ModuleName } from '../../../../store/moodleAPI/moodleTypes/Module';
import { Icon, IconSize } from '../../../../components/Icon';
import { Badge, BadgeVariant } from '../../../../components/Badge';
import s from './CourseEvent.module.scss';

export interface CourseTimelineCardProps extends ICourseEvent {
  className?: string;
}

type ModuleIcon = {
  small: EIconsTypes;
  medium: EIconsTypes;
};

const icons: { [key in ModuleName]?: ModuleIcon } = {
  [ModuleName.lesson]: {
    small: EIconsTypes.SUBJECT_SMALL,
    medium: EIconsTypes.SUBJECT_MEDIUM
  },
  [ModuleName.resource]: {
    small: EIconsTypes.SUBJECT_SMALL,
    medium: EIconsTypes.SUBJECT_MEDIUM
  },
  [ModuleName.quiz]: {
    small: EIconsTypes.TEST2_SMALL,
    medium: EIconsTypes.TEST3_SMALL
  },
  [ModuleName.page]: {
    small: EIconsTypes.SUBJECT_SMALL,
    medium: EIconsTypes.SUBJECT_MEDIUM
  },
  [ModuleName.assign]: {
    small: EIconsTypes.TEST2_SMALL,
    medium: EIconsTypes.TEST3_SMALL
  }
};

export const CourseEvent: ReactFCC<CourseTimelineCardProps> = (props) => {
  const { className, timesort, activityname, activitystr, action, course, instance, modulename, overdue } = props;

  const isMobile = useIsMobile();

  const moduleLink =
    course && !isNil(instance)
      ? generatePath(COURSE_MODULE_PAGE_ROUTE, {
          courseId: course.id,
          moduleId: instance
        })
      : '';

  const icon = icons[modulename as ModuleName] || (icons[ModuleName.lesson] as ModuleIcon);
  const iconElement = (
    <div className={clsx(s.CourseEvent__iconWrap, s[`CourseEvent__iconWrap_${modulename}`])}>
      <Icon icon={(isMobile ? icon.small : icon.medium) || Icons.SUBJECT_SMALL} />
    </div>
  );

  return (
    <div className={clsx(s.CourseEvent, className)}>
      <div className={s.CourseEvent__head}>
        {isMobile && iconElement}
        <span className={s.CourseEvent__time}>{format(new Date(timesort * 1000), 'HH:mm')}</span>
      </div>

      {!isMobile && iconElement}

      <div className={s.CourseEvent__content}>
        <Link component={RouterLink} to={moduleLink} className={s.CourseEvent__name} underlined={false}>
          {activityname}
        </Link>
        <span className={s.CourseEvent__description}>{replaceActivityName(activitystr)}</span>
      </div>

      {overdue && (
        <Badge variant={BadgeVariant.bad} left={<Icon icon={Icons.CLOCK_SMALL} size={IconSize.small} />}>
          Просрочено
        </Badge>
      )}

      {!!action && action.actionable && (
        // Сделать переход на конкретное действие, например, страницу отправки ответа в assignment
        <Button component={RouterLink} to={moduleLink} variant={ButtonVariant.tertiary} size={ButtonSize.small}>
          {action.name}
        </Button>
      )}
    </div>
  );
};

function replaceActivityName(name: string = '') {
  return name.replace('requires action', 'требует действия');
}
