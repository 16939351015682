import clsx from 'clsx';
import { Container } from 'components/Container';
import { Heading, HeadingSize } from 'components/Heading';
import { Tabs, TabsProps } from 'components/Tabs';
import { ViewSwitch, ViewSwitchOption } from 'components/ViewSwitch';
import { Icons } from 'components/Icons';
import { useSearchParams } from 'hooks/useSearchParams';
import { CoreCourseGetEnrolledCoursesClassification } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';
import { useEnrolledCourses } from 'store/moodleAPI/hooks';
import { DataPlaceholder } from '../../components/DataPlaceholder/DataPlaceholder';
import { Loader, LoaderSize } from '../../components/Loader';
import { useIsMobile } from '../../hooks/useIsMobile';
import { CoursesSort, coursesSortString, useCoursesFilters } from './useCoursesFilters';
import { CourseCard, CourseCardVariant } from './_components/CourseCard/CourseCard';
import s from './HomePage.module.scss';

const tabs: TabsProps<CoreCourseGetEnrolledCoursesClassification>['tabs'] = [
  {
    id: CoreCourseGetEnrolledCoursesClassification.All,
    label: 'Все'
  },
  {
    id: CoreCourseGetEnrolledCoursesClassification.InProgress,
    label: 'В процессе'
  },
  {
    id: CoreCourseGetEnrolledCoursesClassification.Future,
    label: 'Предстоящие'
  },
  {
    id: CoreCourseGetEnrolledCoursesClassification.Past,
    label: 'Завершенные'
  },
  {
    id: CoreCourseGetEnrolledCoursesClassification.Hidden,
    label: 'Скрытые'
  },
  {
    id: CoreCourseGetEnrolledCoursesClassification.Favourite,
    label: 'Избранные'
  }
];

enum ViewModes {
  Card = 'card',
  List = 'list'
}

const viewOptions: ViewSwitchOption<ViewModes>[] = [
  {
    icon: Icons.VIEW_CARD,
    value: ViewModes.Card
  },
  {
    icon: Icons.VIEW_LIST,
    value: ViewModes.List
  }
];

export function HomePage() {
  const isMobile = useIsMobile();
  const [query, changeQuery] = useSearchParams(['tab', 'view']);
  const [filtersComponent, filter] = useCoursesFilters();

  const viewFilter = {
    tab: (query.tab || CoreCourseGetEnrolledCoursesClassification.All) as CoreCourseGetEnrolledCoursesClassification,
    view: (isMobile ? ViewModes.Card : query.view || ViewModes.Card) as ViewModes
  };

  const handleTabChange = (tab: CoreCourseGetEnrolledCoursesClassification) => {
    changeQuery(
      {
        tab
      },
      true
    );
  };
  const handleViewChange = (view: ViewModes) => {
    changeQuery(
      {
        view
      },
      true
    );
  };

  const { data, isLoading } = useEnrolledCourses({
    classification: viewFilter.tab,
    searchvalue: filter.search,
    sort: coursesSortString[filter.sort as CoursesSort]
  });
  const courses = data?.courses;

  const noData = !courses?.length;
  const noSearchData = filter.search && noData;

  return (
    <Container className={s.HomePage}>
      <Heading className={s.HomePage__title} component={'h2'} size={HeadingSize.H2} pageTitle={'Курсы'}>
        Курсы
      </Heading>

      <Tabs tabs={tabs} activeId={viewFilter.tab} setActive={handleTabChange} />

      <div className={s.HomePage__filters}>
        {filtersComponent}

        {!isMobile && <ViewSwitch options={viewOptions} value={viewFilter.view} onChange={handleViewChange} />}
      </div>

      <div
        className={clsx(s.HomePage__content, {
          [s.HomePage__content_centered]: isLoading || noData
        })}>
        {isLoading ? (
          <Loader size={LoaderSize.large} />
        ) : noData ? (
          <DataPlaceholder
            icon={Icons.PLACEHOLDER_SEARCH}
            description={
              noSearchData ? (
                <>
                  По вашему запросу курсы не найдены. <br />
                  Попробуйте изменить ваш запрос
                </>
              ) : (
                'Курсы не найдены'
              )
            }
          />
        ) : (
          <div className={clsx(s.HomePage__list, s[`HomePage__list_${viewFilter.view}`])}>
            {courses.map((item) => (
              <CourseCard
                key={item.id}
                {...item}
                variant={viewFilter.view === ViewModes.Card ? CourseCardVariant.Vertical : CourseCardVariant.Horizontal}
              />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
}
