import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { findLast, isNil } from 'lodash-es';
import { ISection } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_contents';
import { Collapse, CollapseProps, CollapseVariant } from 'components/Collapse';
import { CoursesUtils } from 'utils/courses/CoursesUtils';
import { useTooltip } from 'components/Tooltip';
import { htmlToInlineText } from 'utils/html';
import { Modules } from '../Modules/Modules';
import s from './CourseProgram.module.scss';

interface SectionCollapseProps extends CollapseProps {
  section: ISection;
}

const SectionCollapse = ({ section, ...props }: PropsWithChildren<SectionCollapseProps>) => {
  const locked = props.variant === CollapseVariant.Locked;

  const { component: tooltip, setTargetRef } = useTooltip({
    placement: 'top',
    title: 'Заблокирован',
    description: htmlToInlineText(section.availabilityinfo),
    enabled: locked
  });

  return (
    <>
      <Collapse ref={setTargetRef} {...props} />
      {tooltip}
    </>
  );
};

export interface CourseProgramProps {
  courseId?: string;
  contents?: ISection[];
}

export const CourseProgram = ({ courseId, contents }: CourseProgramProps) => {
  const { state } = useLocation();
  const stateModuleId = state?.moduleId; // ModulePage goBack() state

  const activeSectionId = useMemo(() => {
    if (!contents?.length) return null;

    /**
     * самая последняя активная секция в списке
     */
    const lastestSectionInProgress = findLast(
      contents.map((c) => ({
        id: c.id,
        variant: CoursesUtils.mapCourseContentToCollapseVariant(c)
      })),
      (c) => c.variant === CollapseVariant.InProgress
    );

    if (isNil(stateModuleId)) {
      return lastestSectionInProgress?.id;
    }

    /**
     * секция, содержащая модуль, с которого юзер вернулся назад (goBack())
     */
    const sectionByModuleId = contents.find((c) => c.modules.find((m) => m.id === stateModuleId));

    /**
     * открываем либо секцию по модулю, либо самую последнюю активную
     */
    return sectionByModuleId?.id ?? lastestSectionInProgress?.id;
  }, [contents, stateModuleId]);

  /**
   * очищаем стейт при перезагрузке страницы,
   * чтобы сбросить логику переходов
   */
  useEffect(() => {
    window.history.replaceState(null, '');
  }, []);

  return (
    <div className={s.CourseProgram}>
      {contents?.map((item, iItem) => {
        const variant = CoursesUtils.mapCourseContentToCollapseVariant(item);
        const modules = CoursesUtils.filterSectionModules(item);
        return (
          <SectionCollapse
            key={iItem}
            index={iItem + 1}
            title={item.name}
            variant={variant}
            section={item}
            open={item.id === activeSectionId}>
            <Modules
              index={iItem + 1}
              courseId={courseId}
              modules={modules}
              sectionLocked={variant === CollapseVariant.Locked}
            />
          </SectionCollapse>
        );
      })}
    </div>
  );
};
