import { Button, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';

import s from './ProfileFooter.module.scss';

export interface ProfileFooterProps {
  logOut: (error?: Error) => void;
}

export const ProfileFooter: React.FC<ProfileFooterProps> = ({ logOut }) => {
  return (
    <div className={s.ProfileFooter}>
      <Button variant={ButtonVariant.ghost} leftIcon={Icons.LOGOUT} onClick={() => logOut()}>
        Выйти
      </Button>
    </div>
  );
};
