import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizGetQuizzesByCoursesData,
  ModQuizGetQuizzesByCoursesHookArgs,
  ModQuizGetQuizzesByCoursesQuery
} from '../moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';

export function useQuizzesByCourses({ moodleConfig, courseids }: Partial<ModQuizGetQuizzesByCoursesHookArgs>) {
  return useMoodleQuery<ModQuizGetQuizzesByCoursesQuery, ModQuizGetQuizzesByCoursesData>(
    {
      wsfunction: 'mod_quiz_get_quizzes_by_courses',
      courseids
    },
    {
      ...moodleConfig,
      skip: !courseids?.length || moodleConfig?.skip
    }
  );
}
