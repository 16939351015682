import React, { useState } from 'react';
import { Container } from 'components/Container';
import { Heading, HeadingSize } from 'components/Heading';
import { Divider } from 'components/Divider';
import { htmlToInlineText } from 'utils/html';
import { MoodleAssignment } from 'components/MoodleAssignment';
import { ButtonBackBox } from 'components/Button/ButtonBack';
import { CompletionMode, CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { ModulePageContextType } from '../../ModulePageProvider';
import { PageActions } from '../../_components/PageActions/PageActions';
import s from './Assignment.module.scss';

export interface AssignmentProps {
  data: ModulePageContextType['data'];
  submitLoading: boolean;
  onModuleSubmit: ModulePageContextType['submitModule'];
  onModuleReload: ModulePageContextType['refetchModule'];
  onGoBack?: () => void;
}

export const Assignment = ({ data, submitLoading, onModuleSubmit, onModuleReload, onGoBack }: AssignmentProps) => {
  const [loading, setLoading] = useState(true);

  const {
    course,
    module,
    currentModuleContents,
    previousModuleContents,
    nextModuleContents,
    prevModuleLink,
    nextModuleLink
  } = data;

  const shouldBeSubmitted =
    module?.completion === CompletionMode.Manual &&
    currentModuleContents?.completiondata?.state === CompletionState.Incomplete;
  const prevModuleAvailability = previousModuleContents?.availabilityinfo;
  const nextModuleAvailability = nextModuleContents?.availabilityinfo;

  return (
    <Container className={s.Assignment}>
      {onGoBack && <ButtonBackBox onGoBack={onGoBack} />}

      <Heading
        className={s.Assignment__heading}
        component={'h2'}
        size={HeadingSize.H2}
        pageTitle={[module?.name, course?.fullname]}>
        {module?.name}
      </Heading>

      <div className={s.Assignment__content}>
        {module && (
          <MoodleAssignment
            className={s.Assignment__iframeBox}
            moduleId={module.id}
            loading={loading}
            setLoading={setLoading}
            onReloadRequest={onModuleReload}
          />
        )}
      </div>

      <Divider className={s.Assignment__actionsDivider} />

      <PageActions
        prevLink={prevModuleLink}
        nextLink={nextModuleLink}
        prevDisabled={loading || !!prevModuleAvailability}
        nextDisabled={loading || !!nextModuleAvailability}
        prevDisabledMessage={htmlToInlineText(prevModuleAvailability)}
        nextDisabledMessage={htmlToInlineText(nextModuleAvailability)}
        prevTooltipDisabled={loading}
        nextTooltipDisabled={loading}
        shouldBeSubmitted={shouldBeSubmitted}
        submitLoading={submitLoading || loading}
        submitDisabled={loading}
        onSubmitClick={onModuleSubmit}
      />
    </Container>
  );
};
