import {
  useQuizAttemptAccessInformation,
  useQuizAttemptData,
  useQuizAttemptSummary,
  useQuizViewAttempt
} from '../../store/moodleAPI/hooks';

export function useQuizAttempt(
  quizId: number | undefined,
  attemptId: number | undefined,
  currentQuestionPage: number | undefined
) {
  const currentPage = currentQuestionPage ?? 0;

  const {
    data: accessData,
    isLoading: isAccessLoading,
    error: accessError,
    mutate: mutateAccessData
  } = useQuizAttemptAccessInformation({
    quizid: quizId,
    attemptid: attemptId
  });

  // todo Тут стоит неправильный порядок: сначала должен быть viewAttempt (или accessData, уточнить),
  //  а потом уже attemptData и attemptSummary.
  //  Сейчас это работает, потому что при мутации сбрасывается кэш и перезапрашиваются все запросы,
  //  в т.ч. viewAttempt с новым значением параметра currentPage, поэтому работает.
  //  Если это исправить, то в теории refreshInterval будет не нужен.

  const {
    data: attemptData,
    isLoading: isAttemptDataLoading,
    error: attemptDataError,
    mutate: mutateAttemptData
  } = useQuizAttemptData({
    attemptid: attemptId,
    page: currentPage,
    moodleConfig: {
      skip: !accessData,
      keepPreviousData: true,
      refreshInterval: (data) => (data?.questions.length === 0 ? 100 : 0)
    }
  });

  const {
    data: attemptSummary,
    isLoading: isAttemptSummaryLoading,
    error: attemptSummaryError,
    mutate: mutateAttemptSummary
  } = useQuizAttemptSummary({
    attemptid: attemptId,
    moodleConfig: {
      skip: !accessData || attemptData?.questions.length === 0,
      keepPreviousData: true
    }
  });

  const { error: viewAttemptError, mutate: mutateViewAttempt } = useQuizViewAttempt({
    attemptid: attemptId,
    page: currentPage,
    moodleConfig: {
      skip: !attemptData || !attemptSummary
    }
  });

  const clearCache = () => {
    mutateAccessData(undefined, { revalidate: true });
    mutateAttemptData(undefined, { revalidate: true });
    mutateAttemptSummary(undefined, { revalidate: true });
    mutateViewAttempt(undefined, { revalidate: true });
  };

  const clearAllQuestions = () => {
    mutateAttemptSummary(undefined, { revalidate: false });
  };

  const questions = attemptData?.questions || [];
  const allQuestions = attemptSummary?.questions || [];

  const isLoading =
    !attemptData || isAttemptDataLoading || isAccessLoading || isAttemptSummaryLoading || questions.length === 0;
  const error = attemptDataError || viewAttemptError || accessError || attemptSummaryError;

  return {
    attemptData,
    accessData,
    questions,
    allQuestions,
    isLoading,
    error,
    clearCache,
    clearAllQuestions
  };
}
