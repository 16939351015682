import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Logo, LogoSize } from 'components/Logo';
import { Icons } from 'components/Icons';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { HOME_PAGE_ROUTE } from 'app/routes/routes';
import { useIsTablet } from 'hooks/useIsTablet';
import { MobileNav } from '../MobileNav/MobileNav';
import { UserMenu } from './UserMenu';
import s from './Header.module.scss';

export const Header = () => {
  const isTablet = useIsTablet();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!isTablet) {
      setMenuOpen(false);
    }
  }, [isTablet]);

  return (
    <div
      className={clsx(s.Header, {
        [s.Header_mobileNavOpen]: menuOpen
      })}>
      {isTablet ? (
        <IconButton
          icon={menuOpen ? Icons.CANCEL : Icons.BURGER}
          variant={IconButtonVariant.ghost}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      ) : (
        <Link to={HOME_PAGE_ROUTE}>
          <Logo size={LogoSize.small} />
        </Link>
      )}
      <div className={s.Header__ext}>
        <UserMenu />
      </div>

      {isTablet && <MobileNav open={menuOpen} onLinkClick={() => setMenuOpen(false)} />}
    </div>
  );
};
