import React from 'react';
import clsx from 'clsx';
import s from './Avatar.module.scss';

export enum AvatarSize {
  XLarge = 'xLarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall'
}

export interface AvatarProps {
  className?: string;
  size?: AvatarSize;
  imageSrc?: string;
  userName?: string;
  hasNotifications?: boolean;
  onClick?: () => void;
}

export const Avatar = ({
  className,
  size = AvatarSize.Medium,
  imageSrc,
  userName,
  hasNotifications,
  onClick
}: AvatarProps) => {
  const abbr =
    imageSrc || !userName
      ? ''
      : userName
          .split(' ')
          .map((w) => w[0].toUpperCase())
          .join('');

  return (
    <div
      onClick={onClick}
      className={clsx(s.Avatar, s[`Avatar_${size}`], className)}
      style={{
        backgroundImage: imageSrc ? `url(${imageSrc})` : ''
      }}>
      {abbr}

      {hasNotifications && size !== AvatarSize.XSmall && (
        <div className={clsx(s.Avatar__notify, s[`Avatar__notify_${size}`])} />
      )}
    </div>
  );
};
