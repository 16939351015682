import { useCallback } from 'react';
import { useMoodleMutation } from '../useMoodleMutation';
import {
  CoreCourseSetFavouriteCoursesData,
  CoreCourseSetFavouriteCoursesQuery
} from '../moodleTypes/_functions/core_course_set_favourite_courses';

export const useCourseSetFavourite = () => {
  const { mutate, ...props } = useMoodleMutation();

  const run = useCallback(
    (courseId: number, favourite: boolean) => {
      return mutate<CoreCourseSetFavouriteCoursesQuery, CoreCourseSetFavouriteCoursesData>({
        wsfunction: 'core_course_set_favourite_courses',
        courses: [
          {
            id: courseId,
            favourite: +favourite
          }
        ]
      });
    },
    [mutate]
  );

  return {
    mutate: run,
    ...props
  };
};
