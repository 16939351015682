import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreUserGetUsersByFieldData,
  CoreUserGetUsersByFieldQuery,
  CoreUserGetUsersByIdHookArgs
} from '../moodleTypes/_functions/core_user_get_users_by_field';

export const useUsersById = ({ moodleConfig, ids = [] }: Partial<CoreUserGetUsersByIdHookArgs>) => {
  return useMoodleQuery<CoreUserGetUsersByFieldQuery, CoreUserGetUsersByFieldData>(
    {
      wsfunction: 'core_user_get_users_by_field',
      field: 'id',
      values: ids
    },
    {
      skip: !ids?.length,
      ...moodleConfig
    }
  );
};
