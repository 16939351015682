import { ComponentProps, ElementType, JSX } from 'react';
import clsx from 'clsx';
import { PolyExtends } from 'utils/types';
import { Text, TextColor } from '../Text';
import { PageHelmet, PageHelmetProps } from '../PageHelmet';
import s from './Heading.module.scss';

export enum HeadingSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5'
}

export interface HeadingClasses {
  title?: string;
}

export const HeadingDefaultComponent = 'h1';
export type HeadingDefaultComponentType = typeof HeadingDefaultComponent;

export interface HeadingPropsSelf<ComponentType extends ElementType> {
  classes?: HeadingClasses;
  pageTitle?: PageHelmetProps['title'];
  /**
   * Размер заголовка("H1", "H2", "H3", "H4", "H5")
   */
  size?: HeadingSize;
  /**
   * Реф на корневой элемент
   */
  innerRef?: ComponentProps<ComponentType>['ref'];
  /**
   * Вариант цвета текста
   */
  colorVariant?: TextColor;
}

export type HeadingProps<ComponentType extends ElementType = HeadingDefaultComponentType> = PolyExtends<
  ComponentType,
  HeadingPropsSelf<ComponentType>,
  ComponentProps<ComponentType>
>;

export function Heading(props: HeadingProps<'h1'>): JSX.Element;
export function Heading<ComponentType extends ElementType>(props: HeadingProps<ComponentType>): JSX.Element;
export function Heading<ComponentType extends ElementType = HeadingDefaultComponentType>({
  children,
  component,
  className,
  classes,
  pageTitle,
  size = HeadingSize.H1,
  innerRef,
  colorVariant,
  ...props
}: HeadingProps<ComponentType>) {
  const Component = component || size;

  return (
    <div className={clsx(s.Heading, className)}>
      <PageHelmet title={pageTitle} />

      <Text
        component={Component}
        ref={innerRef}
        className={clsx(s[`Heading__title_${size}`], classes?.title)}
        colorVariant={colorVariant}
        noStyle
        {...props}>
        {children}
      </Text>
    </div>
  );
}
