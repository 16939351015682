import React from 'react';
import { InputDelayed, InputDelayedProps } from '../InputDelayed';
import { Icons } from '../../Icons';
import { InputIcon } from '../InputIcon';
import s from './SearchInput.module.scss';

export interface SearchInputProps extends Omit<InputDelayedProps, 'onChange'> {
  onChange?: (value: string) => void;
}

export const SearchInput = ({ value, onChange, ...props }: SearchInputProps) => {
  return (
    <InputDelayed
      {...props}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      // TODO: прокидывать иконки через отдельный проп в компоненте и наследовать нужные цвета
      left={() => <InputIcon className={s.SearchInput__searchIcon} icon={Icons.SEARCH} />}
      right={(value) =>
        value && <InputIcon className={s.SearchInput__clearIcon} icon={Icons.CANCEL} onClick={() => onChange?.('')} />
      }
    />
  );
};
