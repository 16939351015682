import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { Logo, LogoSize } from '../../../components/Logo';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { mediaQuery, useMediaQueryResult } from '../../../hooks/useMediaQueryResult';
import { LoginLayoutBackground } from './LoginLayoutBackground';
import s from './LoginLayout.module.scss';

export interface LoginLayoutProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const LoginLayout: ReactFCC<LoginLayoutProps> = (props) => {
  const { children, className } = props;

  const isMobile = useIsMobile();
  const isTablet = useMediaQueryResult(mediaQuery.desktopSmallDown);

  return (
    <div className={clsx(s.LoginLayout, className)}>
      <LoginLayoutBackground>
        <div className={s.LoginLayout__content}>
          <Logo
            className={s.LoginLayout__logo}
            size={isMobile ? LogoSize.medium : isTablet ? LogoSize.large : LogoSize.large_x}
          />

          {children}
        </div>
      </LoginLayoutBackground>
    </div>
  );
};
