import { useCallback } from 'react';
import { useMoodleMutation } from '../useMoodleMutation';
import {
  ModQuizProcessAttemptData,
  ModQuizProcessAttemptQuery,
  ModQuizProcessAttemptQueryArgs
} from '../moodleTypes/_functions/mod_quiz_process_attempt';

export const useQuizProcessAttempt = () => {
  const { mutate, ...props } = useMoodleMutation();

  const run = useCallback(
    (data: ModQuizProcessAttemptQueryArgs) => {
      return mutate<ModQuizProcessAttemptQuery, ModQuizProcessAttemptData>({
        wsfunction: 'mod_quiz_process_attempt',
        ...data
      });
    },
    [mutate]
  );

  return {
    mutate: run,
    ...props
  };
};
