import React from 'react';
import clsx from 'clsx';
import { SvgPropsWithoutRef } from 'utils/types';
import { ICONS_MAP, IconTypes } from 'components/Icons/Icons';
import s from './Icon.module.scss';

export enum IconSize {
  small_x = 'small_x',
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface IconProps extends Omit<SvgPropsWithoutRef, 'size'> {
  /**
   * Размер Icon ("small_x", "small", "medium", "large")
   */
  size?: IconSize;
  /**
   * Компонент иконки
   */
  icon?: IconTypes;
  /**
   * Цвет иконки
   */
  color?: string;
  /**
   * Реф на корневой элемент
   */
  innerRef?: React.Ref<SVGSVGElement>;
}

export function Icon({ size = IconSize.medium, className, icon, innerRef, style, color, ...props }: IconProps) {
  if (!icon) {
    return null;
  }
  const iconStyle: React.CSSProperties = style || {};
  if (color) {
    iconStyle.color = color;
  }

  return React.cloneElement(ICONS_MAP[icon], {
    ref: innerRef,
    className: clsx(s.Icon, s[`Icon_size_${size}`], className),
    style: iconStyle,
    ...props
  });
}

Icon.Size = IconSize;
