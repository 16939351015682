import { QuizQuestionLayout } from '../../QuizQuestionLayout';
import { MoodleContent } from '../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionTypeProps } from '../types';

export function DescriptionQuestion({ id, description, prompt }: QuizQuestionTypeProps) {
  return (
    <QuizQuestionLayout id={id} title={'Информационный блок'} prompt={prompt}>
      <MoodleContent wide>{description}</MoodleContent>
    </QuizQuestionLayout>
  );
}
