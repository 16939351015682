import { useEffect, useMemo, useRef, useState } from 'react';
import { QuizQuestionLayout } from '../../QuizQuestionLayout';
import { MoodleContent } from '../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionTypeProps } from '../types';
import { Divider } from '../../../../../components/Divider';
import s from '../QuizQuestion.module.scss';
import { RadioGroup } from '../../../../../components/Radio/RadioGroup';
import { useProcessQuestion } from '../useProcessQuestion';

export function RadioQuestion({
  id,
  content,
  sequenceCheck,
  description,
  title,
  prompt,
  setPageProcessData
}: QuizQuestionTypeProps) {
  const [answers, setAnswers] = useState<{ name: string; value: string }[]>([]);

  const contentRef = useRef(content);
  const hasClearChoiceRef = useRef(!!contentRef.current?.querySelector('div[id$="clearchoice"]'));

  const { options, questionName, currentValue } = useMemo(() => {
    const $answers = Array.from(contentRef.current?.querySelectorAll('.answer > div') ?? []);
    let qName: undefined | string | null;
    let currentValue: string | null | undefined;

    const options = $answers.map((answer) => {
      const $input = answer.querySelector('input[type="radio"]');
      const id = $input?.getAttribute('id');
      qName = $input?.getAttribute('name');
      const label =
        answer.querySelector('label')?.textContent ?? answer.querySelector('[data-region="answer-label"]')?.textContent;
      const value = $input?.getAttribute('value');

      if ($input?.getAttribute('checked') === 'checked') {
        currentValue = value;
      }

      if (!hasClearChoiceRef.current) {
        currentValue = '0';
      }

      if (!label || !id || !value) {
        return {
          id: '',
          label: '',
          value: ''
        };
      }

      return {
        id,
        label,
        value
      };
    });

    return {
      options,
      questionName: qName,
      currentValue
    };
  }, []);

  useEffect(() => {
    if (!questionName) {
      return;
    }

    setAnswers([
      {
        name: questionName,
        value: currentValue ?? (hasClearChoiceRef.current ? '-1' : '')
      }
    ]);
  }, [questionName, currentValue]);

  useProcessQuestion({
    answers,
    setPageProcessData,
    sequenceCheck
  });

  const radioChangeHandler = (value: string | null) => {
    if (!questionName || !value) {
      return;
    }

    setAnswers([
      {
        name: questionName,
        value
      }
    ]);
  };

  let value = answers[0]?.value ?? '';
  if (value === '-1') value = '';

  return (
    <QuizQuestionLayout id={id} title={title} prompt={prompt}>
      <MoodleContent wide>{description}</MoodleContent>

      <Divider className={s.QuizQuestion__divider} />

      <RadioGroup onChange={radioChangeHandler} options={options} value={value} />
    </QuizQuestionLayout>
  );
}
