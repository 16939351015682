import React, { MouseEventHandler, useCallback } from 'react';
import clsx from 'clsx';
import { IModuleContents } from 'store/moodleAPI/moodleTypes/Module';
import { Link, LinkSize, LinkVariant } from '../Link';
import { Icons } from '../../Icons';
import { useIsMobile } from '../../../hooks/useIsMobile';
import s from './LinkFile.module.scss';

export interface LinkFileProps extends Pick<IModuleContents, 'filename' | 'fileurl'> {
  className?: string;
  variant?: LinkVariant;
  size?: LinkSize;
  onClick?: MouseEventHandler;
}

export const LinkFile = ({ className, variant, size: sizeProp, filename, fileurl, onClick }: LinkFileProps) => {
  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        const link = document.createElement('a');
        link.href = fileurl || '';
        link.download = filename;
        link.click();
      } catch (err) {
        console.error(err);
      }

      onClick?.(e);
    },
    [onClick, filename, fileurl]
  );

  const isMobile = useIsMobile();

  const size = sizeProp !== undefined ? sizeProp : isMobile ? LinkSize.small : LinkSize.medium;

  return (
    <Link
      component={'button'}
      className={clsx(s.LinkFile, className)}
      variant={variant}
      size={size}
      standalone
      underlined={false}
      leftIcon={Icons.ATTACHMENT_SMALL}
      onClick={handleClick}>
      {filename}
    </Link>
  );
};
