import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizGetUserBestGradeData,
  ModQuizGetUserBestGradeHookArgs,
  ModQuizGetUserBestGradeQuery
} from '../moodleTypes/_functions/mod_quiz_get_user_best_grade';

export function useQuizUserBestGrade({ moodleConfig, quizid }: Partial<ModQuizGetUserBestGradeHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetUserBestGradeQuery, ModQuizGetUserBestGradeData>(
    {
      wsfunction: 'mod_quiz_get_user_best_grade',
      quizid: quizid ?? 0
    },
    {
      skip: isNil(quizid),
      ...moodleConfig
    }
  );
}
