import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export function logout(target?: Window | null) {
  return new MoodleIframeBridge<MoodleMessage.LogoutRequest, MoodleMessage.PageOpenResponse>(
    target ?? window,
    'LOGOUT',
    'LOAD',
    (response) => !response.logged
  ).send();
}
