import { ElementType } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { ReactComponent as LogoLargeX } from './assets/Size=XL.svg';
import { ReactComponent as LogoLarge } from './assets/Size=L.svg';
import { ReactComponent as LogoMedium } from './assets/Size=M.svg';
import { ReactComponent as LogoSmall } from './assets/Size=S.svg';
import s from './Logo.module.scss';

export enum LogoSize {
  large_x = 'large_x',
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export interface LogoProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Размер логотипа
   */
  size?: LogoSize;
}

const components: Record<LogoSize, ElementType> = {
  [LogoSize.large_x]: LogoLargeX,
  [LogoSize.large]: LogoLarge,
  [LogoSize.medium]: LogoMedium,
  [LogoSize.small]: LogoSmall
};

export const Logo: ReactFCC<LogoProps> = (props) => {
  const { className, size = LogoSize.large } = props;

  const LogoComponent = components[size];

  return <LogoComponent className={clsx(s.Logo, className)} />;
};
