import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { Text, TextVariant } from '../../../../components/Text';
import { DivPropsWithoutRef } from '../../../../utils/types';
import s from './QuizQuestionLayout.module.scss';

export interface QuizQuestionLayoutProps extends DivPropsWithoutRef {
  className?: string;
  title: string;
  prompt: string;
}

export function QuizQuestionLayout({
  children,
  className,
  title,
  prompt,
  ...props
}: PropsWithChildren<QuizQuestionLayoutProps>) {
  return (
    <div className={clsx(s.QuizQuestionLayout, className)} {...props}>
      <div className={s.QuizQuestionLayout__contentBox}>
        <div className={s.QuizQuestionLayout__header}>
          <Heading size={HeadingSize.H4}>{title}</Heading>

          {prompt && (
            <Text className={s.QuizQuestionLayout__prompt} variant={TextVariant.BODY_S}>
              {prompt}
            </Text>
          )}
        </div>

        <div className={s.QuizQuestionLayout__content}>{children}</div>
      </div>
    </div>
  );
}
