import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useCourseSetFavourite, useCourseSetHidden } from 'store/moodleAPI/hooks';
import { Icons } from '../../Icons';
import { ActionBox, ActionBoxActionType } from '../ActionBox';
import s from './CourseActionBox.module.scss';

export interface CourseActionBoxProps {
  className?: string;
  courseId: number;
  favourite?: boolean;
  hidden?: boolean;
}

export const CourseActionBox = ({ className, courseId, favourite, hidden }: CourseActionBoxProps) => {
  const { mutate: setFavouriteMutate } = useCourseSetFavourite();
  const { mutate: setVisibleMutate } = useCourseSetHidden();

  const setFavourite = useCallback(async () => {
    try {
      await setFavouriteMutate(courseId, !favourite);
    } catch (err) {
      console.error(err);
    }
  }, [courseId, favourite, setFavouriteMutate]);

  const setHidden = useCallback(async () => {
    try {
      await setVisibleMutate(courseId, !hidden);
    } catch (err) {
      console.error(err);
    }
  }, [courseId, hidden, setVisibleMutate]);

  const actions: ActionBoxActionType[] = useMemo(() => {
    return [
      {
        className: clsx(favourite && s.CourseActionBox__actionFavorite_active),
        icon: favourite ? Icons.HEART_FILL : Icons.HEART_OUTLINE,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setFavourite();
        }
      },
      {
        icon: hidden ? Icons.EYE_CLOSED : Icons.EYE_OPENED,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setHidden();
        }
      }
    ];
  }, [favourite, hidden, setFavourite, setHidden]);

  return <ActionBox className={clsx(s.CourseActionBox, className)} actions={actions} />;
};
