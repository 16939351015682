import { generatePath, Navigate } from 'react-router-dom';
import clsx from 'clsx';
import { QuizProps } from '../../types';
import { MoodleContent } from '../../../../../../components/MoodleContent/MoodleContent';
import { Divider } from '../../../../../../components/Divider';
import { PageActions } from '../../../../_components/PageActions/PageActions';
import { htmlToInlineText } from '../../../../../../utils/html';
import { UseQuizDataReturn, useStartQuiz } from '../../../../../../hooks/quiz';
import { QuizCompletionState } from '../QuizCompletionState';
import { Loader, LoaderSize } from '../../../../../../components/Loader';
import { QUIZ_PAGE_ROUTE } from '../../../../../../app/routes/routes';
import { CompletionMode, CompletionState } from '../../../../../../store/moodleAPI/moodleTypes/Module';
import s from './QuizAbout.module.scss';

export interface QuizAboutProps extends QuizProps {
  quizData: UseQuizDataReturn;
}

export const QuizAbout = ({ data, quizData, onModuleSubmit, submitLoading }: QuizAboutProps) => {
  const {
    course,
    module,
    currentModuleContents,
    previousModuleContents,
    nextModuleContents,
    prevModuleLink,
    nextModuleLink
  } = data;

  const description = currentModuleContents?.description;

  const shouldBeSubmitted =
    module?.completion === CompletionMode.Manual &&
    currentModuleContents?.completiondata?.state === CompletionState.Incomplete;
  const prevModuleAvailability = previousModuleContents?.availabilityinfo;
  const nextModuleAvailability = nextModuleContents?.availabilityinfo;

  const {
    startQuiz,
    startAttemptData,
    isLoading: isStartQuizLoading
  } = useStartQuiz({
    quiz: quizData.quiz,
    attemptInProgress: quizData.attemptInProgress
  });

  if (startAttemptData && startAttemptData.id && course && currentModuleContents && currentModuleContents.instance) {
    return (
      <Navigate
        to={getAttemptPath(
          course.id,
          currentModuleContents.instance,
          startAttemptData.id,
          currentModuleContents.id,
          startAttemptData.currentpage
        )}
      />
    );
  }

  const isLoading = (!quizData.quiz || quizData.isLoading) && !startAttemptData;

  // Зависимость от startAttemptData для того, чтобы не было флика после загрузки данных для попытки
  const isButtonLoading = isStartQuizLoading || !!startAttemptData;

  return (
    <div className={s.QuizAbout}>
      <div className={clsx(s.QuizAbout__content)}>
        {description && <MoodleContent className={s.QuizAbout__description}>{description}</MoodleContent>}

        {isLoading ? (
          <Loader className={s.QuizAbout__loader} size={LoaderSize.large} />
        ) : (
          <QuizCompletionState {...quizData} onStartQuiz={startQuiz} isStartQuizLoading={isButtonLoading} />
        )}
      </div>

      <Divider className={s.QuizAbout__actionsDivider} />

      <PageActions
        prevLink={prevModuleLink}
        nextLink={nextModuleLink}
        prevDisabled={!!prevModuleAvailability}
        nextDisabled={!!nextModuleAvailability}
        prevDisabledMessage={htmlToInlineText(prevModuleAvailability)}
        nextDisabledMessage={htmlToInlineText(nextModuleAvailability)}
        shouldBeSubmitted={shouldBeSubmitted}
        submitLoading={submitLoading}
        onSubmitClick={onModuleSubmit}
      />
    </div>
  );
};

function getAttemptPath(courseId: number, quizId: number, attemptId: number, moduleId: number, page?: number) {
  return generatePath(QUIZ_PAGE_ROUTE, { courseId, quizId }) + `?attempt=${attemptId}&cmid=${moduleId}&page=${page}`;
}
