import React, { useCallback } from 'react';

export interface UseDelegateFocusProps<Root extends HTMLElement> {
  /**
   * Новый обработчик клика для элемента
   */
  onClick?: React.MouseEventHandler<Root>;
  /**
   * Элемент, клик на который нужно игнорировать
   */
  ignoreRef?: React.MutableRefObject<Element | null>;
}

/**
 * Прокидывает фокус на targetRef при взаимодействии с элементом
 * @param targetRef - реф элемента, куда прокидывать клик
 * @param props
 */
export const useDelegateFocus = <Root extends HTMLElement, Target extends HTMLElement>(
  targetRef: React.MutableRefObject<Target | null>,
  { onClick: onClickProp, ignoreRef }: UseDelegateFocusProps<Root> = {}
) => {
  const onClick = useCallback(
    (event: React.MouseEvent<Root, MouseEvent>) => {
      if (targetRef.current && event.target !== targetRef.current && event.target !== ignoreRef?.current) {
        targetRef.current.focus();
      }
      onClickProp?.(event);
    },
    [ignoreRef, onClickProp, targetRef]
  );

  return {
    onClick
  };
};
