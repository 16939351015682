import { useMemo } from 'react';
import { addDays, subDays, addMonths, format } from 'date-fns'; // eslint-disable-line import/no-duplicates
import ru from 'date-fns/locale/ru'; // eslint-disable-line import/no-duplicates
import { groupBy } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCalendarGetActionEventsByCourseData,
  CoreCalendarGetActionEventsByCourseQuery,
  CoreCalendarGetActionEventsByCourseQueryArgs
} from '../moodleTypes/_functions/core_calendar_get_action_events_by_course';

export interface UseCourseTimelineProps {
  courseId?: string;
  sort?: CourseEventsTimeSort;
  search?: string;
  limit?: number;
}

export enum CourseEventsTimeSort {
  all = 'all',
  overdue = 'overdue',
  next_week = 'next_week',
  next_month = 'next_month',
  next_three_month = 'next_three_month',
  next_six_month = 'next_six_month'
}

export const useCourseEvents = (props: UseCourseTimelineProps) => {
  const { courseId, sort = CourseEventsTimeSort.all, search, limit = 50 } = props;

  const { data, ...query } = useMoodleQuery<
    CoreCalendarGetActionEventsByCourseQuery,
    CoreCalendarGetActionEventsByCourseData
  >(
    {
      wsfunction: 'core_calendar_get_action_events_by_course',
      courseid: Number(courseId),
      searchvalue: search,
      limitnum: limit,
      ...getCourseEventsQueryArgs(sort)
    },
    {
      skip: !courseId
    }
  );

  const events = useMemo(
    () =>
      Object.entries(groupBy(data?.events || [], (i) => i.timeusermidnight)).map(([date, events]) => {
        const formattedDate = format(new Date(Number(date) * 1000), 'EEEEEE, dd MMMM, yyyy', { locale: ru });

        return {
          date: formattedDate[0].toUpperCase() + formattedDate.slice(1),
          events
        };
      }),
    [data]
  );

  return { events, ...query };
};

function getCourseEventsQueryArgs(
  sort: CourseEventsTimeSort
): Pick<CoreCalendarGetActionEventsByCourseQueryArgs, 'timesortfrom' | 'timesortto'> {
  switch (sort) {
    case CourseEventsTimeSort.all:
      return {
        timesortfrom: getTimeForSort(subDays(new Date(), 14))
      };
    case CourseEventsTimeSort.overdue:
      return {
        timesortfrom: getTimeForSort(subDays(new Date(), 14)),
        timesortto: getTimeForSort(new Date())
      };
    case CourseEventsTimeSort.next_week:
      return {
        timesortfrom: getTimeForSort(new Date()),
        timesortto: getTimeForSort(addDays(new Date(), 7))
      };
    case CourseEventsTimeSort.next_month:
      return {
        timesortfrom: getTimeForSort(new Date()),
        timesortto: getTimeForSort(addDays(new Date(), 30))
      };
    case CourseEventsTimeSort.next_three_month:
      return {
        timesortfrom: getTimeForSort(new Date()),
        timesortto: getTimeForSort(addMonths(new Date(), 3))
      };
    case CourseEventsTimeSort.next_six_month:
      return {
        timesortfrom: getTimeForSort(new Date()),
        timesortto: getTimeForSort(addMonths(new Date(), 6))
      };
    default:
      return {
        // equals to 'all'
        timesortfrom: getTimeForSort(subDays(new Date(), 14))
      };
  }
}

function getTimeForSort(date: Date) {
  return Math.floor(date.setHours(0, 0, 0, 0) / 1000);
}
