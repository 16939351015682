import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type SearchParamsObjectType<K extends string = string> = { [Key in K[number]]: string | undefined };
export type SetSearchParamsObjectType<K extends string = string> = { [Key in K[number]]: any };
export type SetSearchParamsFuncType<K extends string = string> = (
  query: Partial<SetSearchParamsObjectType<K>>,
  replace?: boolean
) => void;

function getValue<K extends string = string>(search: string, param: K) {
  return new URLSearchParams(search).get(param as string) || undefined;
}

function getValuesObject<K extends string = string>(search: string, params: K[]) {
  return Object.fromEntries(params.map((k) => [k, getValue(search, k)])) as SearchParamsObjectType<K>;
}

export function useSearchParams<K extends string>(keys: K[]) {
  const { search } = useLocation();
  const navigate = useNavigate();
  const keysRef = useRef(keys);

  const setQuery: SetSearchParamsFuncType<K> = useCallback(
    (query: Partial<SetSearchParamsObjectType<K>>, replace?: boolean) => {
      const urlSearchParams = new URLSearchParams(search);
      Object.entries(query).forEach(([key, value]) => {
        if (value !== undefined) {
          urlSearchParams.set(key, value as string);
        } else {
          urlSearchParams.delete(key);
        }
      });

      navigate(`${window.location.pathname}?${urlSearchParams.toString()}`, {
        replace
      });
    },
    [navigate, search]
  );

  const value: SearchParamsObjectType<K> = useMemo(() => {
    return getValuesObject(search, keysRef.current);
  }, [search]);

  return [value, setQuery] as const;
}
