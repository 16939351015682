import React from 'react';
import clsx from 'clsx';
import { htmlToInlineText } from 'utils/html';
import { ModuleVariant } from 'utils/courses/CoursesUtils';
import { CompletionState, IModuleCompletionDataDetail } from 'store/moodleAPI/moodleTypes/Module';
import { ModuleBaseProps } from '../module-types';
import s from '../Module.module.scss';

function mapDetailVariant(detail: IModuleCompletionDataDetail): ModuleVariant {
  switch (detail.rulevalue.status) {
    case CompletionState.Complete:
    case CompletionState.CompletePass:
      return ModuleVariant.Success;
    case CompletionState.Incomplete:
      return ModuleVariant.InProgress;
    default:
      return ModuleVariant.Normal;
  }
}

type ModuleDefaultContentProps = Pick<ModuleBaseProps, 'description' | 'completiondata'> & {
  detailsVariant?: ModuleVariant;
};

export const ModuleDefaultContent = ({ description, completiondata, detailsVariant }: ModuleDefaultContentProps) => {
  return (
    <>
      {description && <div className={s.Module__description}>{htmlToInlineText(description)}</div>}

      {!!completiondata?.details.length && (
        <div className={s.Module__content}>
          {completiondata.details.map((item, iItem) => (
            <div
              key={iItem}
              className={clsx(s.ModuleDetail, s[`ModuleDetail_${detailsVariant || mapDetailVariant(item)}`])}>
              <div className={s.ModuleDetail__dot} />
              {item.rulevalue.description}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
