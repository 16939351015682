import React from 'react';
import clsx from 'clsx';
import { getProgress } from '../../utils/number/progress';
import s from './ProgressBar.module.scss';

export enum ProgressBarSize {
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall'
}

export enum ProgressBarVariant {
  Good = 'good',
  Warning = 'warning',
  Bad = 'bad',
  Default = 'default'
}

export interface ProgressBarProps {
  className?: string;
  value?: number;
  totalValue?: number;
  size?: ProgressBarSize;
  variant?: ProgressBarVariant;
  color?: string;
}

export const ProgressBar = ({
  className,
  value,
  totalValue,
  size = ProgressBarSize.Small,
  variant = ProgressBarVariant.Default,
  color
}: ProgressBarProps) => {
  const percent = getProgress({
    value,
    totalValue,
    toFixed: 0
  });

  return (
    <div className={clsx(s.ProgressBar, s[`ProgressBar_${size}`], s[`ProgressBar_${variant}`], className)}>
      <div
        className={s.ProgressBar__value}
        style={{
          width: `${percent || 0}%`,
          color
        }}
      />
    </div>
  );
};
