import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export function requestCerts(target?: Window | null) {
  return new MoodleIframeBridge<MoodleMessage.CertRequest, MoodleMessage.CertResponse>(
    target ?? window,
    'CERT',
    'CERT'
  ).send();
}
