import clsx from 'clsx';
import { format } from 'date-fns'; // eslint-disable-line import/no-duplicates
import ru from 'date-fns/locale/ru'; // eslint-disable-line import/no-duplicates
import { isNil } from 'lodash-es';
import { useQuizAccessOptions, UseQuizDataReturn } from '../../../../../../hooks/quiz';
import { CompletionState, IModule } from '../../../../../../store/moodleAPI/moodleTypes/Module';
import { Text, TextVariant } from '../../../../../../components/Text';
import { Button, ButtonSize } from '../../../../../../components/Button';
import { ArrayElement } from '../../../../../../utils/types';
import { formatGrade } from '../../../../../../utils/quiz/formatGrade';
import { Heading, HeadingSize } from '../../../../../../components/Heading';
import { useIsMobile } from '../../../../../../hooks/useIsMobile';
import s from './QuizCompletionState.module.scss';

export interface QuizCompletionStateProps extends UseQuizDataReturn {
  className?: string;
  onStartQuiz: () => void;
  isStartQuizLoading?: boolean;
}

export function QuizCompletionState(props: QuizCompletionStateProps) {
  const {
    className,
    module,
    quiz,
    accessData,
    gradeData,
    attemptAccessData,
    questionTypesData,
    attemptInProgress,
    error,
    lastAttemptId,
    onStartQuiz,
    isStartQuizLoading,
    isLoading
  } = props;

  const completionState = module?.completiondata?.state;

  const accessrules = accessData?.accessrules || [];
  const preventaccessreasons = accessData?.preventaccessreasons || [];
  const preventnewattemptreasons = attemptAccessData?.preventnewattemptreasons || [];

  const { accessDisabledByOptions, accessClosedReason, isAccessEnabled } = useQuizAccessOptions({
    error,
    questionTypesData,
    completionState,
    activerulenames: accessData?.activerulenames || [],
    accessrules,
    preventaccessreasons
  });

  const canAttempt = !!attemptInProgress || preventnewattemptreasons.length === 0 || accessData?.canpreview;

  const showButton = !accessDisabledByOptions && isAccessEnabled && canAttempt;

  const grade = gradeData?.grade;
  const gradetopass = gradeData?.gradetopass;
  const gradeMax = quiz?.grade;
  const decimalpoints = quiz?.decimalpoints;

  const isMobile = useIsMobile();

  if (isLoading) {
    return null;
  }

  // todo isLoading
  // todo error

  return (
    <div className={clsx(s.QuizCompletionState, className)}>
      <div className={s.QuizCompletionStateProps__content}>
        {/* todo не привязывать проверку к completionState, так как модуль может иметь ручное выполнение
              проверять по последней попытке / оценке
        */}

        {module?.dates?.map((date, index) => (
          <Text key={index} className={s.QuizCompletionState__text}>
            {formatQuizDate(date)}
          </Text>
        ))}

        {!isAccessEnabled && accessClosedReason ? (
          <Text className={s.QuizCompletionState__text}>{accessClosedReason}</Text>
        ) : (
          <>
            {preventaccessreasons.map((reason, index) => (
              <Text key={index} className={s.QuizCompletionState__text}>
                {reason}
              </Text>
            ))}

            {!isNil(gradetopass) && !isNil(gradeMax) && (
              <Text className={s.QuizCompletionState__text}>
                Проходная оценка: {formatGrade(gradetopass, gradeMax, decimalpoints, ' из ')}
              </Text>
            )}

            {!canAttempt &&
              preventnewattemptreasons.map((reason, index) => (
                <Text variant={TextVariant.BODY_S} key={index}>
                  {reason}
                </Text>
              ))}
          </>
        )}

        {isAccessEnabled &&
          canAttempt &&
          [...accessrules, accessDisabledByOptions].map((rule, idx) => {
            return (
              rule && (
                <Text key={idx} className={s.QuizCompletionState__text}>
                  {rule}
                </Text>
              )
            );
          })}
      </div>

      {showButton && (
        <Button
          className={s.QuizCompletionState__button}
          onClick={() => !isStartQuizLoading && onStartQuiz()}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          isLoading={isStartQuizLoading}>
          {attemptInProgress
            ? 'Продолжить последнюю попытку'
            : // todo не привязывать проверку к completionState
            completionState === CompletionState.Incomplete && !lastAttemptId
            ? 'Начать тестирование'
            : 'Пройти заново'}
        </Button>
      )}

      {!isNil(grade) && !isNil(gradeMax) && (
        <Heading component={'p'} className={s.QuizCompletionState__grade} size={HeadingSize.H5}>
          Ваша итоговая оценка за этот тест: {formatGrade(grade, gradeMax, decimalpoints)}
        </Heading>
      )}
    </div>
  );
}

function formatQuizDate(date: ArrayElement<IModule['dates']>) {
  const dateStr = format(new Date(Number(date.timestamp) * 1000), 'EEEEEE, dd MMMM, yyyy, HH:mm', { locale: ru });
  return `${date.label} ${dateStr}`;
}
