import { isNil } from 'lodash-es';
import {
  useQuizAccessInformation,
  useQuizAttemptAccessInformation,
  useQuizQuestionTypes,
  useQuizUserAttempts,
  useQuizUserBestGrade,
  useQuizViewQuiz
} from '../../store/moodleAPI/hooks';
import { ICourse } from '../../store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';
import { CompletionState, IModule } from '../../store/moodleAPI/moodleTypes/Module';
import { useQuizAccessEnabled } from './useQuizAccessEnabled';
import { useQuiz } from './useQuiz';

export type UseQuizDataProps = {
  course: ICourse | undefined;
  module: IModule | undefined;
};

export type UseQuizDataReturn = ReturnType<typeof useQuizData>;

export function useQuizData({ course, module }: UseQuizDataProps) {
  // Запрашиваем квиз
  const {
    quiz,
    isLoading: isQuizzesLoading,
    error: quizzesError,
    mutate: mutateQuizzesData
  } = useQuiz({
    courseId: course?.id,
    quizId: module?.instance
  });

  // Проверяем доступ к курсу
  const {
    data: accessData,
    isLoading: isAccessLoading,
    error: accessError,
    mutate: mutateAccessData
  } = useQuizAccessInformation({
    quizid: quiz?.id,
    moodleConfig: {
      skip: isNil(module?.completiondata?.state)
    }
  });

  const { isAccessEnabled } = useQuizAccessEnabled(
    accessData && {
      preventaccessreasons: accessData.preventaccessreasons,
      accessrules: accessData.accessrules
    }
  );

  // Получаем попытку и проверяем наличие последней активной попытки
  const {
    data: attemptsData,
    isLoading: isAttemptsLoading,
    error: attemptsError,
    mutate: mutateAttemptsData
  } = useQuizUserAttempts({
    quizid: quiz?.id,
    status: 'all',
    includepreviews: 1,
    moodleConfig: {
      skip: !isAccessEnabled
    }
  });

  const attempts = attemptsData?.attempts || [];

  const lastAttempt = attemptsData?.attempts?.[attemptsData.attempts.length - 1];
  const lastAttemptId = lastAttempt?.id;

  const attemptInProgress = lastAttempt?.state === 'inprogress' ? lastAttempt : null;

  const {
    data: attemptAccessData,
    isLoading: isAttemptAccessLoading,
    error: attemptAccessError
  } = useQuizAttemptAccessInformation({
    quizid: quiz?.id,
    attemptid: lastAttemptId || 0,
    moodleConfig: {
      skip: isNil(lastAttemptId)
    }
  });

  // Запрашиваем все типы вопросов, которые используются в тесте
  const {
    data: questionTypesData,
    isLoading: isQuestionTypesLoading,
    error: questionTypesError,
    mutate: mutateQuestionTypesData
  } = useQuizQuestionTypes({
    quizid: quiz?.id,
    moodleConfig: {
      skip:
        module?.completiondata?.state === undefined ||
        module.completiondata?.state === CompletionState.CompletePass ||
        !isAccessEnabled
    }
  });

  // Запрашиваем информацию об оценке
  const {
    data: gradeData,
    isLoading: isGradeLoading,
    error: gradeError
  } = useQuizUserBestGrade({
    quizid: quiz?.id
  });

  // "Смотрим" курс и проверяем, что нет ошибки
  const {
    error: viewQuizError,
    isLoading: isViewQuizLoading,
    mutate: mutateViewQuiz
  } = useQuizViewQuiz({
    quizid: quiz?.id,
    moodleConfig: {
      skip: !attemptsData
    }
  });

  const clearCache = () => {
    mutateQuizzesData(undefined, { revalidate: true });
    mutateAccessData(undefined, { revalidate: true });
    mutateAttemptsData(undefined, { revalidate: true });
    mutateQuestionTypesData(undefined, { revalidate: true });
    mutateViewQuiz(undefined, { revalidate: true });
  };

  // Склеиваем состояния
  // На уровень выше обязательно нужно добавить сюда состояния загрузки и ошибки course и module
  const error =
    viewQuizError ||
    accessError ||
    attemptsError ||
    questionTypesError ||
    attemptAccessError ||
    gradeError ||
    quizzesError;
  const isLoading =
    isViewQuizLoading ||
    isAccessLoading ||
    isAttemptsLoading ||
    isQuestionTypesLoading ||
    isAttemptAccessLoading ||
    isGradeLoading ||
    isQuizzesLoading;

  return {
    module,
    quiz,
    accessData,
    attemptAccessData,
    gradeData,
    questionTypesData,
    attemptsData,
    attempts,
    lastAttemptId,
    attemptInProgress,
    isLoading,
    error,
    clearCache
  };
}
