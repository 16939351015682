import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { PROFILE_PAGE_ROUT } from 'app/routes/routes';
import { useSiteInfoData } from 'app/providers/siteInfo';
import { useImageSrc } from 'hooks/useImageSrc';
import { useAuth } from 'app/providers/auth';
import { Avatar } from 'components/Avatar/Avatar';
import { Icons } from 'components/Icons';
import { Icon } from 'components/Icon';
import { useClickOutside } from '../../../hooks/useClickOutside';
import s from './Header.module.scss';

export const UserMenu = () => {
  const { fullname, userpictureurl } = useSiteInfoData();
  const imageSrc = useImageSrc(userpictureurl);
  const auth = useAuth();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref: dropdownRef,
    callback: () => setIsVisible(false)
  });

  return (
    <div
      className={clsx(s.UserMenu, {
        [s.UserMenu_open]: isVisible
      })}>
      <Avatar
        className={s.UserMenu__avatar}
        userName={fullname}
        imageSrc={imageSrc}
        onClick={() => setIsVisible(!isVisible)}
      />

      <div className={s.UserMenu__dropdown} ref={dropdownRef}>
        <Link to={PROFILE_PAGE_ROUT} className={s.UserMenu__link} onClick={() => setIsVisible(!isVisible)}>
          <Icon icon={Icons.USER} className={s.UserMenu__icon} />
          <span className={s.UserMenu__text}>Профиль</span>
        </Link>
        <div className={s.UserMenu__link} onClick={() => auth.logOut()}>
          <Icon icon={Icons.LOGOUT} className={s.UserMenu__icon} />
          <span className={s.UserMenu__text}>Выйти</span>
        </div>
      </div>
    </div>
  );
};
