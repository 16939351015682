import { isNil } from 'lodash-es';

export type GetProgressProps = {
  value?: number | string;
  totalValue?: number | string;
  toFixed?: number;
};

export function getProgress({ value, totalValue = 100, toFixed }: GetProgressProps): number | undefined {
  const v = parseFloat(value as string);
  const tValue = parseFloat(totalValue as string);

  if (isNaN(v) || isNaN(tValue)) return undefined;

  const result = (v * 100) / tValue;

  if (isNaN(result)) return undefined;

  return isNil(toFixed) ? result : +result.toFixed(toFixed);
}
