import clsx from 'clsx';
import { ModalBody, ModalFooter, ModalHeader, ModalContainer } from '../../../../components/Modal';
import { Text } from '../../../../components/Text';
import { Button, ButtonSize, ButtonVariant } from '../../../../components/Button';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import s from './QuizConfirmModal.module.scss';

export interface QuizConfirmModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoadingSubmit?: boolean;
}

export function QuizConfirmModal({ className, isOpen, onClose, onSubmit, isLoadingSubmit }: QuizConfirmModalProps) {
  const isMobile = useIsMobile();

  return (
    <ModalContainer
      className={clsx(s.ModalAlert, className)}
      onClose={onClose}
      isOpen={isOpen}
      preventWindowScroll={false}>
      <ModalHeader className={s.ModalAlert__header} onClose={onClose}>
        Завершение тестирования
      </ModalHeader>

      <ModalBody className={s.ModalAlert__body}>
        <Text className={s.QuizConfirmModal__text}>Вы уверены, что хотите завершить тестирование?</Text>
      </ModalBody>

      <ModalFooter className={s.ModalAlert__footer}>
        <Button
          className={s.QuizConfirmModal__button}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          onClick={onClose}
          variant={ButtonVariant.secondary}>
          Назад
        </Button>

        <Button
          className={s.QuizConfirmModal__button}
          size={isMobile ? ButtonSize.medium : ButtonSize.large}
          onClick={() => !isLoadingSubmit && onSubmit()}
          variant={ButtonVariant.primary}
          isLoading={isLoadingSubmit}>
          Завершить
        </Button>
      </ModalFooter>
    </ModalContainer>
  );
}
