import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonVariant } from '../../../../components/Button';
import { Icons } from '../../../../components/Icons';
import { QuizNavigationNumber, QuizNavigationNumberSize } from '../QuizNavigationNumber';
import { UseQuizPaginationData } from '../../../../hooks/quiz';
import { mediaQuery, useMediaQueryResult } from '../../../../hooks/useMediaQueryResult';
import { Text, TextVariant } from '../../../../components/Text';
import { Input, InputSizeVariant } from '../../../../components/Input';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../../../components/IconButton';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import s from './QuizFooter.module.scss';

export type QuizFooterProps = Required<
  Pick<UseQuizPaginationData, 'pagesCount' | 'hasPrevPage' | 'hasNextPage' | 'setPage'>
> & {
  page: number;
  pagesCount: number;
  onGoPrevPage: () => void;
  onGoNextPage: () => void;
  onFinishQuiz: () => void;
  isLoadingData?: boolean;
  isLoadingSubmit?: boolean;
  sequential: boolean;
};

const DISPLAY_PAGES = 5;
const PAGES_TO_SHOW_INPUT = 20;

export function QuizFooter({
  pagesCount,
  sequential,
  page,
  hasPrevPage,
  hasNextPage,
  onGoPrevPage,
  onGoNextPage,
  onFinishQuiz,
  setPage,
  isLoadingData,
  isLoadingSubmit
}: QuizFooterProps) {
  const [value, setValue] = useState('');

  const isDesktop = useMediaQueryResult(mediaQuery.desktopMediumUp);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const goToPage = () => {
    if (isLoadingSubmit) {
      return;
    }

    const targetPage = parseInt(value);
    setValue('');

    if (!targetPage || targetPage - 1 === page) {
      return;
    }

    if (targetPage > pagesCount) {
      setPage(pagesCount - 1);
      return;
    }

    if (targetPage <= 0) {
      setPage(0);
      return;
    }

    setPage(targetPage - 1);
  };

  const paginationElement = useMemo(
    () => (
      <>
        {generatePageNumbers(pagesCount, page + 1, isMobile ? Infinity : DISPLAY_PAGES).map((p, index) => (
          <QuizNavigationNumber
            id={`quiz-pagination-${p - 1}`}
            className={s.QuizFooter__paginationNumber}
            active={p - 1 === page}
            onClick={() => !isLoadingSubmit && p !== -1 && setPage(p - 1)}
            disabled={p === -1}
            size={isMobile ? QuizNavigationNumberSize.small : QuizNavigationNumberSize.medium}
            key={index}>
            {p === -1 ? '...' : p}
          </QuizNavigationNumber>
        ))}
      </>
    ),
    [isLoadingSubmit, isMobile, page, pagesCount, setPage]
  );

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`quiz-pagination-${page}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    });
  }, [page]);

  const nextButtonDataProps = useMemo(
    () =>
      hasNextPage
        ? {
            onClick: () => !isLoadingSubmit && onGoNextPage(),
            isLoading: isLoadingSubmit
          }
        : {
            onClick: () => !isLoadingSubmit && onFinishQuiz()
          },
    [hasNextPage, isLoadingSubmit, onFinishQuiz, onGoNextPage]
  );

  const nextButtonProps = useMemo(
    () =>
      isMobile
        ? {
            variant: IconButtonVariant.primary,
            size: isDesktop ? IconButtonSize.large : IconButtonSize.medium,
            icon: Icons.ARROW_RIGHT,
            rounded: true
          }
        : {
            variant: ButtonVariant.primary,
            size: isDesktop ? ButtonSize.large : ButtonSize.medium,
            rightIcon: Icons.ARROW_RIGHT,
            children: hasNextPage ? 'Далее' : 'Завершить'
          },
    [hasNextPage, isDesktop, isMobile]
  );

  const backButtonProps = useMemo(
    () =>
      isMobile
        ? {
            variant: IconButtonVariant.ghost,
            size: isDesktop ? IconButtonSize.large : IconButtonSize.medium,
            icon: Icons.ARROW_LEFT,
            rounded: true
          }
        : {
            variant: ButtonVariant.ghost,
            size: isDesktop ? ButtonSize.large : ButtonSize.medium,
            leftIcon: Icons.ARROW_LEFT,
            children: 'Назад'
          },
    [isDesktop, isMobile]
  );

  const ButtonComponent = (isMobile ? IconButton : Button) as ComponentType<any>;

  return (
    <div className={s.QuizFooter}>
      <div className={s.QuizFooter__controls}>
        {!sequential && (
          <>
            {hasPrevPage && <ButtonComponent {...backButtonProps} onClick={() => !isLoadingSubmit && onGoPrevPage()} />}

            <div className={s.QuizFooter__pagination}>
              {isMobile ? (
                <Text className={s.QuizFooter__paginationText} variant={TextVariant.CAPTION_S}>
                  Страница {page + 1} из {pagesCount}
                </Text>
              ) : (
                paginationElement
              )}

              {pagesCount >= PAGES_TO_SHOW_INPUT && !isTablet && (
                <div className={s.QuizFooter__goToPage}>
                  <Text variant={TextVariant.CAPTION_M}>Перейти на:</Text>
                  <Input
                    className={s.QuizFooter__goToPageInput}
                    type={'number'}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        goToPage();
                      }
                    }}
                    sizeVariant={InputSizeVariant.medium}
                    placeholder={' '}
                  />
                  <IconButton
                    icon={Icons.ARROW_RIGHT}
                    variant={IconButtonVariant.ghost}
                    size={IconButtonSize.medium}
                    onClick={() => goToPage()}
                    rounded
                  />
                </div>
              )}
            </div>
          </>
        )}

        <ButtonComponent
          className={s.QuizFooter__next}
          disabled={isLoadingData}
          {...nextButtonDataProps}
          {...nextButtonProps}
        />
      </div>

      {isMobile && (
        <div className={clsx(s.QuizFooter__pagination, s.QuizFooter__pagination_scroll)}>{paginationElement}</div>
      )}
    </div>
  );
}

function generatePageNumbers(totalPages: number, currentPage: number, displayCount: number = DISPLAY_PAGES) {
  const pages: number[] = [];
  const halfDisplay = Math.floor(displayCount / 2);

  if (totalPages <= displayCount) {
    // Все страницы помещаются на экране
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Прокрутка для большого количества страниц
    if (currentPage <= halfDisplay) {
      // Ближе к началу
      for (let i = 1; i <= displayCount - 1; i++) {
        pages.push(i);
      }
      pages.push(-1);
      pages.push(totalPages);
    } else if (currentPage >= totalPages - halfDisplay) {
      // Ближе к концу
      pages.push(1);
      pages.push(-1);
      for (let i = totalPages - displayCount + 2; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Страницы посередине
      pages.push(1);
      pages.push(-1);
      for (let i = currentPage - halfDisplay + 1; i <= currentPage + halfDisplay - 1; i++) {
        pages.push(i);
      }
      pages.push(-1);
      pages.push(totalPages);
    }
  }

  return pages;
}
