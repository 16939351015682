import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export function requestAssignment(target?: Window | null) {
  return new MoodleIframeBridge<MoodleMessage.AssignmentRequest, MoodleMessage.AssignmentResponse>(
    target ?? window,
    'ASSIGNMENT',
    'ASSIGNMENT'
  ).send();
}
