import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizGetAttemptAccessInformationData,
  ModQuizGetAttemptAccessInformationHookArgs,
  ModQuizGetAttemptAccessInformationQuery
} from '../moodleTypes/_functions/mod_quiz_get_attempt_access_information';

export function useQuizAttemptAccessInformation({
  moodleConfig,
  quizid,
  attemptid,
  ...props
}: Partial<ModQuizGetAttemptAccessInformationHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetAttemptAccessInformationQuery, ModQuizGetAttemptAccessInformationData>(
    {
      wsfunction: 'mod_quiz_get_attempt_access_information',
      quizid: quizid ?? 0,
      attemptid: attemptid ?? 0,
      ...props
    },
    {
      ...moodleConfig,
      skip: isNil(quizid) || isNil(attemptid) || moodleConfig?.skip
    }
  );
}
