import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCourseGetCategoriesData,
  CoreCourseGetCategoriesQuery,
  CoreCourseGetCategoriesHookArgs
} from '../moodleTypes/_functions/core_course_get_categories';

export const useCoursesCategories = ({ moodleConfig, ...props }: Partial<CoreCourseGetCategoriesHookArgs> = {}) => {
  return useMoodleQuery<CoreCourseGetCategoriesQuery, CoreCourseGetCategoriesData>(
    {
      wsfunction: 'core_course_get_categories',
      ...props
    },
    {
      ...moodleConfig
    }
  );
};
