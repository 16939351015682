import React, { Children } from 'react';
import clsx from 'clsx';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import s from './Carousel.module.scss';

export interface CarouselProps extends SwiperProps {
  slideClassName?: string;
  className?: string;
  children: React.ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ slideClassName, className, children, ...rest }) => {
  const params = {
    ...rest
  };

  return (
    <Swiper modules={[Pagination, Navigation]} className={clsx(s.Carousel, className)} {...params}>
      {children &&
        Children.map(children, (child, index) => (
          <SwiperSlide key={index} className={slideClassName}>
            {child}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
