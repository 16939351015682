import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { groupBy } from 'lodash-es';
import { QuizNavigationNumber } from '../QuizNavigationNumber';
import { AsideContainer, AsideContainerVariant } from '../../../../components/AsideContainer/AsideContainer';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { Text, TextVariant } from '../../../../components/Text';
import { ISummaryQuestion } from '../../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_summary';
import { getQuestionId, UseQuizPaginationData } from '../../../../hooks/quiz';
import { NOT_ANSWERED, NOT_COMPLETED } from '../../constants';
import { usePreventWindowScroll } from '../../../../hooks/usePreventWindowScroll';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../../../components/IconButton';
import { Icons } from '../../../../components/Icons';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import s from './QuizAside.module.scss';

export type QuizAsideProps = Pick<UseQuizPaginationData, 'setPage'> & {
  questions: ISummaryQuestion[];
  currentPage: number;
  isLoadingData?: boolean;
  sequential: boolean;
  mobileNavOpen: boolean;
  setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
};

export function QuizAside({
  questions,
  currentPage,
  setPage,
  sequential,
  isLoadingData,
  mobileNavOpen,
  setMobileNavOpen
}: QuizAsideProps) {
  const { hash } = useLocation();

  const currentId = hash.slice(1);

  const isTablet = useIsTablet();

  usePreventWindowScroll(mobileNavOpen);

  useEffect(() => {
    if (!isTablet) {
      setMobileNavOpen(false);
    }
  }, [isTablet, setMobileNavOpen]);

  const data = useMemo(() => {
    const groups = groupBy(questions, (question) => question.page);

    return Object.entries(groups).map(([page, questions]) => ({
      page: Number(page),
      questions: questions.map((question) => {
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(question.html, 'text/html');

        const id = getQuestionId(htmlDocument);
        const title = !isNaN(Number(question.questionnumber)) ? question.questionnumber : 'И';
        const completed = question.status !== NOT_COMPLETED && question.status !== NOT_ANSWERED;

        return {
          id,
          title,
          completed
        };
      })
    }));
  }, [questions]);

  const goToQuestion = useCallback(
    (page: number, id: string) => {
      setMobileNavOpen(false);
      setPage(page);
      setTimeout(() => {
        window.location.href = `#${id}`;
      }, 0);
    },
    [setMobileNavOpen, setPage]
  );

  const asideContent = useMemo(
    () =>
      sequential && isLoadingData ? null : (
        <div className={s.QuizAside__quizContent}>
          {data.map(({ page, questions }, index) => (
            <div className={s.QuizAside__page} key={index}>
              <Text
                className={clsx(s.QuizAside__pageTitle, {
                  [s.QuizAside__pageTitle_active]: page === currentPage
                })}
                variant={TextVariant.CAPTION_S}>
                Страница {page + 1}
              </Text>

              <div className={s.QuizAside__pagination}>
                {questions.map((question, index) => (
                  <QuizNavigationNumber
                    className={s.QuizAside__xNumber}
                    active={currentId === question.id}
                    completed={question.completed}
                    classes={{
                      completed: s.QuizAside__xNumber_completed,
                      active: s.QuizAside__xNumber_active
                    }}
                    onClick={() => goToQuestion(page, question.id)}
                    key={index}>
                    {question.title}
                  </QuizNavigationNumber>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    [currentId, currentPage, data, goToQuestion, isLoadingData, sequential]
  );

  return isTablet ? (
    <div
      className={clsx(s.QuizMobileNav, {
        [s.QuizMobileNav_open]: mobileNavOpen
      })}>
      <div className={s.QuizMobileNav__header}>
        <Heading size={HeadingSize.H5}>Навигация</Heading>
        <IconButton
          icon={Icons.CANCEL}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          onClick={() => setMobileNavOpen(false)}
          rounded
        />
      </div>

      <div className={s.QuizMobileNav__contentBox}>{asideContent}</div>
    </div>
  ) : (
    <AsideContainer
      classes={{
        root: (extended) => {
          return clsx(s.QuizAside, {
            [s.QuizAside_extended]: extended
          });
        },
        toggle: (extended) => {
          return clsx(s.QuizAside__toggle, {
            [s.QuizAside__toggle_extended]: extended
          });
        },
        content: () => s.QuizAside__content
      }}
      variant={AsideContainerVariant.Right}
      defaultExtended>
      {(extended) =>
        extended && (
          <>
            <Heading className={s.QuizAside__heading} component={'h4'} size={HeadingSize.H4}>
              Навигация
            </Heading>
            {asideContent}
          </>
        )
      }
    </AsideContainer>
  );
}
