import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { useCourseContentData, UseCourseContentDataReturnType } from 'hooks/course/useCourseContentData';
import { useCourseModule } from 'store/moodleAPI/hooks/useCourseModule';
import { CoreCourseGetCourseModuleData } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_course_module';
import { CompletionState, IModule, ModuleName } from 'store/moodleAPI/moodleTypes/Module';
import { COURSE_MODULE_PAGE_ROUTE } from 'app/routes/routes';
import { useModuleSetCompletion } from 'store/moodleAPI/hooks/useModuleSetCompletion';

export type ModulePageContextType = {
  data: UseCourseContentDataReturnType['data'] & {
    courseId?: string;
    moduleId?: string;
    module?: CoreCourseGetCourseModuleData['cm'];
    currentModuleContents?: IModule;
    previousModuleContents?: IModule;
    nextModuleContents?: IModule;
    prevModuleLink?: string;
    nextModuleLink?: string;
  };
  moduleSubmittedId: number | null;
  submitModule: () => void;
  refetchModule: () => void;
  courseLoading: boolean;
  moduleLoading: boolean;
  submitLoading: boolean;
  courseError?: any;
  moduleError?: any;
};

export const ModulePageContext = React.createContext<ModulePageContextType>({
  data: {},
  moduleSubmittedId: null,
  submitModule: () => {},
  refetchModule: () => {},
  courseLoading: false,
  moduleLoading: false,
  submitLoading: false
});

export function useModulePage() {
  return useContext(ModulePageContext);
}

export const ModulePageProvider = ({ children }: PropsWithChildren) => {
  const { courseId, moduleId } = useParams<{ courseId: string; moduleId: string }>();
  const [moduleSubmittedId, setModuleSubmittedId] = useState<number | null>(null);

  const {
    data: courseData,
    isLoading: courseLoading,
    error: courseError,
    refetchContents
  } = useCourseContentData(courseId);
  const { course, contents: contentsData } = courseData;
  /**
   * Исключаем модули Media&Text из списка
   */
  const contents = useMemo(() => {
    return contentsData?.map((c) => ({
      ...c,
      modules: c.modules.filter((m) => m.modname !== ModuleName.label)
    }));
  }, [contentsData]);

  const {
    data: moduleData,
    isLoading: moduleLoading,
    error: moduleError,
    mutate: moduleMutate
  } = useCourseModule({ cmid: moduleId });
  const module = moduleData?.cm;

  const handleModuleRefetch = useCallback(() => {
    refetchContents();
    moduleMutate();
  }, [refetchContents, moduleMutate]);

  const { mutate, isMutating: submitLoading } = useModuleSetCompletion();

  const handleModuleSubmit = useCallback(async () => {
    if (!module) return;

    try {
      const result = await mutate(module.id, CompletionState.Complete);
      if (result?.data.status) {
        setModuleSubmittedId(module.id);
      }
    } catch (err) {
      console.error(err);
    }
  }, [module, mutate]);

  const moduleSiblingsContents = useMemo(() => {
    if (!module || !contents?.length) return {};

    const modules = contents.flatMap((c) => c.modules);

    const currModuleIndex = modules.findIndex((m) => m.id === module.id);
    const prevModuleIndex = currModuleIndex - 1;
    const nextModuleIndex = currModuleIndex + 1;

    const currModule = modules[currModuleIndex];
    const prevModule = modules[prevModuleIndex];
    const nextModule = modules[nextModuleIndex];

    const currSection = currModule ? contents.find((c) => c.modules.find((m) => m.id === currModule.id)) : undefined;
    const prevSection = prevModule ? contents.find((c) => c.modules.find((m) => m.id === prevModule.id)) : undefined;
    const nextSection = nextModule ? contents.find((c) => c.modules.find((m) => m.id === nextModule.id)) : undefined;

    return {
      currentModuleContents: currModule
        ? {
            ...currModule,
            availabilityinfo: currModule.availabilityinfo || currSection?.availabilityinfo
          }
        : undefined,
      previousModuleContents: prevModule
        ? {
            ...prevModule,
            availabilityinfo: prevModule.availabilityinfo || prevSection?.availabilityinfo
          }
        : undefined,
      nextModuleContents: nextModule
        ? {
            ...nextModule,
            availabilityinfo: nextModule.availabilityinfo || nextSection?.availabilityinfo
          }
        : undefined
    };
  }, [contents, module]);

  const prevModuleId = moduleSiblingsContents.previousModuleContents?.id;
  const prevModuleLink =
    course && !isNil(prevModuleId)
      ? generatePath(COURSE_MODULE_PAGE_ROUTE, {
          courseId: course.id,
          moduleId: prevModuleId
        })
      : '';

  const nextModuleId = moduleSiblingsContents.nextModuleContents?.id;
  const nextModuleLink =
    course && !isNil(nextModuleId)
      ? generatePath(COURSE_MODULE_PAGE_ROUTE, {
          courseId: course.id,
          moduleId: nextModuleId
        })
      : '';

  useEffect(() => {
    setModuleSubmittedId(null);
  }, [moduleId]);

  return (
    <ModulePageContext.Provider
      value={{
        data: {
          courseId,
          moduleId,
          course,
          contents,
          module,
          prevModuleLink,
          nextModuleLink,
          ...moduleSiblingsContents
        },
        moduleSubmittedId,
        submitModule: handleModuleSubmit,
        refetchModule: handleModuleRefetch,
        courseLoading,
        moduleLoading,
        submitLoading,
        courseError,
        moduleError
      }}>
      {children}
    </ModulePageContext.Provider>
  );
};
