import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ButtonPropsWithoutRef } from '../../../../utils/types';
import s from './QuizNavigationNumber.module.scss';

export enum QuizNavigationNumberSize {
  medium = 'medium',
  small = 'small'
}

export interface QuizNavigationNumberProps extends ButtonPropsWithoutRef {
  className?: string;
  classes?: {
    completed?: string;
    active?: string;
  };
  completed?: boolean;
  active?: boolean;
  disabled?: boolean;
  size?: QuizNavigationNumberSize;
}

export function QuizNavigationNumber({
  children,
  className,
  classes,
  completed,
  active,
  disabled,
  size = QuizNavigationNumberSize.medium,
  ...props
}: PropsWithChildren<QuizNavigationNumberProps>) {
  return (
    <button
      className={clsx(s.QuizNavigationNumber, className, s[`QuizNavigationNumber_size_${size}`], {
        [clsx(s.QuizNavigationNumber_completed, classes?.completed)]: completed && !active,
        [clsx(s.QuizNavigationNumber_active, classes?.active)]: active,
        [s.QuizNavigationNumber_disabled]: disabled
      })}
      {...props}>
      <span className={s.QuizNavigationNumber__content}>{children}</span>
    </button>
  );
}
