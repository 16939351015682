import { useCallback } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

export function useGoBack(path?: string, options?: NavigateOptions, onGoBack?: () => void) {
  const navigate = useNavigate();
  const { key } = useLocation();

  return useCallback(
    (toPath?: string, toOptions?: NavigateOptions) => {
      const navOptions = {
        ...options,
        ...toOptions
      };
      if (toPath || path) {
        navigate(toPath || path || '', navOptions);
      } else if (key !== 'default') {
        navigate(-1);
      } else {
        navigate('/', navOptions);
      }

      onGoBack?.();
    },
    [options, path, key, onGoBack, navigate]
  );
}
