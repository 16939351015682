import { useMemo } from 'react';
import { IQuestion } from '../../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { QuizQuestionType } from '../../../module/_pages/Quiz/types';
import { useParseQuizHtml } from '../../../../hooks/quiz';
import { tryParseJSON } from '../../../../utils/tryParseJSON';
import { DescriptionQuestion } from './DescriptionQuestion';
import { MultiChoiceSetQuestion } from './MultiChoiceSetQuestion';
import { RadioQuestion } from './RadioQuestion';
import { QuizQuestionTypeProps } from './types';
import { ShortAnswerQuestion } from './ShortAnswerQuestion';
import { NumericalQuestion } from './NumericalQuestion';
import { EssayQuestion } from './EssayQuestion';

export interface QuizQuestionProps {
  question?: IQuestion;
  setPageProcessData: QuizQuestionTypeProps['setPageProcessData'];
}

export function QuizQuestion({ question, setPageProcessData }: QuizQuestionProps) {
  const { content, id, description, title, prompt, sequenceCheck, isMultiChoiceSet } = useParseQuizHtml(
    question?.html ?? '',
    question?.type as QuizQuestionType
  );

  const QuestionComponent = useMemo(() => {
    switch (question?.type) {
      case QuizQuestionType.DESCRIPTION:
        return DescriptionQuestion;
      case QuizQuestionType.MULTI_CHOICE:
        if (isMultiChoiceSet) {
          return MultiChoiceSetQuestion;
        } else {
          return RadioQuestion;
        }
      case QuizQuestionType.TRUE_FALSE:
        return RadioQuestion;
      case QuizQuestionType.SHORT_ANSWER:
        return ShortAnswerQuestion;
      case QuizQuestionType.NUMERICAL:
        return NumericalQuestion;
      case QuizQuestionType.ESSAY:
        return EssayQuestion;
      default:
        return null;
    }
  }, [question, isMultiChoiceSet]);

  const settings = (question?.settings ? tryParseJSON(question?.settings) : null) as object | null;

  return (
    <>
      {question && QuestionComponent && (
        <QuestionComponent
          content={content}
          id={id}
          sequenceCheck={sequenceCheck}
          title={title}
          prompt={prompt}
          description={description}
          setPageProcessData={setPageProcessData}
          settings={settings}
        />
      )}
    </>
  );
}
