import React, { MouseEventHandler } from 'react';
import { CompletionMode, CompletionState, ModuleName } from 'store/moodleAPI/moodleTypes/Module';
import { useModuleSetCompletion } from 'store/moodleAPI/hooks/useModuleSetCompletion';
import { ModuleVariant } from 'utils/courses/CoursesUtils';
import { ModuleBaseProps } from './module-types';
import { ModuleGeneral } from './ModuleGeneral';
import { ModuleTextMedia } from './ModuleTextMedia';
import { ModuleDefaultContent } from './_components/ModuleDefaultContent';
import { ModuleResourceContent } from './_components/ModuleResourceContent';

export const Module = (props: ModuleBaseProps) => {
  const { id, variant, description, completion, completiondata, contents } = props;

  const locked = variant === ModuleVariant.Locked;

  const { mutate, isMutating: submitLoading } = useModuleSetCompletion();

  const handleSubmit: MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await mutate(id, CompletionState.Complete);
    } catch (err) {
      console.error(err);
    }
  };

  const shouldBeSubmitted =
    completion === CompletionMode.Manual && completiondata?.state === CompletionState.Incomplete;

  switch (props.modname) {
    case ModuleName.label:
      return (
        <ModuleTextMedia
          shouldBeSubmitted={shouldBeSubmitted}
          submitLoading={submitLoading}
          onSubmit={handleSubmit}
          {...props}
        />
      );
    case ModuleName.resource:
      return (
        <ModuleGeneral
          locked={locked}
          shouldBeSubmitted={shouldBeSubmitted}
          submitLoading={submitLoading}
          onSubmit={handleSubmit}
          {...props}>
          <ModuleResourceContent contents={contents} />
        </ModuleGeneral>
      );
    default:
      return (
        <ModuleGeneral
          locked={locked}
          shouldBeSubmitted={shouldBeSubmitted}
          submitLoading={submitLoading}
          onSubmit={handleSubmit}
          {...props}>
          <ModuleDefaultContent
            description={description}
            completiondata={completiondata}
            detailsVariant={locked ? ModuleVariant.Locked : undefined}
          />
        </ModuleGeneral>
      );
  }
};
