import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTooltip } from 'components/Tooltip';
import { htmlToInlineText } from 'utils/html';
import { IconButton, IconButtonSize, IconButtonVariant } from 'components/IconButton';
import { Icons } from 'components/Icons';
import { IconSize } from 'components/Icon';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { ModuleVariant } from 'utils/courses/CoursesUtils';
import { ModuleGeneralProps } from './module-types';
import s from './Module.module.scss';

export const ModuleGeneral = ({
  index,
  variant = ModuleVariant.Normal,
  name,
  availabilityinfo,
  to,
  locked,
  sectionLocked,
  shouldBeSubmitted,
  submitLoading,
  onSubmit,
  children
}: ModuleGeneralProps) => {
  const {
    component: tooltip,
    setTargetRef,
    targetProps
  } = useTooltip({
    placement: 'top',
    title: 'Заблокирован',
    description: htmlToInlineText(availabilityinfo),
    enabled: locked && !sectionLocked
  });

  return (
    <Link
      ref={setTargetRef}
      {...targetProps}
      className={clsx(s.Module, s[`Module_${variant}`], {
        [s.Module_disabled]: locked
      })}
      to={to}
      onClick={(e) => {
        if (locked) {
          e.preventDefault();
        }
      }}>
      <div className={s.Module__head}>
        <div className={s.Module__title}>
          {index && <div className={s.Module__index}>{index}</div>}
          {name}
        </div>
        {!locked && (
          <IconButton
            className={s.Module__linkIcon}
            icon={Icons.CHEVRON_CURVED_RIGHT_SMALL}
            variant={IconButtonVariant.ghost}
            size={IconButtonSize.small_x}
            iconSize={IconSize.small}
          />
        )}
      </div>

      {children}

      {shouldBeSubmitted && !locked && (
        <div className={s.Module__actions}>
          <Button
            variant={ButtonVariant.primary}
            size={ButtonSize.small}
            leftIcon={Icons.CHECK}
            isLoading={submitLoading}
            onClick={onSubmit}>
            Отметить выполненным
          </Button>
        </div>
      )}

      {tooltip}
    </Link>
  );
};
