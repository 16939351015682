import React from 'react';
import { Link } from 'react-router-dom';
import { Logo, LogoSize } from '../../components/Logo';
import { Heading, HeadingSize } from '../../components/Heading';
import { Text, TextColor, TextVariant } from '../../components/Text';
import { Button } from '../../components/Button';
import { HOME_PAGE_ROUTE } from '../../app/routes/routes';
import error500Src from './_assets/error-500.svg';
import error404Src from './_assets/error-404.svg';
import s from './ErrorPage.module.scss';

export enum ErrorEnum {
  Error404 = '404',
  Error500 = '500'
}

const errorImages = {
  [ErrorEnum.Error404]: error404Src,
  [ErrorEnum.Error500]: error500Src
};

const errorTitles = {
  [ErrorEnum.Error404]: '404',
  [ErrorEnum.Error500]: '500'
};

export interface ErrorPageProps {
  error?: ErrorEnum;
  text?: React.ReactNode;
}

export const ErrorPage = ({ error, text }: ErrorPageProps) => {
  const imgSrc = error ? errorImages[error] : '';
  const title = error ? errorTitles[error] : 'Ошибка';

  return (
    <div className={s.ErrorPage}>
      <div className={s.ErrorPage__logo}>
        <Logo className={s.Logo} size={LogoSize.small} />
      </div>

      <div className={s.ErrorPage__content}>
        {imgSrc && <img className={s.ErrorImage} src={imgSrc} alt="" />}
        <Heading
          className={s.ErrorTitle}
          component={'h2'}
          size={HeadingSize.H2}
          colorVariant={TextColor.SECONDARY_0}
          pageTitle={title}>
          Что-то пошло не так!
        </Heading>
        <Text className={s.ErrorText} variant={TextVariant.BODY_S} colorVariant={TextColor.SECONDARY_0}>
          {text || (
            <>
              Страница, которую вы запрашиваете не&nbsp;существует. <br />
              Возможно она устарела или&nbsp;удалена
            </>
          )}
        </Text>
        <div className={s.ErrorActions}>
          <Button component={Link} to={HOME_PAGE_ROUTE}>
            Вернуться на главную
          </Button>
        </div>
      </div>
    </div>
  );
};
