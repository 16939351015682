import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldHookArgs,
  CoreCourseGetCoursesByFieldQuery
} from '../moodleTypes/_functions/core_course_get_courses_by_field';

export const useCoursesByField = ({ moodleConfig, ...props }: Partial<CoreCourseGetCoursesByFieldHookArgs>) => {
  return useMoodleQuery<CoreCourseGetCoursesByFieldQuery, CoreCourseGetCoursesByFieldData>(
    {
      wsfunction: 'core_course_get_courses_by_field',
      ...props
    },
    {
      skip: isNil(props.field),
      ...moodleConfig
    }
  );
};
