import { isNil } from 'lodash-es';
import {
  ModQuizViewQuizData,
  ModQuizViewQuizHookArgs,
  ModQuizViewQuizQuery
} from '../moodleTypes/_functions/mod_quiz_view_quiz';
import { useMoodleQuery } from '../useMoodleQuery';

export function useQuizViewQuiz({ moodleConfig, quizid }: Partial<ModQuizViewQuizHookArgs> = {}) {
  return useMoodleQuery<ModQuizViewQuizQuery, ModQuizViewQuizData>(
    {
      wsfunction: 'mod_quiz_view_quiz',
      quizid: quizid ?? 0
    },
    {
      skip: isNil(quizid),
      ...moodleConfig
    }
  );
}
