import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  JSX,
  LegacyRef,
  PropsWithoutRef,
  ReactNode,
  Ref,
  useRef
} from 'react';
import clsx from 'clsx';
import composeRefs from '@seznam/compose-react-refs';
import { PolyExtends } from 'utils/types';
import { useDelegateFocus } from 'hooks/useDelegateFocus';
import { useFocus } from 'hooks/useFocus';
import s from './Input.module.scss';

export enum InputVariant {
  default = 'default',
  round = 'round'
}

export enum InputSizeVariant {
  large = 'large',
  medium = 'medium'
}

export interface InputPropsSelf<ComponentType extends ElementType = 'input'> {
  /**
   * Вариант инпута (default, round)
   */
  variant?: InputVariant;
  /**
   * Размер инпута (large, medium)
   */
  sizeVariant?: InputSizeVariant;
  /**
   * Состояние ошибки
   */
  error?: boolean;
  /**
   * Проп для контролируемого включения состояния фокуса
   */
  focused?: boolean;
  /**
   * Ref на input-элемент
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * Обработчик нажатия на Input
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /**
   * Слот слева
   */
  left?: (value: ComponentProps<ComponentType>['value']) => ReactNode;
  /**
   * Слот справа
   */
  right?: (value: ComponentProps<ComponentType>['value']) => ReactNode;
  /**
   * Обработчик нажатия на иконку очистки значения
   */
  onClear?: () => void;
  /**
   * Дополнительные css-классы элементов:
   * * root - внешний контейнер
   * * input - элемент input
   * * icon - иконки слева и справа Input
   * * iconLeft - иконка слева Input
   * * iconRight - иконка справа Input
   */
  classes?: {
    root?: string;
    icon?: string;
    iconRight?: string;
    input?: string;
  };
}

export type InputProps<ComponentType extends ElementType = 'input'> = PolyExtends<
  ComponentType,
  InputPropsSelf<ComponentType>,
  ComponentProps<ComponentType>
>;

export function InputCmp<ComponentType extends ElementType = 'input'>(
  {
    component,
    value,
    variant = InputVariant.round,
    sizeVariant = InputSizeVariant.large,
    error,
    focused: focusedProp,
    className,
    classes,
    onClick,
    inputRef: inputRefProp,
    style,
    left,
    right,
    ...inputProps
  }: InputProps<ComponentType>,
  ref: Ref<HTMLDivElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);

  const delegateProps = useDelegateFocus<HTMLDivElement, HTMLInputElement>(inputRef, { onClick });
  const { focused, ...focusProps } = useFocus<HTMLInputElement | HTMLTextAreaElement>(inputProps);

  const Component = (component || 'input') as ElementType;

  return (
    <div
      ref={ref}
      className={clsx(
        s.Input,
        s[`Input_variant_${variant}`],
        s[`Input_size_${sizeVariant}`],
        {
          [s.Input_focus]: focusedProp ?? focused,
          [s.Input_error]: error,
          [s.Input_disabled]: inputProps.disabled,
          [s.Input_textarea]: Component === 'textarea'
        },
        className,
        classes?.root
      )}
      style={style}
      {...delegateProps}>
      {left && <div className={s.Input__left}>{left(value)}</div>}
      <Component
        ref={composeRefs(inputRef, inputRefProp)}
        className={clsx(s.Input__input, { [s.Input__input_disabled]: inputProps.disabled }, classes?.input)}
        autoComplete={'off'}
        value={value}
        {...inputProps}
        {...focusProps}
      />
      {right && <div className={s.Input__right}>{right(value)}</div>}
    </div>
  );
}

export const Input = forwardRef(InputCmp) as <ComponentType extends ElementType = 'input'>(
  props: PropsWithoutRef<InputProps<ComponentType>> & {
    ref?: LegacyRef<HTMLDivElement>;
  }
) => JSX.Element;
