import React, { useEffect } from 'react';
import { generatePath, Link, Navigate, useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { Container } from 'components/Container';
import { Loader, LoaderSize } from 'components/Loader';
import { DataPlaceholder } from 'components/DataPlaceholder/DataPlaceholder';
import { CompletionState, ModuleName } from 'store/moodleAPI/moodleTypes/Module';
import { COURSE_PAGE_ROUTE, HOME_PAGE_ROUTE, NOT_FOUND_PAGE_ROUTE } from 'app/routes/routes';
import { Button, ButtonVariant } from 'components/Button';
import { useGoBack } from 'hooks/useGoBack';
import { htmlToInlineText } from '../../utils/html';
import { PageHelmet, PageHelmetProps } from '../../components/PageHelmet';
import { useModulePage } from './ModulePageProvider';
import { Page } from './_pages/Page/Page';
import { PageActions } from './_components/PageActions/PageActions';
import { Assignment } from './_pages/Assignment/Assignment';
import { Resource } from './_pages/Resource/Resource';
import { Quiz } from './_pages/Quiz';
import s from './ModulePage.module.scss';

export function ModulePage() {
  const navigate = useNavigate();
  const { data, moduleSubmittedId, submitModule, refetchModule, submitLoading, courseLoading, moduleLoading } =
    useModulePage();
  const { course, module, prevModuleLink, nextModuleLink, nextModuleContents, currentModuleContents } = data;

  const moduleId = module?.id;
  const currentModuleAvailable = !currentModuleContents?.availabilityinfo;
  const currentModuleSubmitted =
    currentModuleContents?.completiondata?.state === CompletionState.Complete ||
    currentModuleContents?.completiondata?.state === CompletionState.CompletePass;
  const nextModuleAvailable = !nextModuleContents?.availabilityinfo;
  const shouldNavigateToNext =
    !isNil(moduleId) &&
    currentModuleSubmitted &&
    moduleSubmittedId === moduleId &&
    nextModuleAvailable &&
    nextModuleLink;

  /**
   * авто-редирект на след. модуль, если
   * - текущий модуль отмечен как "выполненный"
   * - следующий модуль существует и он не заблокирован
   */
  useEffect(() => {
    if (shouldNavigateToNext) {
      navigate(nextModuleLink);
    }
  }, [navigate, nextModuleLink, shouldNavigateToNext]);

  const backPath = course
    ? generatePath(COURSE_PAGE_ROUTE, {
        courseId: String(course?.id)
      }) + '?tab=program'
    : '';

  const goBack = useGoBack(
    backPath,
    backPath
      ? {
          state: {
            moduleId
          },
          replace: true
        }
      : undefined
  );

  const loading = courseLoading || moduleLoading;
  const noData = !course || !module;

  const defaultContent = (placeholder?: string, pageTitle?: PageHelmetProps['title']) => (
    <Container className={s.ModulePage}>
      <PageHelmet title={pageTitle || placeholder} />

      <div className={s.ModulePage__content}>
        {loading ? (
          <Loader size={LoaderSize.large} />
        ) : (
          <DataPlaceholder className={s.ModulePage__placeholder} description={placeholder}>
            <div>
              <Button component={Link} to={backPath || HOME_PAGE_ROUTE} variant={ButtonVariant.secondary}>
                {backPath ? 'Вернуться к курсу' : 'На главную'}
              </Button>
            </div>
          </DataPlaceholder>
        )}
      </div>
      {!currentModuleAvailable && <PageActions prevLink={prevModuleLink} nextLink={nextModuleLink} />}
    </Container>
  );

  if (loading) {
    return defaultContent();
  }

  if (noData) {
    return <Navigate to={NOT_FOUND_PAGE_ROUTE} replace />;
  }

  if (!currentModuleAvailable) {
    return defaultContent(htmlToInlineText(currentModuleContents?.availabilityinfo), 'Модуль заблокирован');
  }

  switch (module.modname) {
    case ModuleName.page:
      return <Page data={data} submitLoading={submitLoading} onModuleSubmit={submitModule} onGoBack={goBack} />;
    case ModuleName.resource:
      return <Resource data={data} submitLoading={submitLoading} onModuleSubmit={submitModule} onGoBack={goBack} />;
    case ModuleName.assign:
      return (
        <Assignment
          data={data}
          submitLoading={submitLoading}
          onModuleSubmit={submitModule}
          onModuleReload={refetchModule}
          onGoBack={goBack}
        />
      );
    case ModuleName.quiz:
      return <Quiz data={data} submitLoading={submitLoading} onModuleSubmit={submitModule} onGoBack={goBack} />;
    default:
      return defaultContent('Неподдерживаемый тип модуля');
  }
}
