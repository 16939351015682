import { ModulePageContextType } from '../../ModulePageProvider';

export interface QuizProps {
  data: ModulePageContextType['data'];
  submitLoading?: boolean;
  onModuleSubmit?: ModulePageContextType['submitModule'];
  onGoBack?: () => void;
}

export enum QuizQuestionType {
  MULTI_CHOICE_SET = 'multichoiceset', // почему-то не работает, вместо него приходит multichoice
  MULTI_CHOICE = 'multichoice',
  DDW_TO_SHARD = 'ddwtoshard',
  MATCH = 'match',
  SHORT_ANSWER = 'shortanswer',
  NUMERICAL = 'numerical',
  TRUE_FALSE = 'truefalse',
  ESSAY = 'essay',
  DESCRIPTION = 'description'
}
