import { PropsWithChildren } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { ReactComponent as BackgroundMedium } from './assets/background-medium.svg';
import { ReactComponent as BackgroundSmall } from './assets/background-small.svg';
import s from './LoginLayout.module.scss';

export function LoginLayoutBackground(props: PropsWithChildren) {
  const { children } = props;

  const isMobile = useIsMobile();

  const BackgroundImage = isMobile ? BackgroundSmall : BackgroundMedium;

  return (
    <div className={s.LoginLayoutBackground}>
      {children}

      <div className={s.LoginLayoutBackground__topLeft} />
      <div className={s.LoginLayoutBackground__topRight} />
      <div className={s.LoginLayoutBackground__bottomLeft} />
      <BackgroundImage className={s.LoginLayoutBackground__bottomRight} />
    </div>
  );
}
