import { UseMoodleQueryOptionsType } from '../../useMoodleQuery';

export type EnrolledCourseType = {
  id: number; //5,
  fullname: string; //"Тестовый курс 1",
  shortname: string; //"Тестовый курс 1",
  idnumber: string; //"",
  summary: string; //"<p>Описание курса длинное в несколько строк длинное в несколько строк длинное в несколько строк длинное в несколько строк длинное в несколько строк</p>",
  summaryformat: number; //1,
  startdate: number; //1698440400,
  enddate: number; //0,
  visible: boolean; //true,
  showactivitydates: boolean; //true,
  showcompletionconditions: boolean; //true,
  pdfexportfont: string; //"",
  fullnamedisplay: string; //"Тестовый курс 1",
  viewurl: string; //"https://edu-admin.skolca.ru/course/view.php?id=5",
  courseimage: string; //base64 | url
  progress: number; //0,
  hasprogress: boolean; //false,
  isfavourite: boolean; //false,
  hidden: boolean; //false,
  showshortname: boolean; //false,
  coursecategory: string; //"Общее"
};

export enum CoreCourseGetEnrolledCoursesClassification {
  All = 'all',
  InProgress = 'inprogress',
  Future = 'future',
  Past = 'past',
  Hidden = 'hidden',
  Favourite = 'favourites',
  Search = 'search'
}

export interface CoreCourseGetEnrolledCoursesQueryArgs {
  classification?: CoreCourseGetEnrolledCoursesClassification;
  limit?: number;
  offset?: number;
  // "{fieldName} ['asc' | 'desc']"
  sort?: string;
  searchvalue?: string;
}

export type CoreCourseGetEnrolledCoursesHookArgs = CoreCourseGetEnrolledCoursesQueryArgs & {
  moodleConfig?: UseMoodleQueryOptionsType;
};

export interface CoreCourseGetEnrolledCoursesQuery extends CoreCourseGetEnrolledCoursesQueryArgs {
  wsfunction: 'core_course_get_enrolled_courses_by_timeline_classification';
}

// Get the list of courses where a user is enrolled in
export type CoreCourseGetEnrolledCoursesData = { courses: Array<EnrolledCourseType>; nextoffset: number };
