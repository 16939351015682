import React from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from 'utils/types';
import { ICheckboxProps } from 'components/Checkbox';
import { Radio, RadioProps } from '../Radio';
import s from './RadioGroup.module.scss';

export enum RadioGroupDirection {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export interface IRadioGroupOption extends RadioProps {
  /**
   * Отображаемая подпись значения
   */
  label?: React.ReactNode;
  /**
   * Значение
   */
  value?: string;
}

export interface RadioGroupProps
  extends Omit<ICheckboxProps, 'checked' | 'label' | 'onChange' | 'value' | 'style' | 'classes' | 'rootProps'> {
  /**
   * Значение RadioGroup
   */
  value?: string | null;
  /**
   * Направление RadioGroup
   */
  direction?: RadioGroupDirection;
  /**
   * Обработчик изменения значения RadioGroup. Принимает на вход значение выбранного Radio
   */
  onChange?: (value: string | null) => void;
  /**
   * Массив опций
   */
  options: IRadioGroupOption[];
  /**
   * Дополнительные пропы корневого элемента
   */
  rootProps?: DivPropsWithoutRef;
  /**
   * Дополнительные css-стили корневого элемента
   */
  style?: React.CSSProperties;
  /**
   * Дополнительные классы элементов
   * * `radio` - класс одной радио-кнопки
   * * `itemClasses` - классы для компонентов радио-кнопки. Соответствуют пропу `classes` компонента Checkbox
   */
  classes?: {
    radio?: string;
    itemClasses?: ICheckboxProps['classes'];
  };
}

export function RadioGroup({
  value,
  options,
  onChange,
  rootProps,
  className,
  classes,
  direction = RadioGroupDirection.vertical,
  style,
  ...props
}: RadioGroupProps) {
  return (
    <div
      className={clsx(
        s.RadioGroup,
        {
          [s.RadioGroup_vertical]: direction === RadioGroupDirection.vertical,
          [s.RadioGroup_horizontal]: direction === RadioGroupDirection.horizontal
        },
        className,
        rootProps?.className
      )}
      style={style}
      {...rootProps}>
      {options.map((option, iOption) => {
        return (
          <Radio
            key={iOption}
            className={clsx(s.RadioGroup__radio, classes?.radio)}
            checked={value === option.value}
            onChange={onChange}
            classes={classes?.itemClasses}
            {...option}
            {...props}
          />
        );
      })}
    </div>
  );
}
