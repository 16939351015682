import React, { useState } from 'react';
import clsx from 'clsx';
import { Icon, IconSize } from '../Icon';
import { Icons } from '../Icons';
import s from './AsideContainer.module.scss';

export interface AsideContainerClasses {
  root?: (extended?: boolean) => string;
  toggle?: (extended?: boolean) => string;
  inner?: (extended?: boolean) => string;
  content?: (extended?: boolean) => string;
}

export enum AsideContainerVariant {
  Left = 'left',
  Right = 'right'
}

export interface AsideContainerProps {
  className?: string;
  classes?: AsideContainerClasses;
  variant?: AsideContainerVariant;
  defaultExtended?: boolean;
  children?: (extended?: boolean) => React.ReactNode;
}

const toggleIcons = {
  [AsideContainerVariant.Left]: [Icons.CHEVRON_DOUBLE_CURVED_LEFT, Icons.CHEVRON_DOUBLE_CURVED_RIGHT],
  [AsideContainerVariant.Right]: [Icons.CHEVRON_DOUBLE_CURVED_RIGHT, Icons.CHEVRON_DOUBLE_CURVED_LEFT]
};

export const AsideContainer = ({
  className,
  classes,
  variant = AsideContainerVariant.Left,
  defaultExtended,
  children
}: AsideContainerProps) => {
  const [extended, setExtended] = useState(!!defaultExtended);
  const toggleIcon = toggleIcons[variant];

  return (
    <div
      className={clsx(s.AsideContainer, s[`AsideContainer_${variant}`], className, classes?.root?.(extended), {
        [s.AsideContainer_extended]: extended
      })}>
      <button
        className={clsx(s.AsideContainer__toggle, s[`AsideContainer__toggle_${variant}`], classes?.toggle?.(extended))}
        onClick={() => setExtended(!extended)}>
        <Icon icon={extended ? toggleIcon[0] : toggleIcon[1]} size={IconSize.small} />
      </button>

      <div className={clsx(s.AsideContainer__inner, classes?.inner?.(extended))}>
        <div className={clsx(s.AsideContainer__content, classes?.content?.(extended))}>{children?.(extended)}</div>
      </div>
    </div>
  );
};
