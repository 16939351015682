import React, { PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Icons } from '../Icons';
import { Icon, IconProps } from '../Icon';
import { IconButton, IconButtonSize, IconButtonVariant } from '../IconButton';
import s from './Collapse.module.scss';

export enum CollapseVariant {
  Normal = 'normal',
  Success = 'success',
  InProgress = 'inProgress',
  Locked = 'locked'
}

export enum CollapseSize {
  Medium = 'medium',
  Small = 'small'
}

export interface CollapseProps {
  className?: string;
  classes?: {
    head?: string;
    title?: string;
    button?: string;
    content?: string;
  };
  variant?: CollapseVariant;
  size?: CollapseSize;
  index?: number | string;
  title?: React.ReactNode;
  icon?: IconProps['icon'] | false;
  open?: boolean;
}

const icons = {
  [CollapseVariant.Normal]: Icons.ARROW_RIGHT,
  [CollapseVariant.Success]: Icons.CHECK,
  [CollapseVariant.InProgress]: Icons.CLOCK,
  [CollapseVariant.Locked]: Icons.LOCK
};

export const Collapse = React.forwardRef<HTMLDivElement, PropsWithChildren<CollapseProps>>(function Collapse(
  {
    className,
    classes,
    variant = CollapseVariant.Normal,
    size = CollapseSize.Medium,
    index,
    title,
    icon,
    open: propsOpen,
    children
  },
  ref
) {
  const [open, setOpen] = useState(!!propsOpen);

  useEffect(() => {
    setOpen(!!propsOpen);
  }, [propsOpen]);

  const locked = variant === CollapseVariant.Locked;

  const toggle = () => {
    if (locked) return;
    setOpen(!open);
  };

  const currIcon = icon === false ? null : icon || icons[variant];

  return (
    <div
      ref={ref}
      className={clsx(s.Collapse, s[`Collapse_${variant}`], s[`Collapse_${size}`], className, {
        [s.Collapse_open]: open
      })}>
      <div
        className={clsx(s.Collapse__head, classes?.head, {
          [s.Collapse__head_clickable]: !locked
        })}
        onClick={toggle}>
        {currIcon && (
          <div className={s.Collapse__icon}>
            <Icon icon={currIcon} />
          </div>
        )}
        <div className={clsx(s.Collapse__title, classes?.title)}>
          {index && <div className={s.Collapse__index}>{index}.</div>}
          {title}
        </div>
        <IconButton
          className={clsx(s.Collapse__toggleButton, classes?.button)}
          classes={{
            icon: clsx(s.Collapse__toggleButtonIcon, {
              [s.Collapse__toggleButtonIcon_open]: open
            })
          }}
          icon={Icons.CHEVRON_CURVED_DOWN}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          disabled={locked}
          rounded
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        />
      </div>
      {open && <div className={clsx(s.Collapse__content, classes?.content)}>{children}</div>}
    </div>
  );
});
