import React from 'react';
import { useAttachments } from 'hooks/useAttachments';
import { LinkFile } from 'components/Link/LinkFile/LinkFile';
import { ModuleBaseProps } from '../module-types';
import s from '../Module.module.scss';

type ModuleResourceContentProps = Pick<ModuleBaseProps, 'contents'>;

export const ModuleResourceContent = ({ contents }: ModuleResourceContentProps) => {
  const attachments = useAttachments(contents?.filter((c) => c.type === 'file'));

  if (!attachments?.length) return null;

  return (
    <div className={s.Module__attachments}>
      {attachments.map((item, iItem) => (
        <LinkFile key={iItem} {...item} />
      ))}
    </div>
  );
};
