import React from 'react';
import { useAuth } from '../auth';
import { useMoodleQuery } from '../../../store/moodleAPI';
import {
  ICoreWebserviceGetSiteInfoData,
  ICoreWebserviceGetSiteInfoQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_webservice_get_site_info';
import { SiteInfoContext } from './SiteInfoContext';

export function SiteInfoProvider({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useAuth();
  const queryResult = useMoodleQuery<ICoreWebserviceGetSiteInfoQuery, ICoreWebserviceGetSiteInfoData>(
    // данные автоматически обнуляются при выходе из системы (false) / пере-запрос при смене пользователя
    { wsfunction: 'core_webservice_get_site_info' },
    {
      revalidateIfStale: false,
      skip: !isLoggedIn()
    }
  );

  return <SiteInfoContext.Provider value={{ ...queryResult }}>{children}</SiteInfoContext.Provider>;
}
