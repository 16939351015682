import clsx from 'clsx';
import { AttachmentBase } from '../AttachmentBase';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { sizeFormat } from '../../../utils/sizeFormat';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../IconButton';
import { Icons } from '../../Icons';
import s from './AttachmentUpload.module.scss';

export interface AttachmentUploadProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Имя файла
   */
  filename: string;
  /**
   * Размер файла
   */
  size?: number;

  onClickRemove?: () => void;
}

export function AttachmentUpload(props: AttachmentUploadProps) {
  const { className, filename, size, onClickRemove } = props;

  return (
    <AttachmentBase
      className={clsx(s.AttachmentUpload, className)}
      download={filename}
      preview={<FileIcon className={s.AttachmentUpload__extIcon} />}
      filename={filename}
      caption={size ? sizeFormat(size) : ''}
      actions={
        <IconButton
          component={'div'}
          icon={Icons.CANCEL}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          onClick={() => onClickRemove?.()}
        />
      }
    />
  );
}
