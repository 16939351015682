import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCourseGetEnrolledCoursesClassification,
  CoreCourseGetEnrolledCoursesData,
  CoreCourseGetEnrolledCoursesHookArgs,
  CoreCourseGetEnrolledCoursesQuery
} from '../moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';

export const useEnrolledCourses = ({ moodleConfig, ...props }: Partial<CoreCourseGetEnrolledCoursesHookArgs>) => {
  const classification = props?.searchvalue
    ? CoreCourseGetEnrolledCoursesClassification.Search
    : props?.classification || CoreCourseGetEnrolledCoursesClassification.All;

  return useMoodleQuery<CoreCourseGetEnrolledCoursesQuery, CoreCourseGetEnrolledCoursesData>(
    {
      wsfunction: 'core_course_get_enrolled_courses_by_timeline_classification',
      ...props,
      classification
    },
    moodleConfig
  );
};
