import { useCallback, useEffect, useRef, useState } from 'react';
import { useSingleTimeout } from '../useSingleTimeout';

export type UseQuizTimerProps = {
  endtime: number | null;
  onFinishQuiz: () => void;
};

export function useQuizTimer({ endtime, onFinishQuiz }: UseQuizTimerProps) {
  const [time, setTime] = useState<number | null>(null);

  const timeout = useSingleTimeout();

  const onceRef = useRef(false);

  const processTimeout = useCallback(() => {
    if (!endtime) {
      return;
    }

    if (endtime * 1000 <= Date.now()) {
      timeout.clear();
      onFinishQuiz();
      return;
    }

    let time = Math.floor((endtime * 1000 - Date.now()) / 1000);
    if (time < 0) time = 0;
    setTime(time);
    timeout.set(processTimeout, 1000);
  }, [endtime, onFinishQuiz, timeout]);

  useEffect(() => {
    if (onceRef.current) {
      return;
    }

    if (endtime) {
      processTimeout();
    }
  }, [endtime, processTimeout, timeout]);

  return {
    time
  };
}
