import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCourseGetCourseModuleData,
  CoreCourseGetCourseModuleHookArgs,
  CoreCourseGetCourseModuleQuery
} from '../moodleTypes/_functions/core_course_get_course_module';

export const useCourseModule = ({ moodleConfig, cmid }: Partial<CoreCourseGetCourseModuleHookArgs>) => {
  return useMoodleQuery<CoreCourseGetCourseModuleQuery, CoreCourseGetCourseModuleData>(
    {
      wsfunction: 'core_course_get_course_module',
      cmid: cmid || ''
    },
    {
      skip: isNil(cmid),
      ...moodleConfig
    }
  );
};
