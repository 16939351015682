import { useSiteInfo } from 'app/providers/siteInfo';
import { useAuth } from 'app/providers/auth';

import { Container } from 'components/Container';
import { ProfileHead } from './_components/ProfileHead/ProfileHead';
import { ProfileContent } from './_components/ProfileContent/ProfileContent';
import { ProfileFooter } from './_components/ProfileFooter/ProfileFooter';

import s from './ProfilePage.module.scss';

export function ProfilePage() {
  const info = useSiteInfo();
  const auth = useAuth();

  return (
    <Container className={s.ProfilePage}>
      <ProfileHead name={info.data?.fullname} />
      <ProfileContent />
      <ProfileFooter logOut={auth.logOut} />
    </Container>
  );
}
