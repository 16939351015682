import { MoodleContent } from '../../../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionReviewTypeProps } from '../types';
import { QuizQuestionReviewLayout } from '../../QuizQuestionReviewLayout';

export function DescriptionReview({ description }: QuizQuestionReviewTypeProps) {
  return (
    <QuizQuestionReviewLayout title={'Информационный блок'}>
      <MoodleContent wide>{description}</MoodleContent>
    </QuizQuestionReviewLayout>
  );
}
