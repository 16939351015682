import React from 'react';
import { generatePath } from 'react-router-dom';
import clsx from 'clsx';
import { IModule, ModuleName } from 'store/moodleAPI/moodleTypes/Module';
import { COURSE_MODULE_PAGE_ROUTE } from 'app/routes/routes';
import { CoursesUtils, ModuleVariant } from 'utils/courses/CoursesUtils';
import { Module } from '../Module/Module';
import s from './Modules.module.scss';

function getModulesIndexes(modules?: IModule[]): number[] {
  if (!modules?.length) return [];
  const indexes: number[] = [];
  let skipCount = 0;
  modules.forEach((m, i) => {
    indexes.push(i - skipCount);
    if (m.modname === ModuleName.label) {
      skipCount++;
    }
  });

  return indexes;
}

export interface ModulesProps {
  index: number | string;
  courseId?: string;
  modules?: IModule[];
  sectionLocked?: boolean;
}

export const Modules = ({ index, courseId, modules, sectionLocked }: ModulesProps) => {
  const lockedVariant = sectionLocked ? ModuleVariant.Locked : undefined;
  const indexes = getModulesIndexes(modules);

  return (
    <div className={s.Modules}>
      <div className={s.Modules__list}>
        {modules?.map((mod, iMod, array) => {
          const variant = CoursesUtils.mapModuleCompletionToModuleVariant(mod);
          const nextVariant = array[iMod + 1]
            ? CoursesUtils.mapModuleCompletionToModuleVariant(array[iMod + 1])
            : undefined;
          const nextLocked = !!lockedVariant || nextVariant === ModuleVariant.Locked;

          return (
            <div key={iMod} className={s.Modules__item}>
              <div
                key={iMod}
                className={clsx(s.Progress, s[`Progress_${variant}`], {
                  [s.Progress_nextLocked]: nextLocked
                })}>
                {iMod < array.length - 1 && <div className={s.Progress__line} />}
                <div className={s.Progress__dot} />
              </div>

              <div className={s.Module}>
                <Module
                  index={`${index}.${indexes[iMod] + 1}`}
                  variant={lockedVariant || variant}
                  {...mod}
                  to={generatePath(COURSE_MODULE_PAGE_ROUTE, {
                    courseId,
                    moduleId: mod.id
                  })}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
