import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { differenceInCalendarDays } from 'date-fns';
import { Container } from 'components/Container';
import { Heading, HeadingSize } from 'components/Heading';
import { Tabs, TabsProps } from 'components/Tabs';
import { Loader, LoaderSize } from 'components/Loader';
import { Icons } from 'components/Icons';
import { getDateByTimestamp } from 'utils/date';
import { Label, LabelVariant } from 'components/Label';
import { useIsMobile } from 'hooks/useIsMobile';
import { useGoBack } from 'hooks/useGoBack';
import { useCourseData } from 'hooks/course/useCourseData';
import { useSearchParams } from 'hooks/useSearchParams';
import { ButtonBackBox } from 'components/Button/ButtonBack';
import { NOT_FOUND_PAGE_ROUTE } from '../../app/routes/routes';
import { ModuleName } from '../../store/moodleAPI/moodleTypes/Module';
import { CourseAbout } from './_components/CourseAbout/CourseAbout';
import { CourseHead } from './_components/CourseHead/CourseHead';
import { CourseProgram } from './_components/CourseProgram/CourseProgram';
import { CourseEvents } from './_components/CourseEvents';
import s from './CoursePage.module.scss';

enum CourseTabs {
  About = 'about',
  Program = 'program',
  Schedule = 'schedule'
}

export function CoursePage() {
  const isMobile = useIsMobile();
  const goBack = useGoBack();
  const { courseId } = useParams<{ courseId: string }>();
  const [query, changeQuery] = useSearchParams(['tab', 'asd']);

  const tab = (query.tab || CourseTabs.About) as CourseTabs;
  const setTab = (tab: CourseTabs) => {
    changeQuery(
      {
        tab
      },
      true
    );
  };

  const { data, isLoading } = useCourseData(courseId);
  const { course, contents, teachers, customFields } = data;

  const tabs: TabsProps<CourseTabs>['tabs'] = useMemo(() => {
    const arr = [
      {
        id: CourseTabs.About,
        label: 'О курсе'
      }
    ];

    if (data.contents) {
      arr.push({
        id: CourseTabs.Program,
        label: 'Программа курса'
      });
    }

    arr.push({
      id: CourseTabs.Schedule,
      label: 'Расписание'
    });

    return arr;
  }, [data]);

  const courseStats = useMemo(() => {
    if (!course || !contents?.length) return [];
    const { startdate, enddate } = course;

    const lArray = [
      {
        label: `Темы: ${contents.length}`,
        iconLeft: Icons.SUBJECT_SMALL
      }
    ];

    const innerAmount = contents.reduce((summ, curr) => {
      return summ + curr.modules.filter((module) => module.modname === ModuleName.quiz).length || 0;
    }, 0);

    if (innerAmount) {
      lArray.push({
        label: `Тесты: ${innerAmount}`,
        iconLeft: Icons.TEST2_SMALL
      });
    }

    const daysAmount =
      !startdate || !enddate ? 0 : differenceInCalendarDays(getDateByTimestamp(enddate), getDateByTimestamp(startdate));

    if (daysAmount) {
      lArray.push({
        label: `Дни: ${daysAmount}`,
        iconLeft: Icons.CALENDAR_SMALL
      });
    }

    return lArray;
  }, [contents, course]);

  return (
    <Container
      className={clsx(s.CoursePage, {
        [s.CoursePage_centered]: isLoading || !course
      })}>
      {isLoading ? (
        <Loader size={LoaderSize.large} />
      ) : !course ? (
        <Navigate to={NOT_FOUND_PAGE_ROUTE} replace />
      ) : (
        <>
          <div className={s.CoursePage__head}>
            <CourseHead course={course} stats={courseStats} customFields={customFields} onGoBack={goBack} />
          </div>

          <div className={s.CoursePage__content}>
            <div className={s.CoursePage__contentHead}>
              {isMobile && (
                <Label className={s.CoursePage__category} label={course.categoryname} variant={LabelVariant.Tertiary} />
              )}

              {!isMobile && <ButtonBackBox onGoBack={goBack} />}

              <Heading
                classes={{
                  title: s.CoursePage__title
                }}
                component={'h3'}
                size={HeadingSize.H3}
                pageTitle={[course.fullname, 'Курсы']}>
                {course.fullname}
              </Heading>

              {isMobile && !!courseStats.length && <Label className={s.CoursePage__stats} labels={courseStats} />}
            </div>

            <Tabs tabs={tabs} activeId={tab} setActive={setTab} />

            {tab === CourseTabs.About && <CourseAbout {...course} teachers={teachers} />}
            {tab === CourseTabs.Program && <CourseProgram courseId={courseId} contents={contents} />}
            {tab === CourseTabs.Schedule && <CourseEvents courseId={courseId} />}
          </div>
        </>
      )}
    </Container>
  );
}
