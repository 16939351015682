import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizViewAttemptData,
  ModQuizViewAttemptHookArgs,
  ModQuizViewAttemptQuery
} from '../moodleTypes/_functions/mod_quiz_view_attempt';

export function useQuizViewAttempt({
  moodleConfig,
  attemptid,
  page,
  ...props
}: Partial<ModQuizViewAttemptHookArgs> = {}) {
  return useMoodleQuery<ModQuizViewAttemptQuery, ModQuizViewAttemptData>(
    {
      wsfunction: 'mod_quiz_view_attempt',
      attemptid: attemptid ?? 0,
      page: page ?? 0,
      ...props
    },
    {
      ...moodleConfig,
      skip: isNil(attemptid) || isNil(page) || moodleConfig?.skip
    }
  );
}
