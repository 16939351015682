import React from 'react';
import clsx from 'clsx';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';
import { ModuleGeneralProps } from './module-types';
import s from './Module.module.scss';

export type ModuleTextMediaProps = ModuleGeneralProps;

export const ModuleTextMedia = ({ description, shouldBeSubmitted, submitLoading, onSubmit }: ModuleTextMediaProps) => {
  return (
    <div className={clsx(s.Module, s.ModuleTextMedia)}>
      {description && <MoodleContent className={s.ModuleTextMedia__content}>{description}</MoodleContent>}
      {shouldBeSubmitted && (
        <div className={s.Module__actions}>
          <Button
            variant={ButtonVariant.primary}
            size={ButtonSize.small}
            leftIcon={Icons.CHECK}
            isLoading={submitLoading}
            onClick={onSubmit}>
            Отметить выполненным
          </Button>
        </div>
      )}
    </div>
  );
};
