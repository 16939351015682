import { DataPlaceholder } from '../DataPlaceholder/DataPlaceholder';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { Icons } from '../Icons';
import { useTooltip } from '../Tooltip';
import { Icon, IconSize } from '../Icon';

export interface MoodleAuthErrorPlaceholderProps {
  className?: string;
  logOut?: () => void;
}

export function MoodleAuthErrorPlaceholder({ className, logOut }: MoodleAuthErrorPlaceholderProps) {
  const {
    component: tooltip,
    setTargetRef,
    targetProps
  } = useTooltip({
    placement: 'top',
    description:
      'Ошибка может быть связана с использованием режима инкогнито, запретом на использование файлов cookie или их ручной очисткой.'
  });

  return (
    <DataPlaceholder
      className={className}
      description={
        <p ref={setTargetRef} {...targetProps}>
          <span>Произошла ошибка авторизации </span>
          <Icon icon={Icons.WARNING_FILLED} size={IconSize.small} />
          <br />
          <span>Обновите страницу или перезайдите в систему</span>
          {tooltip}
        </p>
      }>
      <Button
        variant={ButtonVariant.secondary}
        size={ButtonSize.medium}
        leftIcon={Icons.LOGOUT}
        onClick={() => logOut?.()}>
        Выйти
      </Button>
    </DataPlaceholder>
  );
}
