import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export interface CheckProfilePayload {
  username: string;
  email: string;
}

export function checkProfile(
  payload: CheckProfilePayload,
  target?: Window | null
): Promise<MoodleMessage.CheckProfileResponse> {
  return new MoodleIframeBridge<MoodleMessage.CheckProfileRequest, MoodleMessage.CheckProfileResponse>(
    target ?? window,
    'CHECK_PROFILE',
    'CHECK_PROFILE'
  ).send(payload);
}
