import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge, ProcessResponseMessage } from './message-base';

export interface LoginPayload {
  username: string;
  password: string;
}

export function login(
  payload: LoginPayload,
  target?: Window | null,
  processResponse?: ProcessResponseMessage<MoodleMessage.PageOpenResponse>
) {
  return new MoodleIframeBridge<MoodleMessage.LoginRequest, MoodleMessage.PageOpenResponse>(
    target ?? window,
    'LOGIN',
    'LOAD'
  ).send(payload, processResponse);
}
