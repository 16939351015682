import { isNil } from 'lodash-es';
import {
  ModQuizGetQuizAccessInformationData,
  ModQuizGetQuizAccessInformationHookArgs,
  ModQuizGetQuizAccessInformationQuery
} from '../moodleTypes/_functions/mod_quiz_get_quiz_access_information';
import { useMoodleQuery } from '../useMoodleQuery';

export function useQuizAccessInformation({
  moodleConfig,
  quizid
}: Partial<ModQuizGetQuizAccessInformationHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetQuizAccessInformationQuery, ModQuizGetQuizAccessInformationData>(
    {
      wsfunction: 'mod_quiz_get_quiz_access_information',
      quizid: quizid ?? 0
    },
    {
      ...moodleConfig,
      skip: isNil(quizid) || moodleConfig?.skip
    }
  );
}
