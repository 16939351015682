import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps } from './Input';

export interface InputDelayedProps extends InputProps {
  onChangeDelayed?: (value: InputProps['value']) => void;
  delay?: number;
}

export const InputDelayed = ({ delay = 1000, value, onChange, ...props }: InputDelayedProps) => {
  const [val, setVal] = useState(value);
  const delayTORef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setVal(e.target.value);
      clearTimeout(delayTORef.current);
      delayTORef.current = setTimeout(() => {
        onChange?.(e);
      }, delay);
    },
    [delay, onChange]
  );

  return <Input value={val} onChange={handleChange} {...props} />;
};
