import { memo } from 'react';
import clsx from 'clsx';
import { ReactFCC } from 'utils/ReactFCC';
import s from './ModalFooter.module.scss';

export enum ContentPosition {
  left = 'left',
  center = 'center',
  right = 'right'
}
export interface ModalFooterProps {
  className?: string;
  contentPositions?: ContentPosition;
}

export const ModalFooter: ReactFCC<ModalFooterProps> = memo((props) => {
  const { children, className, contentPositions = ContentPosition.center } = props;

  return <div className={clsx(s.ModalFooter, s[`ModalFooter_${contentPositions}`], className)}>{children}</div>;
});
ModalFooter.displayName = 'ModalFooter';
