import React from 'react';
import clsx from 'clsx';
import { IconProps } from '../Icon';
import { IconButton, IconButtonSize, IconButtonVariant } from '../IconButton';
import s from './ViewSwitch.module.scss';

export type ViewSwitchValueType = string | number | null;

export type ViewSwitchOption<Value = ViewSwitchValueType> = {
  value: Value;
  icon: IconProps['icon'];
  disabled?: boolean;
};

export interface ViewSwitchProps<Value> {
  className?: string;
  options: ViewSwitchOption<Value>[];
  value: Value;
  onChange?: (value: Value) => void;
}

export const ViewSwitch = <Value,>({ className, options, value, onChange }: ViewSwitchProps<Value>) => {
  return (
    <div className={clsx(s.ViewSwitch, className)}>
      {options.map((item, iItem) => (
        <IconButton
          key={iItem}
          className={s.ViewSwitch__button}
          variant={item.value === value ? IconButtonVariant.secondary : IconButtonVariant.ghost}
          size={IconButtonSize.large}
          icon={item.icon}
          disabled={item.disabled}
          onClick={() => onChange?.(item.value)}
        />
      ))}
    </div>
  );
};
