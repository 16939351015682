import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink, Navigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { InputIcon, InputVariant, RichInput } from '../../components/Input';
import { Icons } from '../../components/Icons';
import { useAuth } from '../../app/providers/auth';
import { LOCAL_STORAGE_KEY_NEXT } from '../../app/localStorageKeys';
import { LoginLayout } from '../_layouts/LoginLayout';
import { Heading, HeadingSize } from '../../components/Heading';
import { RECOVERY_PAGE_ROUTE } from '../../app/routes/routes';
import s from './LoginPage.module.scss';

interface LoginFields {
  username: string;
  password: string;
}

export function LoginPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoggedIn, logIn } = useAuth();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors }
  } = useForm<LoginFields>({
    defaultValues: {
      username: '',
      password: ''
    },
    reValidateMode: 'onChange'
  });

  const [username, password] = watch(['username', 'password']);

  const onSubmit: SubmitHandler<LoginFields> = useCallback(
    async (data) => {
      try {
        setLoading(true);
        clearErrors();
        await logIn(data.username, data.password);
      } catch (err: any) {
        setError('password', {
          message: err?.message
        });
      } finally {
        setLoading(false);
      }
    },
    [clearErrors, logIn, setError]
  );

  useEffect(() => {
    // устанавливает целевую страницу для возвращения после успешной авторизации
    localStorage.setItem(LOCAL_STORAGE_KEY_NEXT, next || '/');
  }, [next]);

  // редирект авторизованного пользователя на целевую страницу
  if (isLoggedIn()) return <Navigate to={next ? decodeURIComponent(next) : '/'} replace />;

  return (
    <LoginLayout>
      <form className={s.LoginPage__form} onSubmit={handleSubmit(onSubmit)}>
        <Heading className={s.LoginPage__title} component={'h1'} size={HeadingSize.H4} pageTitle={'Вход в систему'}>
          Вход в систему
        </Heading>

        <div className={s.LoginPage__inputs}>
          <RichInput
            variant={InputVariant.round}
            type={'text'}
            placeholder={'Электронная почта или логин'}
            errorMessage={errors.username?.message}
            registration={register('username', {
              required: 'Поле обязательно к заполнению'
            })}
          />
          <RichInput
            variant={InputVariant.round}
            placeholder={'Пароль'}
            type={passwordVisible ? 'text' : 'password'}
            autoCapitalize={'none'}
            errorMessage={errors.password?.message}
            registration={register('password', {
              required: 'Поле обязательно к заполнению'
            })}
            right={() => (
              <>
                {password && <InputIcon icon={Icons.CANCEL} onClick={() => setValue('password', '')} />}
                <InputIcon
                  icon={passwordVisible ? Icons.EYE_CLOSED : Icons.EYE_OPENED}
                  onClick={() => setPasswordVisible((value) => !value)}
                />
              </>
            )}
          />
        </div>

        <Link component={RouterLink} to={RECOVERY_PAGE_ROUTE} underlined standalone>
          Не можете войти?
        </Link>
        <Button type={'submit'} isLoading={loading} disabled={!username || !password}>
          Войти
        </Button>
      </form>
    </LoginLayout>
  );
}
