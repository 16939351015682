import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { ReactComponent as Forward } from 'assets/icons/forward.svg';
import { ReactComponent as Test } from 'assets/icons/test.svg';
import { ReactComponent as Burger } from 'assets/icons/burger.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronCurvedDown } from 'assets/icons/chevron-curved-down.svg';
import { ReactComponent as ChevronCurvedLeft } from 'assets/icons/chevron-curved-left.svg';
import { ReactComponent as ChevronCurvedRight } from 'assets/icons/chevron-curved-right.svg';
import { ReactComponent as ChevronDoubleCurvedLeft } from 'assets/icons/chevron-double-curved-left.svg';
import { ReactComponent as ChevronDoubleCurvedRight } from 'assets/icons/chevron-double-curved-right.svg';
import { ReactComponent as ChevronCurvedLeftSmall } from 'assets/icons/chevron-curved-left-small.svg';
import { ReactComponent as ChevronCurvedRightSmall } from 'assets/icons/chevron-curved-right-small.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ViewCard } from 'assets/icons/view-card.svg';
import { ReactComponent as ViewList } from 'assets/icons/view-list.svg';
import { ReactComponent as ViewListSummary } from 'assets/icons/view-list-summary.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/eye-opened.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-closed.svg';
import { ReactComponent as HeartOutline } from 'assets/icons/heart-outline.svg';
import { ReactComponent as HeartFill } from 'assets/icons/heart-fill.svg';
import { ReactComponent as NavCourses } from 'assets/icons/nav/courses.svg';
import { ReactComponent as PlaceholderSearch } from 'assets/icons/placeholders/search-placeholder.svg';
import { ReactComponent as CalendarSmall } from 'assets/icons/calendar-small.svg';
import { ReactComponent as SubjectSmall } from 'assets/icons/subject-small.svg';
import { ReactComponent as SubjectMedium } from 'assets/icons/subject-medium.svg';
import { ReactComponent as Test2Small } from 'assets/icons/test2-small.svg';
import { ReactComponent as Test2Medium } from 'assets/icons/test2-medium.svg';
import { ReactComponent as Test3Small } from 'assets/icons/test3-small.svg';
import { ReactComponent as Test3Medium } from 'assets/icons/test3-medium.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as ClockSmall } from 'assets/icons/clock-small.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as MailSmall } from 'assets/icons/mail-small.svg';
import { ReactComponent as CopySmall } from 'assets/icons/copy-small.svg';
import { ReactComponent as AttachmentSmall } from 'assets/icons/attachment-small.svg';
import { ReactComponent as Checkbox } from 'assets/icons/checkbox.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as Advice } from 'assets/icons/advice.svg';
import { ReactComponent as Questions } from 'assets/icons/questions.svg';
import { ReactComponent as WarningFilled } from 'assets/icons/warning-filled.svg';

export enum EIconsTypes {
  SPINNER = 'SPINNER',
  FORWARD = 'FORWARD',
  TEST = 'TEST',
  BURGER = 'BURGER',
  CANCEL = 'CANCEL',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_CURVED_DOWN = 'CHEVRON_CURVED_DOWN',
  CHEVRON_CURVED_LEFT = 'CHEVRON_CURVED_LEFT',
  CHEVRON_CURVED_RIGHT = 'CHEVRON_CURVED_RIGHT',
  CHEVRON_DOUBLE_CURVED_LEFT = 'CHEVRON_DOUBLE_CURVED_LEFT',
  CHEVRON_DOUBLE_CURVED_RIGHT = 'CHEVRON_DOUBLE_CURVED_RIGHT',
  CHEVRON_CURVED_LEFT_SMALL = 'CHEVRON_CURVED_LEFT_SMALL',
  CHEVRON_CURVED_RIGHT_SMALL = 'CHEVRON_CURVED_RIGHT_SMALL',
  USER = 'USER',
  LOGOUT = 'LOGOUT',
  CHECK = 'CHECK',
  DOWNLOAD = 'DOWNLOAD',
  CALENDAR = 'CALENDAR',
  SEARCH = 'SEARCH',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  VIEW_CARD = 'VIEW_CARD',
  VIEW_LIST = 'VIEW_LIST',
  VIEW_LIST_SUMMARY = 'VIEW_LIST_SUMMARY',
  EYE_OPENED = 'EYE_OPENED',
  EYE_CLOSED = 'EYE_CLOSED',
  HEART_OUTLINE = 'HEART_OUTLINE',
  HEART_FILL = 'HEART_FILL',
  CALENDAR_SMALL = 'CALENDAR_SMALL',
  SUBJECT_SMALL = 'SUBJECT_SMALL',
  SUBJECT_MEDIUM = 'SUBJECT_MEDIUM',
  TEST2_SMALL = 'TEST2_SMALL',
  TEST2_MEDIUM = 'TEST2_MEDIUM',
  TEST3_SMALL = 'TEST3_SMALL',
  TEST3_MEDIUM = 'TEST3_MEDIUM',
  CLOCK = 'CLOCK',
  CLOCK_SMALL = 'CLOCK_SMALL',
  LOCK = 'LOCK',
  MAIL_SMALL = 'MAIL_SMALL',
  COPY_SMALL = 'COPY_SMALL',
  ATTACHMENT_SMALL = 'ATTACHMENT_SMALL',
  CHECKBOX = 'CHECKBOX',
  UPLOAD = 'UPLOAD',
  ADVICE = 'ADVICE',
  QUESTIONS = 'QUESTIONS',
  WARNING_FILLED = 'WARNING_FILLED',

  // NAV
  NAV_COURSES = 'NAV_COURSES',

  // Placeholders
  PLACEHOLDER_SEARCH = 'PLACEHOLDER_SEARCH'
}

type IconsMap = {
  [key in EIconsTypes]: JSX.Element;
};

export const ICONS_MAP: IconsMap = {
  [EIconsTypes.SPINNER]: <Spinner />,
  [EIconsTypes.FORWARD]: <Forward />,
  [EIconsTypes.TEST]: <Test />,
  [EIconsTypes.BURGER]: <Burger />,
  [EIconsTypes.CANCEL]: <Cancel />,
  [EIconsTypes.CHEVRON_LEFT]: <ChevronLeft />,
  [EIconsTypes.CHEVRON_RIGHT]: <ChevronRight />,
  [EIconsTypes.CHEVRON_CURVED_DOWN]: <ChevronCurvedDown />,
  [EIconsTypes.CHEVRON_CURVED_LEFT]: <ChevronCurvedLeft />,
  [EIconsTypes.CHEVRON_CURVED_RIGHT]: <ChevronCurvedRight />,
  [EIconsTypes.CHEVRON_DOUBLE_CURVED_LEFT]: <ChevronDoubleCurvedLeft />,
  [EIconsTypes.CHEVRON_DOUBLE_CURVED_RIGHT]: <ChevronDoubleCurvedRight />,
  [EIconsTypes.CHEVRON_CURVED_LEFT_SMALL]: <ChevronCurvedLeftSmall />,
  [EIconsTypes.CHEVRON_CURVED_RIGHT_SMALL]: <ChevronCurvedRightSmall />,
  [EIconsTypes.USER]: <User />,
  [EIconsTypes.LOGOUT]: <Logout />,
  [EIconsTypes.CHECK]: <Check />,
  [EIconsTypes.DOWNLOAD]: <Download />,
  [EIconsTypes.CALENDAR]: <Calendar />,
  [EIconsTypes.SEARCH]: <Search />,
  [EIconsTypes.ARROW_LEFT]: <ArrowLeft />,
  [EIconsTypes.ARROW_RIGHT]: <ArrowRight />,
  [EIconsTypes.VIEW_CARD]: <ViewCard />,
  [EIconsTypes.VIEW_LIST]: <ViewList />,
  [EIconsTypes.VIEW_LIST_SUMMARY]: <ViewListSummary />,
  [EIconsTypes.EYE_OPENED]: <EyeOpened />,
  [EIconsTypes.EYE_CLOSED]: <EyeClosed />,
  [EIconsTypes.HEART_OUTLINE]: <HeartOutline />,
  [EIconsTypes.HEART_FILL]: <HeartFill />,
  [EIconsTypes.CALENDAR_SMALL]: <CalendarSmall />,
  [EIconsTypes.SUBJECT_SMALL]: <SubjectSmall />,
  [EIconsTypes.SUBJECT_MEDIUM]: <SubjectMedium />,
  [EIconsTypes.TEST2_SMALL]: <Test2Small />,
  [EIconsTypes.TEST2_MEDIUM]: <Test2Medium />,
  [EIconsTypes.TEST3_SMALL]: <Test3Small />,
  [EIconsTypes.TEST3_MEDIUM]: <Test3Medium />,
  [EIconsTypes.CLOCK]: <Clock />,
  [EIconsTypes.CLOCK_SMALL]: <ClockSmall />,
  [EIconsTypes.LOCK]: <Lock />,
  [EIconsTypes.MAIL_SMALL]: <MailSmall />,
  [EIconsTypes.COPY_SMALL]: <CopySmall />,
  [EIconsTypes.ATTACHMENT_SMALL]: <AttachmentSmall />,
  [EIconsTypes.CHECKBOX]: <Checkbox />,
  [EIconsTypes.UPLOAD]: <Upload />,
  [EIconsTypes.ADVICE]: <Advice />,
  [EIconsTypes.QUESTIONS]: <Questions />,
  [EIconsTypes.WARNING_FILLED]: <WarningFilled />,

  // NAV
  [EIconsTypes.NAV_COURSES]: <NavCourses />,

  // Placeholders
  [EIconsTypes.PLACEHOLDER_SEARCH]: <PlaceholderSearch />
};

export const Icons = {
  ...EIconsTypes
};

export type IconTypes = keyof typeof Icons;
