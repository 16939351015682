import React, { useCallback } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';
import { ProgressBar } from 'components/ProgressBar';
import { EnrolledCourseType } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { CourseActionBox } from 'components/ActionBox/CourseActionBox/CourseActionBox';
import { useImageSrc } from 'hooks/useImageSrc';
import { COURSE_PAGE_ROUTE } from 'app/routes/routes';
import s from './CourseCard.module.scss';

export enum CourseCardVariant {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export interface CourseCardProps
  extends Pick<EnrolledCourseType, 'id' | 'courseimage' | 'shortname' | 'progress' | 'hidden' | 'isfavourite'> {
  className?: string;
  variant?: CourseCardVariant;
  coursecategory?: string;
}

export const CourseCard = ({ className, variant = CourseCardVariant.Vertical, ...course }: CourseCardProps) => {
  const { id, courseimage, coursecategory, shortname, progress, hidden, isfavourite } = course;
  const navigate = useNavigate();
  const imageSrc = useImageSrc(courseimage);
  const isHorizontal = variant === CourseCardVariant.Horizontal;
  const url = generatePath(COURSE_PAGE_ROUTE, {
    courseId: id.toString()
  });

  const handleActionButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(url);
    },
    [url, navigate]
  );

  const actionButton = progress ? (
    isHorizontal ? (
      <IconButton
        className={s.CourseCard__actionButton}
        variant={IconButtonVariant.secondary}
        icon={Icons.ARROW_RIGHT}
        rounded
        onClick={handleActionButtonClick}
      />
    ) : (
      <Button
        className={s.CourseCard__actionButton}
        size={ButtonSize.medium}
        variant={ButtonVariant.accentSecondary}
        rightIcon={Icons.ARROW_RIGHT}
        onClick={handleActionButtonClick}>
        Продолжить
      </Button>
    )
  ) : (
    <Button
      className={s.CourseCard__actionButton}
      size={ButtonSize.medium}
      variant={ButtonVariant.secondary}
      rightIcon={Icons.ARROW_RIGHT}
      onClick={handleActionButtonClick}>
      Начать обучение
    </Button>
  );

  const actionBox = (
    <CourseActionBox className={s.CourseCard__actionsBox} courseId={id} favourite={isfavourite} hidden={hidden} />
  );

  return (
    <Link
      className={clsx(s.CourseCard, s[`CourseCard_${variant}`], className, {
        [s.CourseCard_horizontal]: isHorizontal
      })}
      to={url}>
      <div className={s.CourseCard__imageBox}>
        <div
          className={s.CourseCard__image}
          style={{
            backgroundImage: imageSrc ? `url(${imageSrc})` : ''
          }}
        />
        {!isHorizontal && actionBox}
      </div>
      <div className={s.CourseCard__content}>
        <div className={s.CourseCard__head}>
          <div className={s.CourseCard__headContent}>
            {coursecategory && <div className={s.CourseCard__category}>{coursecategory}</div>}
            <div className={s.CourseCard__name}>{shortname}</div>
          </div>
          {isHorizontal && actionBox}
        </div>
        <div className={s.CourseCard__main}>
          {!!progress && (
            <div className={s.CourseCard__progress}>
              <div className={s.CourseCard__progressPercent}>{Math.round(progress)}%</div>
              <ProgressBar value={progress} />
            </div>
          )}
          <div className={s.CourseCard__actions}>{actionButton}</div>
        </div>
      </div>
    </Link>
  );
};
