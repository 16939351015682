import React, { memo } from 'react';
import clsx from 'clsx';
import { Heading, HeadingSize } from 'components/Heading';
import { Icons } from 'components/Icons';
import { ReactFCC } from 'utils/ReactFCC';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../IconButton';
import s from './ModalHeader.module.scss';

export interface ModalHeaderProps {
  className?: string;
  text?: string;
  onClose?: () => void;
  withCloseButton?: boolean;
  center?: boolean;
}

export const ModalHeader: ReactFCC<ModalHeaderProps> = memo((props) => {
  const { onClose, withCloseButton = true, center = true, className, text, children } = props;

  return (
    <div
      className={clsx(s.ModalHeader, className, {
        [s.ModalHeader_headerCenter]: center,
        [s.ModalHeader_withCloseButton]: withCloseButton
      })}>
      <Heading className={s.ModalHeader__content} component={'p'} size={HeadingSize.H3}>
        {children ?? text}
      </Heading>

      {withCloseButton && (
        <IconButton
          className={s.ModalHeader__close}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          icon={Icons.CANCEL}
          onClick={onClose}
          rounded
        />
      )}
    </div>
  );
});

ModalHeader.displayName = 'ModalHeader';
