import { ISection } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_contents';
import { CollapseVariant } from 'components/Collapse';
import { CompletionState, IModule, ModuleName } from 'store/moodleAPI/moodleTypes/Module';

export enum ModuleVariant {
  Normal = 'normal',
  Success = 'success',
  InProgress = 'inProgress',
  Locked = 'locked'
}

export class CoursesUtils {
  static mapCourseContentToCollapseVariant(section: ISection): CollapseVariant {
    if (section.availabilityinfo) {
      return CollapseVariant.Locked;
    }

    const modulesComplete = section.modules.filter(
      (m) =>
        m.completiondata?.state === CompletionState.Complete || m.completiondata?.state === CompletionState.CompletePass
    );

    // Если есть пройденные модули
    if (modulesComplete.length) {
      if (modulesComplete.length === section.modules.length) {
        // Если пройдены все модули
        return CollapseVariant.Success;
      } else {
        // Если пройдены не все модули
        return CollapseVariant.InProgress;
      }
    }

    return CollapseVariant.Normal;
  }

  static mapModuleCompletionToModuleVariant(module: IModule): ModuleVariant {
    if (module.availabilityinfo) {
      return ModuleVariant.Locked;
    }

    /**
     * TODO если module.completion === 2 (0 means none, 1 manual, 2 automatic),
     *  то В АДМИНСКОМ АККАУНТЕ state всегда будет 0, поэтому надо смотреть details
     *  и проверять, что все задания выполнены
     *  P.S. уточнить, нужно ли это править или и так норм
     *  +++ Для page, resource и тд сейчас не отправляется событие mod_*_view_*,
     *  которое необходимо для просмотра модуля
     */
    switch (module.completiondata?.state) {
      case CompletionState.Complete:
      case CompletionState.CompletePass:
        return ModuleVariant.Success;
      case CompletionState.Incomplete:
        return ModuleVariant.InProgress;
      default:
        return ModuleVariant.Normal;
    }
  }

  /**
   * Фильтрует модули в секции, убирая оттуда сертификаты, т.к. для них отсутствует
   * отдельное отображение и выдаются они автоматически
   * @param section
   */
  static filterSectionModules(section: ISection) {
    return section.modules.filter((module) => module.modname !== ModuleName.customcert);
  }
}
