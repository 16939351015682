import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface PageHelmetProps {
  title?: string | (string | undefined)[];
}

export const PageHelmet = ({ title }: PageHelmetProps) => {
  const titleStr = (() => {
    if (!title?.length) return '';
    if (Array.isArray(title)) {
      return title.filter(Boolean).join(' — ');
    }
    return title;
  })();

  if (!titleStr) return null;

  return <Helmet title={titleStr} />;
};
