import MoodleMessage from '@skolca/moodle-hook';

export type ProcessResponseMessage<ResponseData> = (response: ResponseData) => boolean;

const RESEND_TIMEOUT = 1000;

export class MoodleIframeBridge<Request extends MoodleMessage.Request, ResponseData extends MoodleMessage.Response> {
  private answerCheckTimer?: NodeJS.Timeout;

  constructor(
    private target: Window,
    private type: Request['type'],
    private responseType: ResponseData['type'],
    // Метод проверки входящего сообщения:
    //  Если метод соответствует текущему мосту - возвращаем true
    //  В ином случае - возвращаем false
    // Т.е. если вернули false - сообщение обрабатываться не будет, для обработки ошибок ответа нужно добавить
    //  process-метод в send
    private checkMethod?: (response: ResponseData) => boolean
  ) {}

  send(payload?: Omit<Request, 'type'>, process?: ProcessResponseMessage<ResponseData>): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      this.listenResponse()
        .then((response) => {
          if (!process || process(response)) {
            resolve(response);
          } else {
            reject();
          }
        })
        .catch(reject);
      this.sendMessage(payload);
      this.autoCheck(payload);
    });
  }

  private autoCheck(payload?: Omit<Request, 'type'>) {
    this.answerCheckTimer = setTimeout(() => {
      this.sendMessage(payload);
      this.autoCheck(payload);
    }, RESEND_TIMEOUT);
  }

  private sendMessage(payload?: Omit<Request, 'type'>) {
    this.target.postMessage(
      {
        type: this.type,
        ...(payload ?? {})
      },
      '*' // Из-за проблем с кросс-доменом с iframe, пока решено оставить * для взаимодействия
    );
  }

  public listenResponse(): Promise<ResponseData> {
    return new Promise((resolve) => {
      const processResponse = (event: MessageEvent<ResponseData | void>) => {
        if (
          !event.data ||
          event.data.type !== this.responseType ||
          (this.target !== window && event.source !== this.target)
        ) {
          return;
        }

        if (this.checkMethod && !this.checkMethod(event.data)) {
          return;
        }

        clearTimeout(this.answerCheckTimer);

        window.removeEventListener('message', processResponse);

        resolve(event.data);
      };

      window.addEventListener('message', processResponse);
    });
  }
}
