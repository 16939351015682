import { createContext } from 'react';

export interface IAuthData {
  accessToken: string | null;
  expires: number;
}

export interface IAuthContext {
  authData: IAuthData;
  error: Error | null;
  loading: boolean;
  isLoggedIn: () => boolean;
  setToken: (accessToken: string | null, expires?: number) => void;
  getToken: () => Promise<string | null>;
  logIn: (username: string, password: string) => Promise<any>;
  logOut: (error?: Error, reload?: boolean) => void;
  checkAuth(username: string, email: string): Promise<boolean>;
  openChangePasswordPopup(): void;
}

export const AuthContext = createContext<IAuthContext | null>(null);
