import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  COURSE_MODULE_PAGE_ROUTE,
  COURSE_PAGE_PARAM,
  NOT_FOUND_PAGE_ROUTE,
  QUIZ_PAGE_QUIZ_PARAM
} from '../../app/routes/routes';
import { useGoBack } from '../../hooks/useGoBack';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useQuiz, useQuizAttempt, useQuizPagination, useQuizSubmit, useQuizTimer } from '../../hooks/quiz';
import { Loader, LoaderSize } from '../../components/Loader';
import { useCourseModule } from '../../store/moodleAPI/hooks/useCourseModule';
import { usePreventWindowScroll } from '../../hooks/usePreventWindowScroll';
import { QuizAside, QuizFooter, QuizHeader } from './_components';
import { QuizQuestion, QuizAnswerType } from './_components/QuizQuestion';
import { QuizConfirmModal } from './_components/QuizConfirmModal';
import s from './QuizPage.module.scss';

export function QuizPage() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const { courseId: courseIdProp, quizId: quizIdProp } = useParams<{
    [COURSE_PAGE_PARAM]: string;
    [QUIZ_PAGE_QUIZ_PARAM]: string;
  }>();
  const [query, changeQuery] = useSearchParams(['attempt', 'cmid', 'page']);

  const courseId = Number(courseIdProp);
  const quizId = Number(quizIdProp);
  const attemptId = Number(query.attempt) ?? undefined;
  const page = Number(query.page) ?? undefined;
  const moduleId = Number(query.cmid) ?? undefined;

  const { data: moduleData, isLoading: isModuleLoading, error: moduleError } = useCourseModule({ cmid: moduleId });
  const module = moduleData?.cm;

  const { quiz, isLoading: isQuizLoading, error: quizError } = useQuiz({ courseId, quizId: module?.instance });

  // todo обработать accessData
  const {
    attemptData,
    accessData,
    questions,
    allQuestions,
    isLoading: isQuizAttemptLoading,
    error: quizAttemptError,
    clearCache,
    clearAllQuestions
  } = useQuizAttempt(quizId, attemptId, page);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const [pageProcessData, setPageProcessData] = useState<QuizAnswerType[]>([]);
  const sequential = quiz?.navmethod === 'sequential';

  const { pagesCount, setPage, hasPrevPage, hasNextPage, goPrevPage, goNextPage } = useQuizPagination({
    attemptData,
    questions: allQuestions,
    page,
    changeQuery
  });

  const {
    submitQuiz,
    isMutating: isQuizSubmitLoading,
    isFinished
  } = useQuizSubmit({
    attemptData,
    pageProcessData
  });

  const navigate = useNavigate();
  const backPath = useCallback(
    (tab = 'about') => getBackPath(quizId, String(courseId), moduleId, tab),
    [courseId, moduleId, quizId]
  );
  const goBack = useGoBack(backPath('about'), { replace: true }, () => clearCache());

  const [modalOpen, setModalOpen] = useState(false);
  const preventScroll = usePreventWindowScroll();

  const onGoNextPage = async () => {
    await submitQuiz();
    goNextPage();

    if (sequential) {
      clearAllQuestions();
    }
  };

  const onGoPage = async (page: number) => {
    await submitQuiz();
    setPage(page);
  };

  const onFinishQuiz = useCallback(async () => {
    await submitQuiz({ finish: true });
    setModalOpen(false);
    preventScroll(false);
    navigate(backPath('attempts'), { replace: true });
    clearCache();
  }, [backPath, clearCache, navigate, preventScroll, submitQuiz]);

  const { time } = useQuizTimer({
    endtime: (!!quiz?.timelimit && accessData?.endtime) || null,
    onFinishQuiz
  });

  const isLoading = isModuleLoading || isQuizAttemptLoading || isQuizLoading;
  const error = moduleError || quizAttemptError || quizError;

  if (error && !isFinished) {
    return <Navigate to={NOT_FOUND_PAGE_ROUTE} replace />;
  }

  return (
    <>
      <div className={s.QuizPage}>
        {!!module && (
          <QuizHeader moduleName={module?.name} onGoBack={goBack} time={time} setMobileNavOpen={setMobileNavOpen} />
        )}

        <main className={s.QuizPage__main}>
          <div className={s.QuizPage__mainBox}>
            <div
              className={clsx(s.QuizPage__content, {
                [s.QuizPage__content_centered]: isLoading
              })}>
              {isLoading ? (
                <Loader size={LoaderSize.large} />
              ) : (
                questions.map((question, index) => (
                  <QuizQuestion question={question} setPageProcessData={setPageProcessData} key={index} />
                ))
              )}
            </div>
          </div>
        </main>

        {pagesCount && (
          <>
            {allQuestions && (
              <QuizAside
                currentPage={page}
                questions={allQuestions}
                setPage={onGoPage}
                sequential={sequential}
                isLoadingData={isLoading}
                mobileNavOpen={mobileNavOpen}
                setMobileNavOpen={setMobileNavOpen}
              />
            )}

            <QuizFooter
              pagesCount={pagesCount}
              sequential={sequential}
              page={page}
              setPage={onGoPage}
              hasPrevPage={hasPrevPage}
              hasNextPage={hasNextPage}
              onGoPrevPage={goPrevPage}
              onGoNextPage={onGoNextPage}
              onFinishQuiz={() => setModalOpen(true)}
              isLoadingData={isLoading}
              isLoadingSubmit={isQuizSubmitLoading}
            />
          </>
        )}
      </div>

      <QuizConfirmModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={onFinishQuiz}
        isLoadingSubmit={isQuizSubmitLoading}
      />
    </>
  );
}

function getBackPath(quizId?: number, courseId?: string, moduleId?: number, tab: string = 'about') {
  return quizId
    ? generatePath(COURSE_MODULE_PAGE_ROUTE, {
        courseId: String(courseId),
        moduleId: String(moduleId)
      }) + `?tab=${tab}`
    : '';
}
