import clsx from 'clsx';
import { QuizProps } from '../../types';
import { UseQuizDataReturn } from '../../../../../../hooks/quiz';
import { QuizAttempt } from '../QuizAttempt';
import { Loader, LoaderSize } from '../../../../../../components/Loader';
import { DataPlaceholder } from '../../../../../../components/DataPlaceholder/DataPlaceholder';
import s from './QuizAttempts.module.scss';

export interface QuizAttemptsProps extends QuizProps {
  quizData: UseQuizDataReturn;
}

export function QuizAttempts({ quizData }: QuizAttemptsProps) {
  const { attemptsData, quiz, isLoading } = quizData;

  const sumgradesMax = quiz?.sumgrades;
  const decimalpoints = quiz?.decimalpoints;

  const noData = !isLoading && !attemptsData?.attempts.length;

  return (
    <div
      className={clsx(s.QuizAttempts, {
        [s.QuizAttempts__centered]: isLoading || !attemptsData || noData
      })}>
      {isLoading ? (
        <Loader size={LoaderSize.large} />
      ) : noData ? (
        <DataPlaceholder description={'Попытки не найдены'} />
      ) : (
        attemptsData?.attempts.map((attempt, index) => (
          <QuizAttempt {...attempt} sumgradesMax={sumgradesMax} decimalpoints={decimalpoints} key={index} />
        ))
      )}
    </div>
  );
}
