import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreEnrolGetUsersCoursesData,
  CoreEnrolGetUsersCoursesHookArgs,
  CoreEnrolGetUsersCoursesQuery
} from '../moodleTypes/_functions/core_enrol_get_users_courses';

export const useCoursesByUserId = ({ moodleConfig, ...props }: Partial<CoreEnrolGetUsersCoursesHookArgs>) => {
  return useMoodleQuery<CoreEnrolGetUsersCoursesQuery, CoreEnrolGetUsersCoursesData>(
    {
      wsfunction: 'core_enrol_get_users_courses',
      ...props,
      userid: props.userid || 0
    },
    {
      skip: isNil(props.userid),
      ...moodleConfig
    }
  );
};
