import React from 'react';
import { useMoodleQuery } from '../../../store/moodleAPI';
import { useSiteInfo } from '../siteInfo';
import {
  CoreUserGetUsersByFieldData,
  CoreUserGetUsersByFieldQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';
import { UserContext } from './UserContext';

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { data: siteInfoData } = useSiteInfo();

  const userQuery = useMoodleQuery<CoreUserGetUsersByFieldQuery, CoreUserGetUsersByFieldData>(
    {
      wsfunction: 'core_user_get_users_by_field',
      field: 'id',
      values: [String(siteInfoData?.userid || 0)]
    },
    {
      revalidateIfStale: false,
      skip: !siteInfoData?.userid
    }
  );

  return <UserContext.Provider value={userQuery}>{children}</UserContext.Provider>;
}
