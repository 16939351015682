import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useGoBack } from 'hooks/useGoBack';
import { Icons } from '../Icons';
import { Button, ButtonSize, ButtonVariant } from './Button';
import s from './Button.module.scss';

export interface ButtonBackProps {
  className?: string;
  path?: string;
  onClick?: () => void;
}

export const ButtonBack = ({ className, path, onClick, ...props }: ButtonBackProps) => {
  const goBack = useGoBack(path);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      goBack();
    }
  }, [onClick, goBack]);

  return (
    <Button
      className={clsx(s.ButtonBack, className)}
      variant={ButtonVariant.ghost}
      size={ButtonSize.medium}
      leftIcon={Icons.CHEVRON_CURVED_LEFT}
      onClick={handleClick}
      {...props}>
      Назад
    </Button>
  );
};

export interface ButtonBackBoxProps {
  className?: string;
  backPath?: ButtonBackProps['path'];
  onGoBack?: ButtonBackProps['onClick'];
  buttonProps?: Omit<ButtonBackProps, 'path' | 'onClick'>;
}

export const ButtonBackBox = ({ className, backPath, onGoBack, buttonProps }: ButtonBackBoxProps) => {
  return (
    <div className={clsx(s.ButtonBackBox, className)}>
      <ButtonBack path={backPath} onClick={onGoBack} {...buttonProps} />
    </div>
  );
};
