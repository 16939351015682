export enum AuthenticationErrorMessage {
  ERR_NETWORK = 'Ошибка соединения',
  ERR_AUTH = 'Неверные учетные данные',
  ERR_UNKNOWN = 'Непредвиденная ошибка'
}

export class AuthenticationError extends Error {
  constructor(message: AuthenticationErrorMessage) {
    super(message);
    this.name = 'AuthenticationError';
  }
}
