import { useCallback, useState } from 'react';
import { IAttempt } from '../../store/moodleAPI/moodleTypes/Attempt';
import { useQuizStartAttempt } from '../../store/moodleAPI/hooks';
import { IQuiz } from '../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';

export interface UseStartQuizProps {
  quiz?: IQuiz;
  attemptInProgress?: IAttempt | null;
}

export function useStartQuiz({ quiz, attemptInProgress }: UseStartQuizProps) {
  const [startAttemptData, setStartAttemptData] = useState<IAttempt | null>(null);
  const [error, setError] = useState<string | null>();

  const { mutate, isMutating } = useQuizStartAttempt();

  const startQuiz = useCallback(async () => {
    if (attemptInProgress) {
      setStartAttemptData(attemptInProgress);
    } else {
      if (quiz?.id) {
        try {
          const responseData = await mutate({ quizid: quiz.id });

          if (responseData?.data.attempt.id) {
            setStartAttemptData(responseData.data.attempt);
          } else if (responseData?.data.warnings?.length) {
            setError(responseData?.data.warnings.map(({ message }) => message).join(', '));
          }
        } catch (err: any) {
          setError(err.toString());
        }
      }
    }
  }, [attemptInProgress, mutate, quiz]);

  return {
    startQuiz,
    startAttemptData,
    isLoading: isMutating,
    error: error
  };
}
