import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { SelectOptionType } from '../../../../utils/select/types';
import { CourseEventsTimeSort } from '../../../../store/moodleAPI/hooks/useCourseEvents';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { SearchInput } from '../../../../components/Input/SearchInput/SearchInput';
import { Select } from '../../../../components/Select/Select';
import s from './CourseEvents.module.scss';

const sortOptions: SelectOptionType<CourseEventsTimeSort>[] = [
  {
    label: 'Все',
    value: CourseEventsTimeSort.all
  },
  {
    label: 'Просрочены',
    value: CourseEventsTimeSort.overdue
  },
  {
    label: 'Следующие 7 дней',
    value: CourseEventsTimeSort.next_week
  },
  {
    label: 'Следующие 30 дней',
    value: CourseEventsTimeSort.next_month
  },
  {
    label: 'Следующие 3 месяца',
    value: CourseEventsTimeSort.next_three_month
  },
  {
    label: 'Следующие 6 месяцев',
    value: CourseEventsTimeSort.next_six_month
  }
];

type CourseEventsFiltersType = {
  search?: string;
  sort?: CourseEventsTimeSort;
};

export type UseCourseEventsFiltersReturnType = [React.ReactNode, CourseEventsFiltersType];

export const useCourseEventsFilters = (): UseCourseEventsFiltersReturnType => {
  const [query, changeQuery] = useSearchParams(['search', 'sort']);
  const isMountedRef = useRef(false);

  const filter: CourseEventsFiltersType = useMemo(() => {
    return {
      search: query.search || undefined,
      sort: (query.sort || CourseEventsTimeSort.all) as CourseEventsTimeSort
    };
  }, [query]);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      changeQuery(filter, true);
    }
  }, [changeQuery, filter]);

  const handleFilterChange = useCallback(
    (key: keyof CourseEventsFiltersType, valueConverter?: (value: any) => any) => {
      return (value: any) => {
        const val = valueConverter ? valueConverter(value) : value;
        changeQuery(
          {
            [key]: val ?? undefined
          },
          true
        );
      };
    },
    [changeQuery]
  );

  const filtersComponent = (
    <div className={s.CourseEventsFilters}>
      <Select
        className={s.CourseEventsFilters__sort}
        placeholder={'Показывать события...'}
        value={filter.sort}
        options={sortOptions}
        onChange={handleFilterChange('sort')}
      />
      <SearchInput
        className={s.CourseEventsFilters__search}
        placeholder={'Поиск'}
        value={filter.search || ''}
        onChange={handleFilterChange('search')}
      />
    </div>
  );

  return [filtersComponent, filter];
};
