import React from 'react';
import { Link } from 'react-router-dom';
import { ICourse } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';
import { Heading, HeadingSize } from 'components/Heading';
import { IUser } from 'store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';
import { UserContact } from 'components/UserContact/UserContact';
import s from './CourseAbout.module.scss';

export interface CourseAboutProps extends ICourse {
  teachers?: IUser[];
}

export const CourseAbout = ({ summary, teachers }: CourseAboutProps) => {
  return (
    <div className={s.CourseAbout}>
      <Heading className={s.CourseAbout__title} component={'h4'} size={HeadingSize.H4}>
        Краткая информация о курсе
      </Heading>

      <div className={s.CourseAbout__content} dangerouslySetInnerHTML={{ __html: summary }} />

      {!!teachers?.length && (
        <div className={s.Teachers}>
          <Heading className={s.Teachers__title} component={'h5'} size={HeadingSize.H5}>
            Преподаватели
          </Heading>

          <div className={s.Teachers__list}>
            {teachers.map((item) => (
              <UserContact key={item.id} user={item} component={Link} to={`/teachers/${item.id}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
