import { useEffect, useRef, useState } from 'react';
import { MoodleContent } from '../../../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionReviewTypeProps } from '../types';
import { QuizQuestionReviewLayout } from '../../QuizQuestionReviewLayout';
import s from '../QuizQuestionReview.module.scss';
import { QuizQuestionReviewComment, QuizQuestionReviewCommentVariant } from '../../QuizQuestionReviewComment';
import { Input, InputSizeVariant } from '../../../../../../../components/Input';
import { useIsMobile } from '../../../../../../../hooks/useIsMobile';

export function ShortAnswerReview({
  content,
  description,
  rightAnswer,
  comment,
  ...layout
}: QuizQuestionReviewTypeProps) {
  const [value, setValue] = useState<string>('');

  const contentRef = useRef(content);

  useEffect(() => {
    const $answer = contentRef.current?.querySelector('.answer input[name$="answer"]') as HTMLInputElement | null;

    setValue($answer?.value ?? '');
  }, []);

  const isMobile = useIsMobile();

  return (
    <QuizQuestionReviewLayout classes={{ content: s.QuizQuestionReview }} {...layout}>
      <MoodleContent className={s.QuizQuestionReview__content} wide>
        {description}
      </MoodleContent>

      <Input
        className={s.QuizQuestionReview__input}
        sizeVariant={isMobile ? InputSizeVariant.medium : InputSizeVariant.large}
        value={value}
        disabled
      />

      {rightAnswer && (
        <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.answer} text={rightAnswer} />
      )}

      {comment && <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.comment} html={comment} />}
    </QuizQuestionReviewLayout>
  );
}
