import React from 'react';
import clsx from 'clsx';
import s from './Divider.module.scss';

export enum DividerVariant {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum DividerMargin {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export interface DividerProps {
  className?: string;
  variant?: DividerVariant;
  margin?: DividerMargin;
}

export const Divider = ({ className, variant = DividerVariant.Horizontal, margin }: DividerProps) => {
  return (
    <div className={clsx(s.Divider, s[`Divider_${variant}`], margin && s[`Divider_margin_${margin}`], className)} />
  );
};
