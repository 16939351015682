import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { CourseEvent } from '../CourseEvent';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { useCourseEvents } from '../../../../store/moodleAPI/hooks/useCourseEvents';
import { Loader, LoaderSize } from '../../../../components/Loader';
import { DataPlaceholder } from '../../../../components/DataPlaceholder/DataPlaceholder';
import { Icons } from '../../../../components/Icons';
import { useCourseEventsFilters } from './useCourseEventsFilters';
import s from './CourseEvents.module.scss';

export interface CourseTimelineProps {
  className?: string;
  courseId?: string;
}

export const CourseEvents: ReactFCC<CourseTimelineProps> = (props) => {
  const { className, courseId } = props;

  const [filtersComponent, filter] = useCourseEventsFilters();

  const { events, isLoading } = useCourseEvents({
    courseId,
    ...filter
  });

  const noData = !events.length;
  const noSearchData = filter.search && noData;

  return (
    <div className={clsx(s.CourseEvents, className)}>
      {filtersComponent}

      {isLoading ? (
        <Loader className={s.CourseEvents__loader} size={LoaderSize.large} />
      ) : noData ? (
        <DataPlaceholder
          className={s.CourseEvents__dataPlaceholder}
          icon={Icons.PLACEHOLDER_SEARCH}
          description={
            noSearchData ? (
              <>
                По вашему запросу события не найдены. <br />
                Попробуйте изменить ваш запрос
              </>
            ) : (
              'Нет событий на выбранные даты'
            )
          }
        />
      ) : (
        <div className={s.CourseEvents__content}>
          <div className={clsx(s.CourseEvents__list)}>
            {events.map(({ date, events }, index) => (
              <div className={s.CourseEvents__section} key={index}>
                <Heading size={HeadingSize.H5} className={s.CourseEvents__date}>
                  {date}
                </Heading>

                {events.map((event, index) => (
                  <CourseEvent className={s.CourseEvents__card} {...event} key={index} />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
