import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizGetAttemptReviewData,
  ModQuizGetAttemptReviewHookArgs,
  ModQuizGetAttemptReviewQuery
} from '../moodleTypes/_functions/mod_quiz_get_attempt_review';

export function useQuizAttemptReview({
  moodleConfig,
  attemptid,
  page,
  ...props
}: Partial<ModQuizGetAttemptReviewHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetAttemptReviewQuery, ModQuizGetAttemptReviewData>(
    {
      wsfunction: 'mod_quiz_get_attempt_review',
      attemptid: attemptid ?? 0,
      page: page ?? -1,
      ...props
    },
    {
      ...moodleConfig,
      skip: isNil(attemptid) || moodleConfig?.skip
    }
  );
}
