import { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import s from './TabItem.module.scss';

export interface TabItemProps<T extends string> {
  /**
   * Дополнительный css-класс
   */
  className?: ClassValue;
  /**
   * Идентификатор вкладки
   */
  id: T;
  /**
   * Состояние активность
   */
  active?: boolean;
  /**
   * Содержимое вкладки
   */
  label?: ReactNode;
  /**
   * Обработчик нажатия на вкладку
   */
  onClick?: (id: TabItemProps<T>['id'], e: MouseEvent<HTMLButtonElement>) => void;
}

export const TabItem = <T extends string>(props: PropsWithChildren<TabItemProps<T>>) => {
  const { children, className, active, id, label, onClick } = props;

  return (
    <button
      className={clsx(s.TabItem, className, {
        [s.TabItem_active]: active
      })}
      onClick={(e) => onClick?.(id, e)}>
      {label}
      {children}
    </button>
  );
};
