import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { FloatingArrow, FloatingArrowProps } from '@floating-ui/react';
import s from './Tooltip.module.scss';

export interface TooltipClasses {
  root?: string;
  content?: string;
  title?: string;
  description?: string;
  arrow?: string;
}

export interface TooltipProps {
  className?: string;
  classes?: TooltipClasses;
  title?: React.ReactNode;
  description?: React.ReactNode;
  style?: CSSProperties;
  arrowProps?: Pick<FloatingArrowProps, 'context'> & {
    ref?: React.Ref<SVGSVGElement>;
  };
  children?: React.ReactNode;
}

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(props, ref) {
  const { className, classes, title, description, style, arrowProps, children } = props;

  return (
    <div ref={ref} className={clsx(s.Tooltip, className, classes?.root)} style={style}>
      <div className={clsx(s.Tooltip__content, classes?.content)}>
        {title && <div className={clsx(s.Tooltip__title, classes?.title)}>{title}</div>}
        {description && <div className={clsx(s.Tooltip__description, classes?.description)}>{description}</div>}
        {children}
      </div>

      {arrowProps && <FloatingArrow className={clsx(s.Tooltip__arrow, classes?.arrow)} {...arrowProps} />}
    </div>
  );
});
