import { useCallback } from 'react';
import { useMoodleMutation } from '../useMoodleMutation';
import {
  CoreUserUpdateUserPreferencesData,
  CoreUserUpdateUserPreferencesQuery
} from '../moodleTypes/_functions/core_user_update_user_preferences';

export const useCourseSetHidden = () => {
  const { mutate, ...props } = useMoodleMutation();

  const run = useCallback(
    (courseId: number, hidden: boolean) => {
      return mutate<CoreUserUpdateUserPreferencesQuery, CoreUserUpdateUserPreferencesData>({
        wsfunction: 'core_user_update_user_preferences',
        preferences: [
          {
            type: `block_myoverview_hidden_course_${courseId}`,
            value: +hidden || undefined
            //   value: 1 - скрывает
            //   value: undefined - показывает
          }
        ]
      });
    },
    [mutate]
  );

  return {
    mutate: run,
    ...props
  };
};
