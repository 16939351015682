import React, { useCallback } from 'react';
import { ICourse } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';
import { useImageSrc } from 'hooks/useImageSrc';
import { CourseActionBox } from 'components/ActionBox/CourseActionBox/CourseActionBox';
import { Label, LabelItemType } from 'components/Label';
import { useIsMobile } from 'hooks/useIsMobile';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { Icons } from 'components/Icons';
import { CourseDataCustomFields } from 'hooks/course/useCourseData';
import cornerSrc from '../../_assets/course-head-corner.svg';
import s from './CourseHead.module.scss';

export type CourseHeadProps = {
  course: ICourse;
  stats: LabelItemType[];
  customFields?: CourseDataCustomFields;
  onGoBack?: () => void;
};

export const CourseHead = ({ course, stats, customFields, onGoBack }: CourseHeadProps) => {
  const isMobile = useIsMobile();
  const { id, categoryname, courseimage } = course;

  const handleGoBack = useCallback(() => {
    onGoBack?.();
  }, [onGoBack]);

  const imgSrc = useImageSrc(courseimage);

  return (
    <div className={s.CourseHead} style={{ backgroundImage: `url(${imgSrc})` }}>
      {!isMobile && (
        <div className={s.CourseHead__labels}>
          <Label label={categoryname} />
          {!!stats.length && <Label labels={stats} />}
        </div>
      )}
      {isMobile && (
        <IconButton
          className={s.CourseHead__backButton}
          icon={Icons.CHEVRON_CURVED_LEFT}
          variant={IconButtonVariant.tertiary}
          rounded
          onClick={handleGoBack}
        />
      )}
      {customFields && (
        <>
          <img className={s.CourseHead__corner} src={cornerSrc} alt="" />
          <CourseActionBox
            className={s.CourseHead__actionBox}
            courseId={id}
            favourite={customFields.favourite}
            hidden={customFields.hidden}
          />
        </>
      )}
    </div>
  );
};
