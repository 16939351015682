import { Link, useLocation } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { format } from 'date-fns'; // eslint-disable-line import/no-duplicates
import ru from 'date-fns/locale/ru'; // eslint-disable-line import/no-duplicates
import clsx from 'clsx';
import { formatGrade } from '../../../../../../utils/quiz/formatGrade';
import { Icons } from '../../../../../../components/Icons';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../../../../../components/IconButton';
import { IAttempt } from '../../../../../../store/moodleAPI/moodleTypes/Attempt';
import { useIsMobile } from '../../../../../../hooks/useIsMobile';
import s from './QuizAttempt.module.scss';

export type QuizAttemptProps = IAttempt & {
  sumgradesMax?: number;
  decimalpoints?: number;
  onClick?: () => void;
};

export function QuizAttempt({
  id,
  attempt: attemptNumber,
  state,
  sumgrades,
  sumgradesMax,
  decimalpoints,
  timefinish,
  timemodified
}: QuizAttemptProps) {
  const disabled = state === 'inprogress';

  const { pathname } = useLocation();

  const isMobile = useIsMobile();

  return (
    <Link
      className={clsx(s.QuizAttempt, {
        [s.QuizAttempt_disabled]: disabled
      })}
      to={id ? `${pathname}?tab=review&attempt=${id}` : '#'}
      tabIndex={disabled ? -1 : 0}>
      <div className={s.QuizAttempt__info}>
        <p className={s.QuizAttempt__title}>
          <span className={s.QuizAttempt__index}>{attemptNumber ?? '0'}.</span>
          {getAttemptTitle(state)}
        </p>

        <p className={s.QuizAttempt__time}>
          {timefinish
            ? `Отправлено ${formatAttemptTime(timefinish)}`
            : timemodified
            ? `Обновлено ${formatAttemptTime(timemodified)}`
            : null}
        </p>
      </div>

      <div className={s.QuizAttempt__actions}>
        {state !== 'inprogress' && (
          <div className={s.QuizAttempt__status}>
            {!isNil(sumgrades) && !isNil(sumgradesMax) ? (
              <>{formatGrade(sumgrades, sumgradesMax, decimalpoints, ' / ')} баллов</>
            ) : (
              'Нет оценки'
            )}
          </div>
        )}

        {state === 'finished' && !isMobile && (
          <IconButton variant={IconButtonVariant.ghost} size={IconButtonSize.medium} icon={Icons.ARROW_RIGHT} />
        )}
      </div>
    </Link>
  );
}

function getAttemptTitle(state: string | undefined) {
  switch (state) {
    case 'inprogress':
      return 'В процессе';
    case 'overdue':
      return 'Просрочено';
    case 'finished':
      return 'Завершено';
    case 'abandoned':
      return 'Не закончено';
    default:
      return 'Без статуса';
  }
}

function formatAttemptTime(time: number) {
  return format(new Date(time * 1000), 'dd MMMM, HH:mm', { locale: ru });
}
