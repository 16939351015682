import { QuizQuestionReviewLayoutProps } from '../QuizQuestionReviewLayout';

export type QuizQuestionReviewTypeProps = Pick<QuizQuestionReviewLayoutProps, 'title' | 'state' | 'grade'> & {
  description: string;
  content: Element | null;
  radio?: boolean;
  rightAnswer: string;
  comment: string;
};

export enum AnswerState {
  correct = 'correct',
  incorrect = 'incorrect'
}
