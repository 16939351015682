import { isNil } from 'lodash-es';
import {
  ModQuizGetQuizRequiredQtypesData,
  ModQuizGetQuizRequiredQtypesHookArgs,
  ModQuizGetQuizRequiredQtypesQuery
} from '../moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { useMoodleQuery } from '../useMoodleQuery';

export function useQuizQuestionTypes({ moodleConfig, quizid }: Partial<ModQuizGetQuizRequiredQtypesHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetQuizRequiredQtypesQuery, ModQuizGetQuizRequiredQtypesData>(
    {
      wsfunction: 'mod_quiz_get_quiz_required_qtypes',
      quizid: quizid ?? 0
    },
    {
      ...moodleConfig,
      skip: isNil(quizid) || moodleConfig?.skip
    }
  );
}
