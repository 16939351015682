import { useEffect, useState } from 'react';
import { useUser } from '../../../app/providers/user';
import { useAuth } from '../../../app/providers/auth';

export enum MoodleAuthError {
  AUTH_ERROR = 'AUTH_ERROR',
  UNKNOWN = 'UNKNOWN'
}

export type UseMoodleAuthProps = {
  onSuccess?: () => void | Promise<void>;
};

export function useMoodleAuth({ onSuccess }: UseMoodleAuthProps = {}) {
  const { data: userData } = useUser();
  const { checkAuth } = useAuth();

  const [isLoggedInMoodle, setIsLoggedInMoodle] = useState(false);
  const [isMoodleAuthLoading, setIsMoodleAuthLoading] = useState(true);
  const [moodleAuthError, setMoodleAuthError] = useState<MoodleAuthError | null>(null);

  useEffect(() => {
    if (!userData) {
      return;
    }

    (async () => {
      try {
        const isLoggedIn = await checkAuth(userData.fullname, userData.email);

        if (!isLoggedIn) {
          setMoodleAuthError(MoodleAuthError.AUTH_ERROR);
          return;
        }

        setIsLoggedInMoodle(true);
        onSuccess?.();
      } catch (e) {
        setMoodleAuthError(MoodleAuthError.UNKNOWN);
        console.error(e);
      } finally {
        setIsMoodleAuthLoading(false);
      }
    })();
  }, [checkAuth, onSuccess, userData]);

  return {
    isLoggedInMoodle,
    isMoodleAuthLoading,
    moodleAuthError
  };
}
