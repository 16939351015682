import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../../../../components/Container';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { Tabs, TabsProps } from '../../../../components/Tabs';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { ButtonBackBox } from '../../../../components/Button/ButtonBack';
import { useQuizData } from '../../../../hooks/quiz';
import { QuizProps } from './types';
import { QuizAbout } from './_components/QuizAbout';
import { QuizAttempts } from './_components/QuizAttempts';
import { QuizReview } from './_components/QuizReview';
import s from './Quiz.module.scss';

enum QuizTabs {
  About = 'about',
  Attempts = 'attempts',
  Review = 'review'
}

const tabs: TabsProps<QuizTabs>['tabs'] = [
  {
    id: QuizTabs.About,
    label: 'Описание'
  },
  {
    id: QuizTabs.Attempts,
    label: 'Результаты'
  }
];

export const Quiz = ({ data, onGoBack, ...props }: QuizProps) => {
  const [query, changeQuery] = useSearchParams(['tab', 'attempt']);

  const tab = (query.tab || QuizTabs.About) as QuizTabs;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setTab = (tab: QuizTabs) => {
    changeQuery(
      {
        tab
      },
      true
    );
  };

  const { module, course, currentModuleContents } = data;

  const quizData = useQuizData({
    course,
    module: currentModuleContents
  });

  // После перехода обратно сюда со страницы прохождения теста, необходимо сбросить кэш, чтобы получить актуальную информацию
  // TODO возможно стоит обработать, чтобы эта логика срабатывала только после редиректа
  useEffect(() => {
    quizData.clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={s.Quiz}>
      {onGoBack && (
        <ButtonBackBox
          onGoBack={() => {
            if (tab === QuizTabs.Review) {
              navigate(`${pathname}?tab=attempts`);
            } else {
              onGoBack();
            }
          }}
        />
      )}

      <Heading
        className={s.Quiz__heading}
        component={'h2'}
        size={HeadingSize.H2}
        pageTitle={[module?.name, course?.fullname]}>
        {module?.name}
      </Heading>

      {tab !== QuizTabs.Review && <Tabs className={s.Quiz__tabs} tabs={tabs} activeId={tab} setActive={setTab} />}

      <div className={s.Quiz__content}>
        {tab === QuizTabs.About && <QuizAbout data={data} quizData={quizData} {...props} />}
        {tab === QuizTabs.Attempts && <QuizAttempts data={data} quizData={quizData} {...props} />}
        {tab === QuizTabs.Review && <QuizReview />}
      </div>
    </Container>
  );
};
