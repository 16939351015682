import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as PDFJS from 'pdfjs-dist';
import * as PDFJSWorker from 'pdfjs-dist/build/pdf.worker.min.mjs';
import { useMoodleAuth } from '../../../store/moodleAPI/hooks';
import { requestCerts } from '../../../routes/_moodle/request-certs';
import { getMoodleRoute } from '../../../routes/_moodle/to-moodle-route';
import { CustomCertContext, CustomCertificate } from './CustomCertContext';

PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

const MOODLE_CERT_ROUTE = '/mod/customcert/my_certificates.php';

/*
  TODO По-хорошему надо попробовать сделать кэширование полученных изображений в браузере,
    потому что процесс их получения очень долгий и трудозатратный.
    Вроде как можно что-то подобное сделать через IndexedDB
 */

export function CustomCertProvider({ children }: PropsWithChildren) {
  const [certs, setCerts] = useState<CustomCertificate[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { moodleAuthError, isMoodleAuthLoading } = useMoodleAuth();

  useEffect(() => {
    if (isMoodleAuthLoading) {
      return;
    }

    if (moodleAuthError) {
      setError(true);
      setIsLoading(false);
      return;
    }

    (async () => {
      try {
        const { certs } = await requestCerts(iframeRef.current?.contentWindow);
        const certificateItems = await Promise.all(
          certs.map(async (cert) => ({
            url: cert.url,
            image: await fileToImage(cert.file)
          }))
        );

        setCerts(certificateItems);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isMoodleAuthLoading, moodleAuthError]);

  return (
    <CustomCertContext.Provider
      value={{
        certs,
        error,
        isLoading
      }}>
      {children}

      {isLoading && (
        <div style={{ visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' }}>
          <iframe
            src={getMoodleRoute(MOODLE_CERT_ROUTE)}
            ref={iframeRef}
            id="moodle-cert"
            title="Moodle certificates page"
            // sandbox="allow-same-origin allow-scripts allow-forms"
            // allow="*"
            width={1000}
            height={800}
          />
        </div>
      )}
    </CustomCertContext.Provider>
  );
}

async function fileToImage(file: File) {
  const url = URL.createObjectURL(file as File);
  const pdf = await PDFJS.getDocument(url).promise;

  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 2 });
  const canvas = document.createElement('canvas');

  const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const renderContext = { canvasContext, viewport };
  await page.render(renderContext).promise;

  return canvas.toDataURL();
}
