import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import type { Editor as EditorType } from 'tinymce';
import clsx from 'clsx';
import { QuizQuestionLayout } from '../../QuizQuestionLayout';
import { MoodleContent } from '../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionTypeProps } from '../types';
import { Divider } from '../../../../../components/Divider';
import s from '../QuizQuestion.module.scss';
import { useProcessQuestion } from '../useProcessQuestion';
import { Input, InputSizeVariant } from '../../../../../components/Input';
import { DropZone, useUpload } from '../../../../../components/DropZone';
import { AttachmentUpload } from '../../../../../components/Attachment';
import { sizeFormat } from '../../../../../utils/sizeFormat';

const defaultTinyValue = '<p></p>';

type ResponseFormat = 'editor' | 'editorfilepicker' | 'plain' | 'monospaced' | 'noinline';

type EssayQuestionSettings = {
  attachments: string; // number
  attachmentsrequired: string; // number
  filetypeslist: ['image'];
  maxbytes: string; // number
  maxwordlimit: null;
  minwordlimit: null;
  responsefieldlines: string; // number
  responseformat: ResponseFormat;
  responserequired: string; // number
  responsetemplate: '';
  responsetemplateformat: '1';
};

export function EssayQuestion({
  id,
  content,
  sequenceCheck,
  description,
  title,
  prompt,
  setPageProcessData,
  settings: settingsProp
}: QuizQuestionTypeProps) {
  const [answers, setAnswers] = useState<{ name: string; value: string }[]>([]);
  const [initialValue, setInitialValue] = useState<string | null>(null);

  const contentRef = useRef(content);

  const { attachments, responseformat, responsefieldlines, maxbytes, attachmentsrequired } =
    (settingsProp as EssayQuestionSettings | null) || {};

  const attachmentsCount = Number(attachments);
  const attachmentsRequiredCount = Number(attachmentsrequired);
  const hasAttachments = attachmentsCount > 0;

  useEffect(() => {
    const $answer = contentRef.current?.querySelector('.answer textarea[name$="answer"]') as HTMLTextAreaElement | null;
    const $attachments = contentRef.current?.querySelector(
      '.attachments input[name$="attachments"]'
    ) as HTMLInputElement | null;

    const value = $answer?.value ?? defaultTinyValue;

    setInitialValue(value);

    setAnswers([
      {
        name: $answer?.getAttribute('name') ?? '',
        value: value
      },
      {
        name: $attachments?.getAttribute('name') ?? '',
        value: $attachments?.value ?? ''
      }
    ]);
  }, []);

  useProcessQuestion({
    answers,
    setPageProcessData,
    sequenceCheck
  });

  // [1] Обработка текстового ответа
  const answer = answers.find((i) => i.name.includes('answer'));
  const answerName = answer?.name;
  const value = answer?.value ?? '';

  const editorRef = useRef<EditorType | null>(null);

  const setEditorValue = useCallback(
    (value: string) => {
      setAnswers((answers) => [
        ...answers.filter((i) => i.name !== answerName),
        {
          name: answerName ?? '',
          value
        }
      ]);
    },
    [answerName]
  );

  const editorElement = useMemo(() => {
    switch (responseformat) {
      case 'editor':
      case 'editorfilepicker':
        return (
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={initialValue ?? ''}
            onChange={(e, editor) => {
              setEditorValue(editor.getContent());
            }}
            init={{
              height: 400,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'preview',
                'help',
                'wordcount'
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              language_url: '/tinymcelang/ru.js',
              language: 'ru'
            }}
          />
        );
      case 'noinline':
        return null;
      default:
        return (
          <Input
            className={clsx(
              s.QuizQuestion__textarea,
              responseformat === 'monospaced' && s.QuizQuestion__textarea_monospace
            )}
            sizeVariant={InputSizeVariant.large}
            component={'textarea'}
            rows={Number(responsefieldlines) ?? 10}
            value={value}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setEditorValue(e.target.value)}
            placeholder={'Ответ...'}
          />
        );
    }
  }, [initialValue, responsefieldlines, responseformat, setEditorValue, value]);

  // [2] Обработка файлов
  const attachmentsName = answers.find((i) => i.name.includes('attachments'))?.name;

  const { id: attachmentsValue, files, onDrop, remove } = useUpload();

  useEffect(() => {
    if (!attachmentsName) {
      return;
    }

    setAnswers((answers) => [
      ...answers.filter((i) => i.name !== attachmentsName),
      {
        name: attachmentsName ?? '',
        value: attachmentsValue ? String(attachmentsValue) : ''
      }
    ]);
  }, [attachmentsName, attachmentsValue]);

  return (
    <QuizQuestionLayout id={id} title={title} prompt={prompt}>
      <MoodleContent wide>{description}</MoodleContent>

      <Divider className={s.QuizQuestion__divider} />

      <div className={s.QuizQuestion__editor}>{editorElement}</div>

      {hasAttachments && (
        <div className={s.QuizQuestion__attachments}>
          <DropZone
            className={s.QuizQuestion__dropzone}
            label={'Загрузить файлы'}
            description={
              <>
                {!!Number(maxbytes) && `Максимальный размер файлов: ${sizeFormat(Number(maxbytes))}`} <br />
                {attachmentsRequiredCount && `Требуемое количество файлов: ${attachmentsRequiredCount}`} <br />
                {attachmentsCount && `Максимальное количество файлов: ${attachmentsCount}`}
              </>
            }
            onDrop={onDrop}
            maxFiles={attachmentsCount}
            maxSize={Number(maxbytes) ?? undefined}
          />

          <div className={s.QuizQuestion__attachmentsList}>
            {files.map((file, index) => (
              <AttachmentUpload
                filename={file.filename}
                size={file.filesize}
                onClickRemove={() => remove(file.uniqueId)}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </QuizQuestionLayout>
  );
}
