import { isNil } from 'lodash-es';
import { usePagesByCourses } from 'store/moodleAPI/hooks/usePagesByCourses';

export const usePageData = (courseId?: string | number, moduleId?: string | number) => {
  const {
    data: pagesData,
    isLoading: pagesLoading,
    error: pagesError
  } = usePagesByCourses({
    courseids: isNil(courseId) || isNil(moduleId) ? [] : [+courseId]
  });

  const page = pagesData?.pages.find((p) => p.coursemodule === moduleId);

  return {
    content: page?.content,
    isLoading: pagesLoading,
    error: pagesError
  };
};
