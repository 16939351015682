import { useEffect, useRef, useState } from 'react';
import { QuizQuestionLayout } from '../../QuizQuestionLayout';
import { MoodleContent } from '../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionTypeProps } from '../types';
import { Divider } from '../../../../../components/Divider';
import s from '../QuizQuestion.module.scss';
import { Input, InputSizeVariant } from '../../../../../components/Input';
import { useProcessQuestion } from '../useProcessQuestion';
import { InputPropsWithoutRef } from '../../../../../utils/types';
import { useIsMobile } from '../../../../../hooks/useIsMobile';

export interface ShortAnswerQuestionProps extends QuizQuestionTypeProps {
  type?: InputPropsWithoutRef['type'];
}

export function ShortAnswerQuestion({
  type = 'text',
  id,
  content,
  sequenceCheck,
  description,
  title,
  prompt,
  setPageProcessData
}: ShortAnswerQuestionProps) {
  const [answers, setAnswers] = useState<{ name: string; value: string }[]>([]);

  const contentRef = useRef(content);

  const isMobile = useIsMobile();

  useEffect(() => {
    const $answer = contentRef.current?.querySelector('.answer input[name$="answer"]') as HTMLInputElement | null;

    setAnswers([
      {
        name: $answer?.getAttribute('name') ?? '',
        value: $answer?.value ?? ''
      }
    ]);
  }, []);

  useProcessQuestion({
    answers,
    setPageProcessData,
    sequenceCheck
  });

  const answerName = answers[0]?.name;
  const value = answers[0]?.value ?? '';

  return (
    <QuizQuestionLayout id={id} title={title} prompt={prompt}>
      <MoodleContent wide>{description}</MoodleContent>

      <Divider className={s.QuizQuestion__divider} />

      <Input
        className={s.QuizQuestion__input}
        sizeVariant={isMobile ? InputSizeVariant.medium : InputSizeVariant.large}
        type={type}
        value={value}
        onChange={(e) =>
          setAnswers([
            {
              name: answerName ?? '',
              value: e.target.value
            }
          ])
        }
        placeholder={'Ответ...'}
      />
    </QuizQuestionLayout>
  );
}
