export function insertAccessKeyIntoUrl(stringWithProtectedUrl: string, userPrivateAccessKey?: string) {
  if (!stringWithProtectedUrl) {
    return stringWithProtectedUrl;
  }

  // userprivateaccesskey is marked as optional in Moodle API documentation
  if (!userPrivateAccessKey) {
    console.error('No userprivateaccesskey available.');
    return stringWithProtectedUrl;
  }

  return stringWithProtectedUrl.replace(
    /\/webservice\/pluginfile.php|\/pluginfile.php/g,
    `/tokenpluginfile.php/${userPrivateAccessKey}`
  );
}
