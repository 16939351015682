import { isNil } from 'lodash-es';

export const timestampDefaultLength = 13;

export function getDateByTimestamp(timestamp: number): Date;
export function getDateByTimestamp(timestamp?: number): Date | undefined {
  if (isNil(timestamp)) return undefined;
  const signsToAdd = timestampDefaultLength - timestamp.toString().length;
  return new Date(+(timestamp + '0'.repeat(signsToAdd)));
}
