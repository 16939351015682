import { createContext } from 'react';

export type CustomCertificate = {
  url: string;
  /**
   * Изображение сертификатов в base64
   */
  image: string;
};

export interface ICertContent {
  certs: CustomCertificate[];
  error: boolean;
  isLoading: boolean;
}

export const CustomCertContext = createContext<ICertContent | null>(null);
