import { useCallback } from 'react';
import {
  ModQuizStartAttemptData,
  ModQuizStartAttemptQuery,
  ModQuizStartAttemptQueryArgs
} from '../moodleTypes/_functions/mod_quiz_start_attempt';
import { useMoodleMutation } from '../useMoodleMutation';

export function useQuizStartAttempt() {
  const { mutate, ...props } = useMoodleMutation();

  const run = useCallback(
    (data: ModQuizStartAttemptQueryArgs) => {
      return mutate<ModQuizStartAttemptQuery, ModQuizStartAttemptData>({
        wsfunction: 'mod_quiz_start_attempt',
        ...data
      });
    },
    [mutate]
  );

  return {
    mutate: run,
    ...props
  };
}
