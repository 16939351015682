import React from 'react';
import clsx from 'clsx';
import { ICONS_MAP, IconTypes } from '../Icons';
import s from './DataPlaceholder.module.scss';

export interface DataPlaceholderProps {
  className?: string;
  icon?: IconTypes;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export const DataPlaceholder = ({ className, icon, description, children }: DataPlaceholderProps) => {
  return (
    <div className={clsx(s.DataPlaceholder, className)}>
      {icon && <div className={s.DataPlaceholder__icon}>{ICONS_MAP[icon]}</div>}
      {description && <div className={s.DataPlaceholder__description}>{description}</div>}
      {children}
    </div>
  );
};
