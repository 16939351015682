import clsx from 'clsx';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { Icons } from 'components/Icons';
import cardPlug from '../../_assets/card-plug.svg';
import s from './CardCertificate.module.scss';

export enum CardCertificateVariant {
  default = 'default',
  plug = 'plug'
}

export interface CardCertificateProps {
  variant?: CardCertificateVariant;
  image?: string;
  url?: string;
  className?: string;
}

export const CardCertificate: React.FC<CardCertificateProps> = ({
  variant = CardCertificateVariant.default,
  image,
  url,
  className
}) => {
  return (
    <div className={clsx(s.CardCertificate, className, s[`CardCertificate_${variant}`])}>
      {image && <img src={image} alt="" className={s.CardCertificate__image} />}
      {url && (
        <IconButton
          component="a"
          href={url}
          variant={IconButtonVariant.tertiary}
          rounded
          className={s.CardCertificate__download}
          icon={Icons.DOWNLOAD}
          target={'_blank'}
          rel={'noopener noreferrer'}
        />
      )}
      {variant === 'plug' && <img src={cardPlug} alt="" />}
    </div>
  );
};
