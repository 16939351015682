import { Link as RouterLink } from 'react-router-dom';
import { Text, TextVariant } from '../../../../components/Text';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { Button, ButtonSize } from '../../../../components/Button';
import { Link } from '../../../../components/Link';
import { LOGIN_PAGE_ROUTE } from '../../../../app/routes/routes';
import { useIsMobile } from '../../../../hooks/useIsMobile';

import s from './RecoveryPageContentConfirm.module.scss';

export function RecoveryPageContentConfirm() {
  const isMobile = useIsMobile();

  return (
    <>
      <Heading className={s.RecoveryPageContentConfirm__title} component={'h1'} size={HeadingSize.H5}>
        Восстановление пароля
      </Heading>

      <Text className={s.RecoveryPageContentConfirm__text} variant={TextVariant.BODY_S}>
        Если вы указали верный логин или адрес электронной почты, то вам будет отправлено электронное письмо.
        <br />
        <br />
        Оно содержит простые инструкции для завершения процедуры изменения пароля. Если после этого возникнут какие-то
        сложности, свяжитесь с <Link href={'mailto:skolca.cloud@yandex.ru'}>администратором сайта</Link>
      </Text>

      <Button component={RouterLink} to={LOGIN_PAGE_ROUTE} size={isMobile ? ButtonSize.medium : ButtonSize.large}>
        Продолжить
      </Button>
    </>
  );
}
