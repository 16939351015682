import { useMemo } from 'react';
import { QuizQuestionType } from '../../routes/module/_pages/Quiz/types';

export type UseParseQuizHtmlReturn = ReturnType<typeof useParseQuizHtml>;

export function useParseQuizHtml(html: string, type: QuizQuestionType | undefined, isReview = false) {
  return useMemo(() => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(html, 'text/html');

    const id = getQuestionId(htmlDocument);
    const content = htmlDocument.querySelector('.content');

    const { title, prompt } = getQuestionInfo(content, type, isReview);
    const description = getDescription(content);
    const sequenceCheck = getSequenceCheck(content);
    const isMultiChoiceSet = getIsMultiChoiceSet(content);

    const rightAnswer = getRightAnswer(content);
    const comment = getComment(content);

    return {
      content,
      id,
      description,
      title,
      prompt,
      sequenceCheck,
      isMultiChoiceSet,
      rightAnswer,
      comment
    };
  }, [html, isReview, type]);
}

export function getQuestionId(htmlDocument: Document) {
  return htmlDocument.querySelector('div[id^=question]')?.getAttribute('id') ?? '';
}

function getQuestionInfo(content: Element | null, type: QuizQuestionType | undefined, isReview?: boolean) {
  let $prompt: Element | null = null;

  switch (type) {
    case QuizQuestionType.SHORT_ANSWER:
    case QuizQuestionType.NUMERICAL:
      $prompt = content?.querySelector('label[for$="answer"]') as Element | null;
      break;
    case QuizQuestionType.ESSAY:
      $prompt = (
        isReview
          ? content?.querySelector('[id$="answer_label"], .qtext')
          : content?.querySelector('legend.sr-only, label.sr-only')
      ) as Element | null;
      break;
    default:
      $prompt = content?.querySelector('.prompt') as Element | null;
      break;
  }

  let title = '';
  let prompt = '';

  if ($prompt) {
    const promptData = Array.from($prompt.childNodes.values())
      .filter((i) => (i.nodeType === 1 ? (i as Element).classList.contains('sr-only') : true))
      .map((i) => i.textContent);

    let titleVal;
    let promptVal;

    switch (type) {
      case QuizQuestionType.SHORT_ANSWER:
      case QuizQuestionType.NUMERICAL:
        titleVal = promptData[1];
        promptVal = promptData[0];
        break;
      case QuizQuestionType.ESSAY:
        titleVal = $prompt.textContent;

        if (isReview) {
          titleVal = content?.querySelector('.answer .sr-only')?.textContent ?? '';
        }

        break;
      default:
        titleVal = promptData[0];
        promptVal = promptData[1];
        break;
    }

    if (titleVal) {
      title = titleVal.trim();
    }

    if (promptVal) {
      prompt = promptVal.trim().replace(/:$/, '');
    }
  }

  return {
    title,
    prompt
  };
}

export function getDescription(content: Element | null) {
  const $description = content?.querySelector('.qtext');

  return $description?.innerHTML ?? '';
}

function getSequenceCheck(content: Element | null) {
  const $sequenceCheck = content?.querySelector('.formulation > [type="hidden"][name*="sequencecheck"]');

  return $sequenceCheck
    ? {
        name: $sequenceCheck.getAttribute('name') ?? '',
        value: ($sequenceCheck as HTMLInputElement).value
      }
    : null;
}

function getIsMultiChoiceSet(content: Element | null) {
  return !!content?.querySelector('.answer > div input[type="checkbox"][name*=choice]');
}

function getRightAnswer(content: Element | null) {
  const $rightAnswer = content?.querySelector('.outcome .rightanswer') as HTMLDivElement | null;

  return $rightAnswer?.textContent ?? '';
}

function getComment(content: Element | null) {
  const $comment = content?.querySelector('.comment') as HTMLDivElement | null;
  $comment?.querySelectorAll('.accesshide').forEach((i) => i.remove());

  return $comment?.innerHTML ?? '';
}
