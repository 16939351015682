import { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import s from './Badge.module.scss';

export enum BadgeVariant {
  good = 'good',
  bad = 'bad',
  warning = 'warning',
  info = 'info',
  neutral = 'neutral'
}

export enum BadgeStyle {
  filled = 'filled',
  stroked = 'stroked',
  minified = 'minified'
}

export enum BadgeBorder {
  default = 'default',
  round = 'round'
}

export enum BadgeSize {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's'
}

export interface BadgeProps {
  className?: string;
  variant?: BadgeVariant;
  style?: BadgeStyle;
  border?: BadgeBorder;
  size?: BadgeSize;
  left?: ReactNode;
  right?: ReactNode;
}

export const Badge: ReactFCC<BadgeProps> = (props) => {
  const {
    children,
    className,
    variant = BadgeVariant.good,
    style = BadgeStyle.filled,
    border = BadgeBorder.round,
    size = BadgeSize.M,
    left,
    right
  } = props;

  return (
    <div
      className={clsx(
        s.Badge,
        className,
        s[`Badge_variant_${variant}`],
        s[`Badge_style_${style}`],
        s[`Badge_border_${border}`],
        s[`Badge_size_${size}`]
      )}>
      {left}
      <span className={s.Badge__content}>{children}</span>
      {right}
    </div>
  );
};
