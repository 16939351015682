import { useMemo } from 'react';
import { isNil } from 'lodash-es';
import { IQuestion } from '../../../../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { useParseQuizHtml } from '../../../../../../hooks/quiz';
import { QuizQuestionType } from '../../types';
import { DescriptionReview } from './DescriptionReview';
import { ChoiceReview } from './ChoiceReview';
import { ShortAnswerReview } from './ShortAnswerReview';
import { EssayReview } from './EssayReview';

export interface QuizQuestionReviewProps {
  question?: IQuestion;
}

export function QuizQuestionReview({ question }: QuizQuestionReviewProps) {
  const { content, description, title, rightAnswer, comment, isMultiChoiceSet } = useParseQuizHtml(
    question?.html ?? '',
    question?.type as QuizQuestionType,
    true
  );

  const grade = useMemo(() => {
    if (question && question.mark && !isNil(question.maxmark)) {
      return `${question.mark.replace(',', '.')} из ${question.maxmark.toFixed(2)}`;
    }
  }, [question]);

  const QuestionReviewComponent = useMemo(() => {
    switch (question?.type) {
      case QuizQuestionType.DESCRIPTION:
        return DescriptionReview;
      case QuizQuestionType.MULTI_CHOICE:
      case QuizQuestionType.MULTI_CHOICE_SET:
      case QuizQuestionType.TRUE_FALSE:
        return ChoiceReview;
      case QuizQuestionType.SHORT_ANSWER:
      case QuizQuestionType.NUMERICAL:
        return ShortAnswerReview;
      case QuizQuestionType.ESSAY:
        return EssayReview;
      default:
        return null;
    }
  }, [question]);

  return (
    <>
      {question && QuestionReviewComponent && (
        <QuestionReviewComponent
          title={title}
          content={content}
          description={description}
          radio={!isMultiChoiceSet}
          rightAnswer={rightAnswer}
          comment={comment}
          state={question.state}
          grade={grade}
        />
      )}
    </>
  );
}
