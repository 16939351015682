import React, { useEffect, useRef, useState } from 'react';
import { MoodleContent } from '../../../../../../../components/MoodleContent/MoodleContent';
import { QuizQuestionReviewTypeProps } from '../types';
import { QuizQuestionReviewLayout } from '../../QuizQuestionReviewLayout';
import s from '../QuizQuestionReview.module.scss';
import { QuizQuestionReviewComment, QuizQuestionReviewCommentVariant } from '../../QuizQuestionReviewComment';
import { useSiteInfo } from '../../../../../../../app/providers/siteInfo';
import { useAttachments } from '../../../../../../../hooks/useAttachments';
import { LinkFile } from '../../../../../../../components/Link/LinkFile/LinkFile';

export function EssayReview({ content, description, rightAnswer, comment, ...layout }: QuizQuestionReviewTypeProps) {
  const [value, setValue] = useState<string>('');
  const [attachments, setAttachments] = useState<{ fileurl: string; filename: string }[]>([]);

  const contentRef = useRef(content);
  useSiteInfo();

  useEffect(() => {
    const $answer = contentRef.current?.querySelector(
      '.answer [role="textbox"], .answer textarea[id*="answer"]'
    ) as HTMLTextAreaElement | null;

    const attachments: { fileurl: string; filename: string }[] = [];
    contentRef.current?.querySelectorAll<HTMLAnchorElement>('.attachments li a')?.forEach(($attachment) => {
      const fileurl = $attachment.href;
      const filename = $attachment.innerText;

      if (fileurl && filename) {
        attachments.push({
          fileurl,
          filename
        });
      }
    });

    setValue($answer?.innerHTML ?? $answer?.value ?? '');
    setAttachments(attachments);
  }, []);

  const convertedAttachments = useAttachments(attachments);

  return (
    <QuizQuestionReviewLayout classes={{ content: s.QuizQuestionReview }} {...layout}>
      <MoodleContent className={s.QuizQuestionReview__content} wide>
        {description}
      </MoodleContent>

      {value && (
        <div className={s.QuizQuestionReview__textarea}>
          <MoodleContent>{value}</MoodleContent>
        </div>
      )}

      {!!convertedAttachments?.length && (
        <div className={s.QuizQuestionReview__attachments}>
          {convertedAttachments.map((item, iItem) => (
            <LinkFile className={s.Resource__attachment} key={iItem} {...item} />
          ))}
        </div>
      )}

      {rightAnswer && (
        <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.answer} text={rightAnswer} />
      )}

      {comment && <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.comment} html={comment} />}
    </QuizQuestionReviewLayout>
  );
}
