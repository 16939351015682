import { useMemo } from 'react';
import { useQuizzesByCourses } from '../../store/moodleAPI/hooks';

export type UseQuizProps = {
  courseId?: number;
  quizId?: number;
};

export function useQuiz({ courseId, quizId }: UseQuizProps = {}) {
  const { data, ...query } = useQuizzesByCourses({
    courseids: courseId ? [courseId] : undefined
  });

  const quiz = useMemo(() => data?.quizzes.find((i) => i.id === quizId), [quizId, data]);

  return {
    quiz,
    data,
    ...query
  };
}
