import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './Container.module.scss';

export interface ContainerProps {
  className?: string;
}

export const Container = ({ className, children }: PropsWithChildren<ContainerProps>) => {
  return <div className={clsx(s.Container, className)}>{children}</div>;
};
