import sanitizeHtml from 'sanitize-html';

/**
 * Чтобы не было "слипания слов", сохраняем блочные теги и тег переноса строки, чтобы впоследствии заменить их пробелами
 * Под слипанием слов подразумевается строка вида `Hello<br>World` или `<p>1st line</p><p>2nd line</p>`,
 * если в них просто удалить теги, то они соответственно станут: `HelloWorld` и `1st line2nd line`,
 * что не соответствует визуальному контексту исходного HTML.
 */

// Блочные теги, содержащие простой текст
const textBlockTags = [
  'p',
  'div',
  'address',
  'article',
  'aside',
  'blockquote',
  'dd',
  'dl',
  'dt',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'header',
  'main',
  'nav',
  'pre',
  'section',
  'b',
  'strong'
];

const separateWordsTags = [
  // Заголовки
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // Списки
  'ul',
  'li',
  'ol',
  // Таблицы
  'table',
  // Перенос строки
  'br',
  // Прочие блочные
  ...textBlockTags
];

export const htmlToInlineText = (input?: string) => {
  if (!input) return '';
  /**
   * Удаляем спец. символы из исходного HTML, т.к. sanitizeHtml заменяет `&` на `&amp;`, не хочется, что `&shy;` в
   * исходном превратилось в `&anp;shy;` и выводилось пользователю как `&shy;` - т.к. он этого видеть не должен/
   * input = input
   * // Удаляем все пробельные символы нулевой ширины
   * .replaceAll(new RegExp(nonBoundarySpaceCharsList.join('|'), 'gi'), '')
   * // Все пробелы (не нулевой ширины) заменяем обычным пробелом
   * .replaceAll(new RegExp(spaceSeparatorCharsList.join('|'), 'gi'), ' ');
   */

  return (
    sanitizeHtml(input, {
      allowedTags: separateWordsTags,
      allowedAttributes: {}
    })
      /**
       * 1. sanitizeHtml преобразует строку в безопасных HTML, т.е. такие символы как `<` заменяются кодами - `&lt;`,
       * поэтому гарантируется, что тут конструкции типа `<=>` сохранят свой вид.
       * 2. Неразрывный пробел может создавать проблемы, т.к. если в исходном HTML все пробелы будут неразрывными -
       * вёрстка поломается - поэтому заменяем его обычными
       */
      .replaceAll(/(<[^>]+>)/gi, ' ')
      .replaceAll(/\s+/g, ' ')
      /**
       * sanitizeHtml экранирует `&` в HTML-коды символов, т.е. `&amp;` -> `&amp;amp;`, но т.к. мы используем полученную
       * строку как HTML, то требуется конвертировать `&amp;` обратно, чтобы пользователь видел `&`, а не `&amp;`
       */
      .replaceAll('&amp;', '&')
      .trim()
  );
};

export const htmlToPageContent = (input?: string) => {
  if (!input) return '';

  return (
    sanitizeHtml(input, {
      allowedTags: separateWordsTags,
      allowedAttributes: {},
      // Заменяем все блочные теги с простым текстом (сюда не входят заголовки, списки и т.д.) на <p>
      transformTags: Object.fromEntries(textBlockTags.map((tag) => [tag, 'p']))
    })
      /**
       * Неразрывный пробел может создавать проблемы, т.к. если в исходном HTML все пробелы будут неразрывными -
       * вёрстка поломается - поэтому заменяем его обычными
       */
      .replaceAll(/\s+/g, ' ')
      /**
       * sanitizeHtml экранирует `&` в HTML-коды символов, т.е. `&amp;` -> `&amp;amp;`, но т.к. мы используем полученную
       * строку как HTML, то требуется конвертировать `&amp;` обратно, чтобы пользователь видел `&`, а не `&amp;`
       */
      .replaceAll('&amp;', '&')
      .trim()
  );
};
