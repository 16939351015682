import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { MoodleContent } from '../../../../../../../components/MoodleContent/MoodleContent';
import { AnswerState, QuizQuestionReviewTypeProps } from '../types';
import { QuizQuestionReviewLayout } from '../../QuizQuestionReviewLayout';
import s from '../QuizQuestionReview.module.scss';
import { Checkbox } from '../../../../../../../components/Checkbox';
import { Radio } from '../../../../../../../components/Radio';
import { QuizQuestionReviewComment, QuizQuestionReviewCommentVariant } from '../../QuizQuestionReviewComment';

export function ChoiceReview({
  content,
  description,
  radio,
  rightAnswer,
  comment,
  ...layout
}: QuizQuestionReviewTypeProps) {
  const [answers, setAnswers] = useState<{ name: string; label: string; checked?: boolean; state?: AnswerState }[]>([]);

  const contentRef = useRef(content);

  useEffect(() => {
    const $answers = Array.from(contentRef.current?.querySelectorAll('.answer > div') ?? []);

    const initialAnswers = $answers.map(($answer) => {
      const label = $answer.querySelector('[data-region="answer-label"], [name$="answer"] + label')?.textContent;

      const $input = $answer.querySelector('[name$="answer"], [name*="choice"]');
      const name = $input?.getAttribute('name');
      const checked = $input?.getAttribute('checked') === 'checked';

      const correct = $answer.classList.contains('correct');
      const incorrect = $answer.classList.contains('incorrect');
      const state = correct ? AnswerState.correct : incorrect ? AnswerState.incorrect : undefined;

      if (!label || !name) {
        return {
          name: '',
          label: ''
        };
      }

      return {
        name,
        label,
        checked,
        state
      };
    });

    setAnswers(initialAnswers);
  }, []);

  const Component = radio ? Radio : Checkbox;

  return (
    <QuizQuestionReviewLayout classes={{ content: s.QuizQuestionReview }} {...layout}>
      <MoodleContent className={s.QuizQuestionReview__content} wide>
        {description}
      </MoodleContent>

      <div className={s.QuizQuestionReview__answers}>
        {answers.map(({ name, label, checked, state }, index) => (
          <div
            key={index}
            className={clsx(s.QuizQuestionReview__answer, state && s[`QuizQuestionReview__answer_${state}`])}>
            <Component
              classes={{ label: s.QuizQuestionReview__checkboxLabel }}
              id={name}
              label={label}
              checked={checked}
              disabled
            />
          </div>
        ))}
      </div>

      {rightAnswer && (
        <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.answer} text={rightAnswer} />
      )}

      {comment && <QuizQuestionReviewComment variant={QuizQuestionReviewCommentVariant.comment} html={comment} />}
    </QuizQuestionReviewLayout>
  );
}
