import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  CoreCourseGetContentsData,
  CoreCourseGetContentsHookArgs,
  CoreCourseGetContentsQuery
} from '../moodleTypes/_functions/core_course_get_contents';

export const useCourseContents = ({
  moodleConfig,
  courseid,
  ...props
}: Partial<CoreCourseGetContentsHookArgs> = {}) => {
  return useMoodleQuery<CoreCourseGetContentsQuery, CoreCourseGetContentsData>(
    {
      wsfunction: 'core_course_get_contents',
      courseid: courseid || '',
      ...props
    },
    {
      skip: isNil(courseid),
      ...moodleConfig
    }
  );
};
