import { ReactNode } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Icon, IconSize } from '../Icon';
import { Icons } from '../Icons';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import s from './DropZone.module.scss';

export interface DropZoneProps extends DropzoneOptions {
  className?: string;
  label?: string;
  description?: string | ReactNode;
}

export function DropZone({ className, label, description, ...options }: DropZoneProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  return (
    <div className={clsx(s.DropZone, className)}>
      {label && <p className={s.DropZone__label}>{label}</p>}

      <div
        className={clsx(s.DropZone__zone, {
          [s.DropZone__zone_active]: isDragActive
        })}
        {...getRootProps()}>
        <input {...getInputProps()} />

        <Icon className={s.DropZone__icon} icon={Icons.UPLOAD} size={IconSize.large} />

        <p className={s.DropZone__text}>Нажмите или перетащите файлы</p>

        <Button className={s.DropZone__button} variant={ButtonVariant.tertiary} size={ButtonSize.medium} tabIndex={-1}>
          Загрузить
        </Button>
      </div>

      {description && <p className={s.DropZone__description}>{description}</p>}
    </div>
  );
}
