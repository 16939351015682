import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppRoutes } from 'app/routes';

const appTitle = 'Skolca LMS';
export function App() {
  return (
    <React.Fragment>
      <Helmet defaultTitle={appTitle} titleTemplate={`%s — ${appTitle}`} />
      <AppRoutes />
    </React.Fragment>
  );
}
