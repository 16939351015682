import { useCallback, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { MutatorOptions, useSWRConfig } from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxiosClient } from 'app/providers/axiosClient';

// возможно понадобится для ревалидации запросов при кастомном кеш-провайдере
// export interface UseMoodleMutationProps {
//   revalidateArgs?: Arguments;
// }

export function useMoodleMutation() {
  const { mutate: mutateSWR, ...data } = useSWRConfig();

  const axiosClient = useAxiosClient();
  const { showBoundary } = useErrorBoundary();
  const [isMutating, setIsMutating] = useState(false);

  const mutate = useCallback(
    async <Q, D>(query: Q, opts?: boolean | MutatorOptions): Promise<AxiosResponse<D> | undefined> => {
      try {
        setIsMutating(true);
        const result = await axiosClient.request<D>({ data: query });
        /**
         * TODO: нужен кастомный кеш-провайдер
         * сейчас это временный костыль, обновляющий весь кеш (+ перезапрашивающий все запросы) при любой мутации.
         * нужно передавать в mutateSWR конкретный кеш-ключ запроса (revalidateArgs), который надо обновить
         */
        opts ? await mutateSWR(() => query, undefined, opts) : await mutateSWR(() => query);

        return result;
        // return mutateSWR(revalidateArgs, data, opts);
      } catch (err) {
        const error = err as AxiosError;
        console.error(error);
        if (error?.name === 'NetworkError') {
          showBoundary(error);
        }
      } finally {
        setIsMutating(false);
      }
    },
    [axiosClient, mutateSWR, showBoundary]
  );

  return {
    mutate,
    isMutating,
    ...data
  };
}
