import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import { navLinks } from 'app/routes/routes';
import { AsideContainer } from 'components/AsideContainer/AsideContainer';
import s from './AsideNav.module.scss';

export const AsideNav = () => {
  return (
    <AsideContainer
      classes={{
        root: (extended) => {
          return clsx(s.AsideNav, {
            [s.AsideNav_extended]: extended
          });
        },
        toggle: () => s.AsideNav__toggle
      }}>
      {(extended) => (
        <div className={s.Nav}>
          {navLinks.map((link, iLink) => (
            <NavLink
              key={iLink}
              className={({ isActive }) =>
                clsx(s.NavLink, {
                  [s.NavLink_active]: isActive,
                  [s.NavLink_extended]: extended
                })
              }
              to={link.path}>
              <Icon className={s.NavLink__icon} icon={link.icon} />
              <span className={s.NavLink__label}>{link.label}</span>
            </NavLink>
          ))}
        </div>
      )}
    </AsideContainer>
  );
};
