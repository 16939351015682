import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { navLinks } from 'app/routes/routes';
import { Icon } from 'components/Icon';
import s from './MobileNav.module.scss';

export interface MobileNavProps {
  open: boolean;
  onLinkClick: () => void;
}

export const MobileNav = ({ open, onLinkClick }: MobileNavProps) => {
  return (
    <div
      className={clsx(s.MobileNav, {
        [s.MobileNav_open]: open
      })}>
      {navLinks.map((link, iLink) => (
        <NavLink
          key={iLink}
          className={({ isActive }) =>
            clsx(s.NavLink, {
              [s.NavLink_active]: isActive
            })
          }
          to={link.path}
          onClick={onLinkClick}>
          <Icon className={s.NavLink__icon} icon={link.icon} />
          <span className={s.NavLink__label}>{link.label}</span>
        </NavLink>
      ))}
    </div>
  );
};
