import React, { useRef, useState } from 'react';
import {
  useFloating,
  UseFloatingOptions,
  useHover,
  useInteractions,
  useTransitionStyles,
  FloatingPortal,
  arrow,
  flip
} from '@floating-ui/react';
import { Tooltip, TooltipProps } from './Tooltip';

export interface UseTooltipProps
  extends Pick<UseFloatingOptions, 'placement'>,
    Pick<TooltipProps, 'title' | 'description'> {
  enabled?: boolean;
  children?: TooltipProps['children'];
  tooltipProps?: Pick<TooltipProps, 'className' | 'classes' | 'title' | 'description'>;
}

export type UseTooltipReturnType = ReturnType<typeof useTooltip>;

export function useTooltip({
  children,
  title,
  description,
  enabled,
  tooltipProps,
  ...floatingProps
}: UseTooltipProps = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    ...floatingProps,
    strategy: 'absolute',
    middleware: [
      arrow({
        element: arrowRef
      }),
      flip()
    ]
  });

  const hover = useHover(context, {
    mouseOnly: true,
    enabled
  });

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const tooltipComp = isMounted && isOpen && (
    <FloatingPortal>
      <Tooltip
        ref={refs.setFloating}
        style={{
          ...floatingStyles,
          ...transitionStyles
        }}
        {...getFloatingProps()}
        title={title}
        description={description}
        {...tooltipProps}
        arrowProps={{
          ref: arrowRef,
          context
        }}>
        {children}
      </Tooltip>
    </FloatingPortal>
  );

  return {
    component: tooltipComp,
    setTargetRef: refs.setReference,
    targetProps: getReferenceProps()
  };
}
