import { useErrorBoundary } from 'react-error-boundary';
import useSWR, { SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { useAxiosClient } from '../../app/providers/axiosClient';

export type MoodleQueryType<Q> = Q & {
  wsfunction: string;
};

export type UseMoodleQueryBaseOptionsType = {
  skip?: boolean;
};

export type UseMoodleQueryOptionsType = UseMoodleQueryBaseOptionsType & SWRConfiguration;

export type MoodleQueryReturn<Q, D> = ReturnType<typeof useMoodleQuery<MoodleQueryType<Q>, D>>;
// { data?, isLoading, error }
export type MoodleQueryBaseReturn<Q, D> = Pick<MoodleQueryReturn<Q, D>, 'data' | 'isLoading' | 'error'>;
// { data, isLoading, error }
export type MoodleQueryBaseWithDataReturn<Q, D> = Omit<MoodleQueryBaseReturn<Q, D>, 'data'> & {
  data: D;
};

export function useMoodleQuery<Q, D>(query?: MoodleQueryType<Q>, config: UseMoodleQueryOptionsType = {}) {
  const { skip, ...swrConfig } = config;
  const axiosClient = useAxiosClient();
  const { showBoundary } = useErrorBoundary();

  const fetcher = async (query: Q) => {
    if (!query) {
      return;
    }
    try {
      const { data } = await axiosClient.request<D>({ data: query });
      return data;
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
      if (error?.code === AxiosError.ERR_NETWORK) {
        showBoundary(error);
      }

      throw error;
    }
  };

  return useSWR(!skip && query, fetcher, swrConfig);
}
