import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import clsx from 'clsx';
import { DivPropsWithoutRef, Extends } from 'utils/types';
import { Input } from 'components/Input';
import { Loader, LoaderSize } from '../../Loader';
import s from './RichInput.module.scss';

export type RichInputDefaultComponent = typeof Input;

export interface RichInputSelfProps<ComponentType extends React.ElementType = RichInputDefaultComponent> {
  /**
   * Компонент
   * @type React.ElementType
   */
  component?: ComponentType;
  /**
   * Подпись/наименование
   */
  label?: React.ReactNode;
  /**
   * Обязательное для заполнения поле
   */
  required?: boolean;
  /**
   * Текст ошибки
   */
  errorMessage?: React.ReactNode;
  /**
   * Дополнительные классы элементов компонента:
   * * root - контейнер
   * * input - класс для component
   * * label - лейбл
   * * error - сообщение ошибки
   * * message – сообщение (`caption` или `errorMessage`)
   * * icon - иконка
   * * header - контейнер для заголовка
   */
  classes?: {
    input?: React.ComponentProps<ComponentType>['classes'];
    root?: string;
    label?: string;
    error?: string;
    message?: string;
    header?: string;
  };
  /**
   * Дополнительный css-класс корневого элемента
   */
  className?: string;
  /**
   * Дополнительные стили корневого элемента
   */
  style?: React.CSSProperties;
  /**
   * Дополнительные свойства корневого элемента
   */
  rootProps?: DivPropsWithoutRef;
  registration?: Partial<UseFormRegisterReturn>;
  isLoading?: boolean;
}

export type RichInputProps<ComponentType extends React.ElementType = RichInputDefaultComponent> = Extends<
  RichInputSelfProps<ComponentType>,
  React.ComponentProps<ComponentType>
>;

export function RichInput<ComponentType extends React.ElementType = RichInputDefaultComponent>({
  component,
  label,
  required,
  errorMessage,
  className,
  classes,
  style,
  rootProps,
  registration,
  isLoading,
  ...baseInputProps
}: RichInputProps<ComponentType>) {
  // Чтобы ts не проверял корректность передачи пропов
  const Component = (component || Input) as React.ElementType;
  const hasMessage = Boolean(errorMessage);

  return (
    <div
      {...rootProps}
      style={{ ...style, ...rootProps?.style }}
      className={clsx(
        s.RichInput,
        { [s.RichInput_hasError]: Boolean(errorMessage) },
        className,
        classes?.root,
        rootProps?.className
      )}>
      {Boolean(label) && (
        <div className={clsx(s.RichInput__header, classes?.header)}>
          <div className={clsx(s.RichInput__label, classes?.label)}>
            {label}
            {required && <span className={s.RichInput__labelIcon}>*</span>}
          </div>
          {isLoading && <Loader className={s.RichInput__loader} size={LoaderSize.small} />}
        </div>
      )}

      <Component
        {...registration}
        {...baseInputProps}
        error={Boolean(errorMessage) || undefined}
        classes={classes?.input}
      />
      {hasMessage && (
        <div className={clsx(s.RichInput__message, classes?.message, errorMessage && classes?.error)}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
