import React from 'react';
import { Container } from 'components/Container';
import { Heading, HeadingSize } from 'components/Heading';
import { Divider } from 'components/Divider';
import { htmlToInlineText } from 'utils/html';
import { LinkFile } from 'components/Link/LinkFile/LinkFile';
import { useAttachments } from 'hooks/useAttachments';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { ButtonBackBox } from 'components/Button/ButtonBack';
import { ModulePageContextType } from '../../ModulePageProvider';
import { PageActions } from '../../_components/PageActions/PageActions';
import { CompletionMode, CompletionState } from '../../../../store/moodleAPI/moodleTypes/Module';
import s from './Resource.module.scss';

export interface ResourceProps {
  data: ModulePageContextType['data'];
  submitLoading: boolean;
  onModuleSubmit: ModulePageContextType['submitModule'];
  onGoBack?: () => void;
}

export const Resource = ({ data, onGoBack, submitLoading, onModuleSubmit }: ResourceProps) => {
  const {
    course,
    module,
    currentModuleContents,
    previousModuleContents,
    nextModuleContents,
    prevModuleLink,
    nextModuleLink
  } = data;

  const description = currentModuleContents?.description;
  const attachments = useAttachments(currentModuleContents?.contents.filter((c) => c.type === 'file'));

  const shouldBeSubmitted =
    module?.completion === CompletionMode.Manual &&
    currentModuleContents?.completiondata?.state === CompletionState.Incomplete;
  const prevModuleAvailability = previousModuleContents?.availabilityinfo;
  const nextModuleAvailability = nextModuleContents?.availabilityinfo;

  return (
    <Container className={s.Resource}>
      {onGoBack && <ButtonBackBox onGoBack={onGoBack} />}

      <Heading
        className={s.Resource__heading}
        component={'h2'}
        size={HeadingSize.H2}
        pageTitle={[module?.name, course?.fullname]}>
        {module?.name}
      </Heading>

      <div className={s.Resource__content}>
        {description && <MoodleContent>{description}</MoodleContent>}
        {!!attachments?.length && (
          <>
            {description && <Divider className={s.Resource__attachmentsDivider} />}

            <div className={s.Resource__attachments}>
              {attachments.map((item, iItem) => (
                <LinkFile className={s.Resource__attachment} key={iItem} {...item} />
              ))}
            </div>
          </>
        )}
      </div>

      <Divider className={s.Resource__actionsDivider} />

      <PageActions
        prevLink={prevModuleLink}
        nextLink={nextModuleLink}
        prevDisabled={!!prevModuleAvailability}
        nextDisabled={!!nextModuleAvailability}
        prevDisabledMessage={htmlToInlineText(prevModuleAvailability)}
        nextDisabledMessage={htmlToInlineText(nextModuleAvailability)}
        shouldBeSubmitted={shouldBeSubmitted}
        submitLoading={submitLoading}
        onSubmitClick={onModuleSubmit}
      />
    </Container>
  );
};
