import React from 'react';
import clsx from 'clsx';
import { ReactFCC } from 'utils/ReactFCC';
import { Icon, IconProps } from '../Icon';
import s from './Input.module.scss';

export interface InputIconProps extends IconProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const InputIcon: ReactFCC<InputIconProps> = React.memo(function InputIcon(props) {
  const { className, onClick, ...iconProps } = props;

  return (
    <div className={clsx(s.Input__icon, { [s.Input__icon_interactive]: !!onClick }, className)}>
      <Icon onClick={onClick} {...iconProps} />
    </div>
  );
});
