import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from '../../hooks/useSearchParams';
import { Select } from '../../components/Select/Select';
import { SelectOptionType } from '../../utils/select/types';
import { SearchInput } from '../../components/Input/SearchInput/SearchInput';
import s from './HomePage.module.scss';

export enum CoursesSort {
  Name = 'name',
  LastStart = 'laststart'
}

export const coursesSortString = {
  [CoursesSort.Name]: 'fullname',
  [CoursesSort.LastStart]: 'ul.timeaccess desc'
};

const sortOptions: SelectOptionType<CoursesSort>[] = [
  {
    label: 'По названию курса',
    value: CoursesSort.Name
  },
  {
    label: 'По последнему запуску',
    value: CoursesSort.LastStart
  }
];

type CoursesFiltersType = {
  search?: string;
  sort?: string;
};

export type UseCoursesFiltersReturnType = [React.ReactNode, CoursesFiltersType];

export function useCoursesFilters(): UseCoursesFiltersReturnType {
  const [query, changeQuery] = useSearchParams(['search', 'sort']);
  const isMountedRef = useRef(false);

  const filter: CoursesFiltersType = useMemo(() => {
    return {
      search: query.search || undefined,
      sort: query.sort || CoursesSort.Name
    };
  }, [query]);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      changeQuery(filter, true);
    }
  }, [changeQuery, filter]);

  const handleFilterChange = useCallback(
    (key: keyof CoursesFiltersType, valueConverter?: (value: any) => any) => {
      return (value: any) => {
        const val = valueConverter ? valueConverter(value) : value;
        changeQuery(
          {
            [key]: val ?? undefined
          },
          true
        );
      };
    },
    [changeQuery]
  );

  const filtersComponent = (
    <div className={s.Filters}>
      <SearchInput
        className={s.Filters__search}
        placeholder={'Поиск'}
        value={filter.search || ''}
        onChange={handleFilterChange('search')}
      />
      <Select
        className={s.Filters__sort}
        placeholder={'Сортировать по...'}
        value={filter.sort}
        options={sortOptions}
        onChange={handleFilterChange('sort')}
      />
    </div>
  );

  return [filtersComponent, filter];
}
