import MoodleMessage from '@skolca/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export function changePassword(target?: Window | null) {
  return new MoodleIframeBridge<MoodleMessage.ChangePasswordRequest, MoodleMessage.ChangePasswordResponse>(
    target ?? window,
    'CHANGE_PASSWORD',
    'CHANGE_PASSWORD'
  ).send();
}
