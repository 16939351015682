import { Dispatch, SetStateAction, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useErrorBoundary } from 'react-error-boundary';
import axios, { AxiosError } from 'axios';
import { Text, TextVariant } from '../../../../components/Text';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { InputSizeVariant, InputVariant, RichInput } from '../../../../components/Input';
import { Button, ButtonSize } from '../../../../components/Button';
import { Link, LinkSize } from '../../../../components/Link';
import { LOGIN_PAGE_ROUTE } from '../../../../app/routes/routes';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { API_URL } from '../../../../config';
import { IWarning } from '../../../../store/moodleAPI/moodleTypes/Warning';
import { RecoveryStage } from '../../RecoveryPage';
import s from './RecoveryPageContentSearch.module.scss';

interface RecoveryFields {
  username: string;
  email: string;
}

export interface CoreAuthRequestPasswordResetData {
  error: boolean;
  data: {
    status: boolean;
    warnings?: Array<IWarning>;
    notice: string;
  };
}

const client = axios.create({ baseURL: API_URL });

export interface RecoveryPageContentSearchProps {
  setStage: Dispatch<SetStateAction<RecoveryStage>>;
}

export function RecoveryPageContentSearch(props: RecoveryPageContentSearchProps) {
  const { setStage } = props;

  const isMobile = useIsMobile();
  const { showBoundary } = useErrorBoundary();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm<RecoveryFields>({
    defaultValues: {
      username: '',
      email: ''
    },
    reValidateMode: 'onChange'
  });

  const [username, email] = watch(['username', 'email']);
  const [isLoading, setIsLoading] = useState(false);

  const requestRecovery = (key: string, value: string) => {
    return client({
      url: '/lib/ajax/service.php?info=core_auth_request_password_reset',
      method: 'POST',
      data: `[{"index":0,"methodname":"core_auth_request_password_reset","args":${JSON.stringify({ [key]: value })}}]`
    });
  };

  const createSubmitHandler =
    (type: 'username' | 'email'): SubmitHandler<RecoveryFields> =>
    async (values) => {
      try {
        clearErrors();
        setIsLoading(true);

        let value = '';

        if (type === 'username') {
          if (!values.username) {
            setError('username', { message: 'Поле обязательно к заполнению' });
            return;
          }

          value = values.username;
        } else {
          if (!values.email) {
            setError('email', { message: 'Поле обязательно к заполнению' });
            return;
          }

          value = values.email;
        }

        const response = await requestRecovery(type, value);
        const data: CoreAuthRequestPasswordResetData | undefined = response?.data?.[0];

        if (data?.data.warnings && data.data.warnings.length !== 0) {
          data?.data.warnings.forEach((warning) => {
            setError(warning.item as 'username' | 'email', { message: warning.message });
          });

          return;
        }

        setStage(RecoveryStage.confirm);
      } catch (err) {
        const error = err as AxiosError;
        console.error(error);
        if (error?.name === 'NetworkError') {
          showBoundary(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <>
      <Text className={s.RecoveryPageContentSearch__text} variant={TextVariant.BODY_S}>
        Для сброса пароля укажите ваш логин или адрес электронной почты. Если ваша учётная запись есть в базе данных, на
        ваш адрес электронной почты будет отправлено письмо, содержащее инструкции по восстановлению доступа.
      </Text>

      <form className={s.RecoveryPageContentSearch__form} onSubmit={handleSubmit(createSubmitHandler('username'))}>
        <Heading className={s.RecoveryPageContentSearch__title} component={'h1'} size={HeadingSize.H5}>
          Поиск по логину
        </Heading>

        <div className={s.RecoveryPageContentSearch__inputs}>
          <RichInput
            variant={InputVariant.round}
            sizeVariant={isMobile ? InputSizeVariant.medium : InputSizeVariant.large}
            type={'text'}
            placeholder={'Логин'}
            errorMessage={errors.username?.message}
            registration={register('username')}
          />

          <Button
            type={'submit'}
            size={isMobile ? ButtonSize.medium : ButtonSize.large}
            disabled={!username || isLoading}>
            Найти
          </Button>
        </div>
      </form>

      <form className={s.RecoveryPageContentSearch__form} onSubmit={handleSubmit(createSubmitHandler('email'))}>
        <Heading className={s.RecoveryPageContentSearch__title} component={'h1'} size={HeadingSize.H5}>
          Поиск по адресу электронной почты
        </Heading>

        <div className={s.RecoveryPageContentSearch__inputs}>
          <RichInput
            variant={InputVariant.round}
            sizeVariant={isMobile ? InputSizeVariant.medium : InputSizeVariant.large}
            type={'text'}
            placeholder={'Электронная почта'}
            errorMessage={errors.email?.message}
            registration={register('email')}
          />

          <Button type={'submit'} size={isMobile ? ButtonSize.medium : ButtonSize.large} disabled={!email || isLoading}>
            Найти
          </Button>
        </div>
      </form>

      <Link
        component={RouterLink}
        to={LOGIN_PAGE_ROUTE}
        size={isMobile ? LinkSize.small : LinkSize.medium}
        underlined
        standalone>
        Авторизация
      </Link>
    </>
  );
}
