import { FC, PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { SWRConfig } from 'swr';
import { MathJaxContext } from 'better-react-mathjax';
import { ErrorEnum, ErrorPage } from '../../routes/error';
import { AxiosClientProvider } from './axiosClient';
import { AuthProvider } from './auth';
import { SiteInfoProvider } from './siteInfo';
import { UserProvider } from './user';
import { CustomCertProvider } from './custom-cert';

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ErrorBoundary fallback={<ErrorPage error={ErrorEnum.Error500} />}>
          <AuthProvider>
            <AxiosClientProvider>
              <SWRConfig
                value={{
                  refreshInterval: 0,
                  revalidateOnFocus: false
                }}>
                <SiteInfoProvider>
                  <UserProvider>
                    <CustomCertProvider>
                      <MathJaxContext
                        config={{
                          chtml: {
                            // Можно поиграться с размером
                            // scale: 1.5
                          }
                        }}>
                        {children}
                      </MathJaxContext>
                    </CustomCertProvider>
                  </UserProvider>
                </SiteInfoProvider>
              </SWRConfig>
            </AxiosClientProvider>
          </AuthProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  );
};
