import MoodleMessage from '@skolca/moodle-hook';
import { toMoodleRoute } from './to-moodle-route';

type MoodleIframePageHandlers = {
  onHeightChange?: (height: number) => void;
  onPageLoad?: (location: string) => void;
  onPageUnload?: () => void;
};

export class MoodleIframePage {
  constructor(private handlers: MoodleIframePageHandlers, private target?: Window) {}

  private processResponse = (event: MessageEvent<MoodleMessage.Response | void>) => {
    if (!event.data) {
      return;
    }

    switch (event.data.type) {
      case 'LOAD': {
        this.handlers.onPageLoad?.((event.data as MoodleMessage.PageOpenResponse).location);
        break;
      }
      case 'UNLOAD': {
        this.handlers.onPageUnload?.();
        break;
      }
      case 'HEIGHT': {
        this.handlers.onHeightChange?.((event.data as MoodleMessage.HeightResponse).height);
        break;
      }
    }
  };

  public async requestPage(pathname: string, target?: Window | null | undefined) {
    this.target = target ?? window;
    this.subscribe();

    await toMoodleRoute(pathname, this.target);
    this.target.postMessage({ type: 'SUBSCRIBE_HEIGHT' }, '*');
  }

  private subscribe() {
    this.unsubscribe();
    window.addEventListener('message', this.processResponse);
  }

  public unsubscribe() {
    window.removeEventListener('message', this.processResponse);
  }
}
