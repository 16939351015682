import React from 'react';
import clsx from 'clsx';
import s from './Text.module.scss';

export enum TextVariant {
  BODY_M = 'body_m',
  BODY_S = 'body_s',
  CAPTION_M = 'caption_m',
  CAPTION_S = 'caption_s'
}

export enum TextColor {
  PRIMARY_80 = 'primary_80',
  PRIMARY_60 = 'primary_60',
  PRIMARY_40 = 'primary_40',
  SECONDARY_100 = 'secondary_100',
  SECONDARY_70 = 'secondary_70',
  SECONDARY_60 = 'secondary_60',
  SECONDARY_40 = 'secondary_40',
  SECONDARY_0 = 'secondary_0',
  SYSTEM_GOOD = 'system_good',
  SYSTEM_BAD = 'system_bad',
  SYSTEM_WARNING = 'system_warning',
  SYSTEM_INFO = 'system_info'
}

export interface TextProps<ComponentType extends React.ElementType = 'p'> {
  /**
   * Тэг компонента
   */
  component?: ComponentType;
  /**
   * Дополнительный css-класс корневого элемента
   */
  className?: string;
  /**
   * Вариант цвета текста
   */
  colorVariant?: TextColor;
  /**
   * Цвет текста
   */
  color?: string;
  /**
   * Не добавлять стили шрифтов
   */
  noStyle?: boolean;
  /**
   * Выводимый текст, если не передан children
   */
  text?: React.ReactNode;
  /**
   * Вариант шрифта
   */
  variant?: TextVariant;
  /**
   * Обработчик нажатия на Text
   */
  onClick?: (e: React.MouseEvent) => void;
  /**
   * Реф на корневой элемент
   */
  innerRef?: React.Ref<ComponentType>;
}

export const Text = <T extends React.ElementType = 'p'>(props: TextProps<T> & React.ComponentPropsWithRef<T>) => {
  const {
    component = 'p',
    children,
    noStyle,
    className,
    style,
    onClick,
    text,
    variant = TextVariant.BODY_M,
    colorVariant = TextColor.SECONDARY_100,
    innerRef,
    color,
    ...otherProps
  } = props;

  const textStyle: React.CSSProperties = style || {};
  if (color) {
    textStyle.color = color;
  }

  const TextComponent = component;

  return (
    <TextComponent
      className={clsx(
        !noStyle && {
          [s.Text_pointer]: onClick,
          [s.Text_body_m]: variant === TextVariant.BODY_M,
          [s.Text_body_s]: variant === TextVariant.BODY_S,
          [s.Text_caption_m]: variant === TextVariant.CAPTION_M,
          [s.Text_caption_s]: variant === TextVariant.CAPTION_S
        },
        s[`Text_color_${colorVariant}`],
        className
      )}
      style={textStyle}
      onClick={onClick}
      ref={innerRef}
      {...otherProps}>
      {children ?? text}
    </TextComponent>
  );
};
