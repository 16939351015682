import { useContext } from 'react';
import { notNull } from '../../../utils/notNull';
import { CustomCertContext } from './CustomCertContext';

/**
 * Возвращает объект { certs, isLoading } с массивом сертификатов (изображение в base64 и ссылка на скачивание) и статусом загрузки
 * Для использования надо просто передать cert.image в тег image
 * ```tsx
 *  <img src={cert.image} alt="" />
 * ```
 */
export const useCustomCert = () => {
  const data = useContext(CustomCertContext);

  return notNull(data);
};
