import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonStretch, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';
import { useTooltip } from 'components/Tooltip';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import s from './PageActions.module.scss';

export interface PageActionsProps {
  className?: string;
  shouldBeSubmitted?: boolean;
  nextLink?: string;
  prevLink?: string;
  prevActionLabel?: string;
  nextActionLabel?: string;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  prevDisabledMessage?: React.ReactNode;
  nextDisabledMessage?: React.ReactNode;
  prevTooltipDisabled?: boolean;
  nextTooltipDisabled?: boolean;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  onPrevClick?: MouseEventHandler;
  onNextClick?: MouseEventHandler;
  onSubmitClick?: MouseEventHandler;
}

export const PageActions = ({
  className,
  shouldBeSubmitted,
  prevLink,
  nextLink,
  prevActionLabel,
  nextActionLabel,
  prevDisabled,
  nextDisabled,
  prevDisabledMessage,
  nextDisabledMessage,
  prevTooltipDisabled,
  nextTooltipDisabled,
  submitDisabled,
  submitLoading,
  onPrevClick,
  onNextClick,
  onSubmitClick
}: PageActionsProps) => {
  const {
    component: prevTooltip,
    setTargetRef: setPrevTooltipTarget,
    targetProps: prevTooltipTargetProps
  } = useTooltip({
    placement: 'top',
    title: 'Заблокирован',
    description: prevDisabledMessage,
    enabled: !prevTooltipDisabled && !!(prevLink && prevDisabled)
  });
  const {
    component: nextTooltip,
    setTargetRef: setNextTooltipTarget,
    targetProps: nextTooltipTargetProps
  } = useTooltip({
    placement: 'top',
    title: 'Заблокирован',
    description: nextDisabledMessage,
    enabled: !nextTooltipDisabled && !!(nextLink && nextDisabled)
  });

  const isMobile = useIsMobile();

  return (
    <div className={clsx(s.PageActions, className)}>
      {shouldBeSubmitted ? (
        <Button
          leftIcon={Icons.CHECK}
          variant={ButtonVariant.secondary}
          stretch={ButtonStretch.fill}
          disabled={submitDisabled}
          isLoading={submitLoading}
          onClick={onSubmitClick}>
          Отметить выполненным
        </Button>
      ) : (
        <>
          {prevLink && (
            <div className={s.PageActions__prev} ref={setPrevTooltipTarget} {...prevTooltipTargetProps}>
              <Button
                component={Link}
                to={prevLink || ''}
                variant={ButtonVariant.ghost}
                size={isMobile ? ButtonSize.medium : ButtonSize.large}
                stretch={ButtonStretch.fill}
                leftIcon={Icons.ARROW_LEFT}
                disabled={prevDisabled}
                onClick={onPrevClick}>
                {prevActionLabel || 'Предыдущий материал'}
              </Button>
            </div>
          )}

          {nextLink && (
            <div className={s.PageActions__next} ref={setNextTooltipTarget} {...nextTooltipTargetProps}>
              <Button
                component={Link}
                to={nextLink || ''}
                rightIcon={Icons.ARROW_RIGHT}
                variant={ButtonVariant.secondary}
                size={isMobile ? ButtonSize.medium : ButtonSize.large}
                stretch={ButtonStretch.fill}
                disabled={nextDisabled}
                onClick={onNextClick}>
                {nextActionLabel || 'Следующий материал'}
              </Button>
            </div>
          )}
        </>
      )}

      {prevTooltip}
      {nextTooltip}
    </div>
  );
};
