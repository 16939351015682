import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import qs from 'qs';
import { Loader, LoaderSize } from '../Loader';
import { MoodleIframePage } from '../../routes/_moodle/moodle-page';
import { getMoodleRoute, toMoodleRoute } from '../../routes/_moodle/to-moodle-route';
import { requestAssignment } from '../../routes/_moodle/request-assignment';
import { useMoodleAuth } from '../../store/moodleAPI/hooks';
import { useAuth } from '../../app/providers/auth';
import { MoodleAuthErrorPlaceholder } from '../MoodleAuthErrorPlaceholder';
import s from './MoodleAssignment.module.scss';

export interface MoodleAssignmentProps {
  className?: string;
  classes?: {
    moodle?: string;
  };
  moduleId: string | number;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onReloadRequest?: () => void;
}

const url = '/mod/assign/view.php'; // ?id=123

type GetAssignmentSrcProps = {
  moduleId: MoodleAssignmentProps['moduleId'];
  query?: { [key: string]: string | number };
};

const getAssignmentPathname = ({ moduleId, query }: GetAssignmentSrcProps) => {
  return `${url}?${qs.stringify({ id: moduleId, ...query })}`;
};

export function MoodleAssignment({
  className,
  classes,
  moduleId,
  loading: loadingAssignment,
  setLoading,
  onReloadRequest
}: MoodleAssignmentProps) {
  const [height, setHeight] = useState(0);
  const [pathname] = useState(() => getAssignmentPathname({ moduleId }));

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [moodlePage] = useState(
    () =>
      new MoodleIframePage({
        onPageLoad: async (location) => {
          setLoading(true);

          if (location.includes('action=grading')) {
            /**
             * редирект на страницу задания,
             * т.к. в админской учетке после изменения ответа на задание
             * идет редирект на страницу оценок
             */
            const url = getAssignmentPathname({ moduleId, query: { action: 'view' } });
            await toMoodleRoute(url, iframeRef.current?.contentWindow);
            return;
          } else if (location.includes('action=view')) {
            /**
             * перезапрашиваем модуль (для обновления его состояния)
             * после изменения ответа на задание и авто-редиректа на страницу задания
             */
            onReloadRequest?.();
          }

          await requestAssignment(iframeRef.current?.contentWindow);

          setLoading(false);
        },
        /**
         * Временно отключено, потому что вызывается по несколько раз,
         * и не всегда корректно
         * TODO разобраться и починить
         */
        // onPageUnload: () => {
        //     setLoading(true);
        // },
        onHeightChange: (height) => {
          setHeight(height);
        }
      })
  );

  const { logOut } = useAuth();
  const { moodleAuthError, isLoggedInMoodle, isMoodleAuthLoading } = useMoodleAuth();

  useEffect(() => {
    if (isLoggedInMoodle) {
      moodlePage.requestPage(pathname, iframeRef.current?.contentWindow);
    }
  }, [isLoggedInMoodle, moodlePage, pathname, setLoading, moduleId]);

  useEffect(() => {
    return () => {
      moodlePage.unsubscribe();
    };
  }, [moodlePage]);

  const isLoading = (loadingAssignment || isMoodleAuthLoading) && !moodleAuthError;

  return (
    <div
      className={clsx(s.MoodleAssignment, className)}
      style={{
        height
      }}>
      {isLoading && <Loader className={s.MoodleAssignment__loader} size={LoaderSize.large} />}
      {moodleAuthError && (
        <MoodleAuthErrorPlaceholder
          className={s.MoodleAssignment__placeholder}
          logOut={() => logOut(undefined, true)}
        />
      )}

      <iframe
        className={clsx(
          s.MoodleAssignment__moodle,
          {
            [s.MoodleAssignment__moodle_hidden]: isLoading || moodleAuthError
          },
          classes?.moodle
        )}
        ref={iframeRef}
        src={getMoodleRoute(pathname)}
        title={'Assignment'}
        // allow={'*'}
        // sandbox="allow-same-origin allow-scripts allow-forms"
      />
    </div>
  );
}
