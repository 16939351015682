import { useCallback, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { uniqueId } from 'lodash-es';
import { useAuth } from '../../app/providers/auth';
import { API_URL } from '../../config';

export type UploadedFile = {
  uniqueId: number;
  author: string;
  component: string;
  contextid: number;
  filearea: string;
  filename: string;
  filepath: string;
  filesize: number;
  itemid: number;
  license: string;
  source: string;
  userid: string;
};

export function useUpload() {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const id: number | undefined = files[0]?.itemid;

  const { authData } = useAuth();
  const token = authData.accessToken;

  const onDrop: DropzoneOptions['onDrop'] = useCallback(
    async function onDrop<T extends File>(acceptedFiles: T[]) {
      if (!token) {
        return;
      }

      const formData = new FormData();

      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.set(`file_${i + 1}`, acceptedFiles[i]);
      }

      if (id) {
        formData.set('itemid', String(id));
      }

      const response = await fetch(API_URL + `/webservice/upload.php?token=${token}`, {
        method: 'POST',
        body: formData
      }).then((r) => r.json());

      if (response && Array.isArray(response)) {
        setFiles((files) => [
          ...files,
          ...response.map((item) => ({
            ...item,
            uniqueId: uniqueId('file-upload')
          }))
        ]);
      }
    },
    [id, token]
  );

  const remove = useCallback((uniqueId: number) => {
    setFiles((files) => files.filter((i) => i.uniqueId !== uniqueId));
  }, []);

  return {
    id,
    files,
    remove,
    onDrop
  };
}
