import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  COURSE_MODULE_PAGE_ROUTE,
  COURSE_PAGE_ROUTE,
  COURSES_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  PROFILE_PAGE_ROUT,
  RECOVERY_PAGE_ROUTE,
  SERVER_ERROR_PAGE_ROUTE,
  TEACHER_PAGE_ROUTE,
  QUIZ_PAGE_ROUTE
} from 'app/routes/routes';
import { TeacherPage } from 'routes/teacher';
import { BaseLayout } from '../../routes/_layouts';
import { HomePage } from '../../routes/home';
import { ProfilePage } from '../../routes/profile';
import { LoginPage } from '../../routes/login';
import { useAuth } from '../providers/auth';
import { useScrollTopOnRouteChange } from '../../utils/useScrollTopOnRouteChange';
import { RecoveryPage } from '../../routes/recovery';
import { CoursePage } from '../../routes/course';
import { ModulePage, ModulePageAside, ModulePageProvider } from '../../routes/module';
import { ErrorEnum, ErrorPage } from '../../routes/error';
import { QuizPage } from '../../routes/quiz';

export function AppRoutes() {
  const { isLoggedIn } = useAuth();
  const { pathname, search } = useLocation();

  useScrollTopOnRouteChange();

  return (
    <Routes>
      <Route path={LOGIN_PAGE_ROUTE} element={<LoginPage />} />
      <Route path={RECOVERY_PAGE_ROUTE} element={<RecoveryPage />} />

      {/* error pages */}
      <Route path={NOT_FOUND_PAGE_ROUTE} element={<ErrorPage error={ErrorEnum.Error404} />} />
      <Route path={SERVER_ERROR_PAGE_ROUTE} element={<ErrorPage error={ErrorEnum.Error500} />} />

      {isLoggedIn() ? (
        <>
          <Route element={<BaseLayout />}>
            <Route path={COURSES_PAGE_ROUTE} element={<HomePage />} />
            <Route path={COURSE_PAGE_ROUTE} element={<CoursePage />} />
            <Route path={PROFILE_PAGE_ROUT} element={<ProfilePage />} />
            <Route path={TEACHER_PAGE_ROUTE} element={<TeacherPage />} />

            {/* Отключены, так как страницы предоставляются ссылками на PDF */}
            {/*<Route path={POLICY_PAGE_ROUTE} element={<PolicyPage />} />*/}
            {/*<Route path={RULES_PAGE_ROUTE} element={<RulesPage />} />*/}
          </Route>

          <Route element={<BaseLayout dataProvider={ModulePageProvider} rightSlot={<ModulePageAside />} />}>
            <Route path={COURSE_MODULE_PAGE_ROUTE} element={<ModulePage />} />
          </Route>

          <Route path={QUIZ_PAGE_ROUTE} element={<QuizPage />} />

          <Route path={'*'} element={<Navigate to={COURSES_PAGE_ROUTE} replace />} />
        </>
      ) : (
        // auth guard
        <Route
          path={'*'}
          element={<Navigate to={`${LOGIN_PAGE_ROUTE}?next=${encodeURIComponent(pathname + search)}`} replace />}
        />
      )}
    </Routes>
  );
}
