import { Icons } from 'components/Icons';

export const HOME_PAGE_ROUTE = '/';
export const PROFILE_PAGE_ROUT = '/profile';
export const LOGIN_PAGE_ROUTE = '/login';
export const RECOVERY_PAGE_ROUTE = '/recovery';
export const COURSES_PAGE_ROUTE = '/courses';
export const COURSE_PAGE_PARAM = 'courseId';
export const COURSE_PAGE_ROUTE = `/courses/:${COURSE_PAGE_PARAM}`;
export const TEACHER_PAGE_ROUTE = '/teachers/:teacherId';
export const COURSE_MODULE_PAGE_ROUTE = `${COURSE_PAGE_ROUTE}/modules/:moduleId`;
export const POLICY_PAGE_ROUTE = '/privacy-policy';
export const RULES_PAGE_ROUTE = '/rules';
export const NOT_FOUND_PAGE_ROUTE = '/404';
export const SERVER_ERROR_PAGE_ROUTE = '/500';

export const QUIZ_PAGE_QUIZ_PARAM = 'quizId';
export const QUIZ_PAGE_ROUTE = `${COURSE_PAGE_ROUTE}/quiz/:${QUIZ_PAGE_QUIZ_PARAM}`;

export const navLinks = [
  {
    path: COURSES_PAGE_ROUTE,
    label: 'Курсы',
    icon: Icons.NAV_COURSES
  }
];
