import React from 'react';
import { Link, LinkSize, LinkVariant } from 'components/Link';
import s from './Footer.module.scss';

const links = [
  {
    href: 'https://docs.skolca.ru/Pravila_PDn.pdf',
    label: 'Политика обработки данных'
  }
  // TODO Добавить, когда появится
  // {
  //   href: 'https://docs.skolca.ru/Pravila_PDn.pdf',
  //   label: 'Правила пользования платформой'
  // }
];

export const Footer = () => {
  return (
    <div className={s.Footer}>
      <div className={s.Footer__copy}>© 2023 ОАНО «Сколка». Все права защищены</div>
      <div className={s.Footer__ext}>
        {links.map((link, iLink) => (
          <Link
            key={iLink}
            component={'a'}
            href={link.href}
            variant={LinkVariant.secondary}
            size={LinkSize.small}
            underlined={false}
            target={'_blank'}
            rel={'noopener noreferrer'}>
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
