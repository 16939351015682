import { ElementType, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { PageHelmet } from 'components/PageHelmet';
import { useAuth } from '../../app/providers/auth';
import { LoginLayout } from '../_layouts/LoginLayout';
import { HOME_PAGE_ROUTE } from '../../app/routes/routes';
import { RecoveryPageContentSearch, RecoveryPageContentConfirm } from './content';
import s from './RecoveryPage.module.scss';

export enum RecoveryStage {
  search = 'search',
  confirm = 'confirm'
}

const pageContent: Record<RecoveryStage, ElementType> = {
  [RecoveryStage.search]: RecoveryPageContentSearch,
  [RecoveryStage.confirm]: RecoveryPageContentConfirm
};

export function RecoveryPage() {
  const { isLoggedIn } = useAuth();

  const [stage, setStage] = useState(RecoveryStage.search);

  const PageContent = pageContent[stage];

  if (isLoggedIn()) return <Navigate to={HOME_PAGE_ROUTE} replace />;

  return (
    <LoginLayout className={s.RecoveryPage}>
      <PageHelmet title={'Восстановление пароля'} />
      <PageContent setStage={setStage} />
    </LoginLayout>
  );
}
