import { Navigate } from 'react-router-dom';
import clsx from 'clsx';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { useQuizAttemptReview } from '../../../../../../store/moodleAPI/hooks';
import { NOT_FOUND_PAGE_ROUTE } from '../../../../../../app/routes/routes';
import { QuizQuestionReview } from '../QuizQuestionReview';
import { Loader, LoaderSize } from '../../../../../../components/Loader';
import s from './QuizReview.module.scss';

export interface QuizAttemptReviewProps {
  className?: string;
}

export function QuizReview({ className }: QuizAttemptReviewProps) {
  const [query] = useSearchParams(['attempt']);

  const attemptid = Number(query.attempt) || 0;

  const { data, isLoading, error } = useQuizAttemptReview({
    attemptid
  });

  const questions = data?.questions || [];

  if (error) {
    return <Navigate to={NOT_FOUND_PAGE_ROUTE} />;
  }

  return (
    <div
      className={clsx(s.QuizReview, className, {
        [s.QuizReview_centered]: isLoading
      })}>
      {isLoading ? (
        <Loader size={LoaderSize.large} />
      ) : (
        questions.map((question, index) => <QuizQuestionReview question={question} key={index} />)
      )}
    </div>
  );
}
