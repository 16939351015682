import React, { useMemo } from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import clsx from 'clsx';
import { SelectOptionType } from 'utils/select/types';
import { Icons } from '../Icons';
import { Icon } from '../Icon';
import s from './Select.module.scss';

export enum SelectSize {
  Large = 'large',
  Medium = 'medium'
}

export interface SelectProps<V>
  extends Pick<ReactSelectProps<SelectOptionType<V>>, 'className' | 'name' | 'placeholder'> {
  value?: V | V[];
  options?: SelectOptionType<V>[];
  size?: SelectSize;
  disabled?: boolean;
  loading?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  onChange?: (value: V | V[] | null) => void;
}

export const Select = <V,>({
  className,
  name,
  value,
  options,
  size = SelectSize.Large,
  placeholder,
  disabled,
  loading,
  clearable,
  searchable,
  onChange
}: SelectProps<V>) => {
  const handleChange = (option?: SelectOptionType<V> | SelectOptionType<V>[] | null) => {
    const val = (() => {
      if (!option) return null;
      return Array.isArray(option) ? option.map((o) => o.value) : option.value;
    })();
    onChange?.(val);
  };

  const val = useMemo(() => {
    if (!options?.length) return null;
    return options.find((o) => o.value === value);
  }, [options, value]);

  return (
    <ReactSelect
      className={clsx(s.Select, s[`Select_${size}`], className)}
      classNames={{
        control: () => s.Select__control,
        input: () => s.Select__input,
        placeholder: () => s.Select__placeholder,
        valueContainer: () => s.Select__valueContainer,
        singleValue: () => s.Select__singleValue,
        indicatorSeparator: () => s.Select__indicatorSeparator,
        menu: () => s.Select__menu,
        menuList: () => s.Select__menuList,
        option: ({ isSelected, isFocused }) =>
          clsx(s.Select__option, {
            [s.Select__option_selected]: isSelected,
            [s.Select__option_focused]: isFocused
          })
      }}
      components={{
        DropdownIndicator: ({ isFocused }) => (
          <Icon
            className={clsx(s.Select__dropdownIndicator, {
              [s.Select__dropdownIndicator_focused]: isFocused
            })}
            icon={Icons.CHEVRON_CURVED_DOWN}
          />
        )
      }}
      name={name}
      options={options}
      value={val}
      placeholder={placeholder || 'Выберите...'}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={clearable}
      isSearchable={searchable}
      onChange={handleChange}
      // FOR DEV
      // defaultMenuIsOpen
    />
  );
};
