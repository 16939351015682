import React from 'react';
import clsx from 'clsx';
import { Container } from 'components/Container';
import { Loader, LoaderSize } from 'components/Loader';
import { Heading, HeadingSize } from 'components/Heading';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { Divider } from 'components/Divider';
import { CompletionMode, CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { htmlToInlineText } from 'utils/html';
import { ButtonBackBox } from 'components/Button/ButtonBack';
import { usePageData } from 'hooks/module/usePageData';
import { ModulePageContextType } from '../../ModulePageProvider';
import { PageActions } from '../../_components/PageActions/PageActions';
import s from './Page.module.scss';

export interface PageProps {
  data: ModulePageContextType['data'];
  submitLoading?: boolean;
  onModuleSubmit?: ModulePageContextType['submitModule'];
  onGoBack?: () => void;
}

export const Page = ({ data, onGoBack, submitLoading, onModuleSubmit }: PageProps) => {
  const {
    course,
    module,
    currentModuleContents,
    previousModuleContents,
    nextModuleContents,
    prevModuleLink,
    nextModuleLink
  } = data;

  const { content, isLoading } = usePageData(course?.id, module?.id);

  const shouldBeSubmitted =
    module?.completion === CompletionMode.Manual &&
    currentModuleContents?.completiondata?.state === CompletionState.Incomplete;
  const prevModuleAvailability = previousModuleContents?.availabilityinfo;
  const nextModuleAvailability = nextModuleContents?.availabilityinfo;

  return (
    <Container
      className={clsx(s.Page, {
        [s.Page_centered]: isLoading
      })}>
      {isLoading ? (
        <Loader size={LoaderSize.large} />
      ) : (
        <>
          {onGoBack && <ButtonBackBox onGoBack={onGoBack} />}

          <Heading
            className={s.Page__heading}
            component={'h2'}
            size={HeadingSize.H2}
            pageTitle={[module?.name, course?.fullname]}>
            {module?.name}
          </Heading>

          <div className={s.Page__content}>{content && <MoodleContent>{content}</MoodleContent>}</div>

          <Divider className={s.Page__actionsDivider} />

          <PageActions
            prevLink={prevModuleLink}
            nextLink={nextModuleLink}
            prevDisabled={!!prevModuleAvailability}
            nextDisabled={!!nextModuleAvailability}
            prevDisabledMessage={htmlToInlineText(prevModuleAvailability)}
            nextDisabledMessage={htmlToInlineText(nextModuleAvailability)}
            shouldBeSubmitted={shouldBeSubmitted}
            submitLoading={submitLoading}
            onSubmitClick={onModuleSubmit}
          />
        </>
      )}
    </Container>
  );
};
