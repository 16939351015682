import { useContext } from 'react';
import { notNull } from '../../../utils/notNull';
import { UserContext } from './UserContext';

export const useUser = () => {
  const query = useContext(UserContext);
  const userQuery = notNull(query);

  return {
    ...userQuery,
    data: userQuery?.data?.[0] || null
  };
};
