import clsx from 'clsx';
import { TabItem, TabItemProps } from './TabItem';
import s from './Tabs.module.scss';

export interface TabsProps<T extends string> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Элементы табов
   */
  tabs: TabItemProps<T>[];
  /**
   * ID активной вкладки
   */
  activeId: TabItemProps<T>['id'];
  /**
   * Сеттер ID активной вкладки
   */
  setActive: (value: TabItemProps<T>['id']) => void;
}

export const Tabs = <T extends string>(props: TabsProps<T>) => {
  const { className, tabs, activeId, setActive } = props;

  return (
    <div className={clsx(s.Tabs, className)}>
      <div className={s.Tabs__list}>
        {tabs.map((tab, index) => (
          <TabItem active={tab.id === activeId} onClick={(id) => setActive(id)} {...tab} key={index} />
        ))}
      </div>
    </div>
  );
};
