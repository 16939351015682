import React, { ComponentProps, ElementType, JSX } from 'react';
import { To } from 'react-router-dom';

import clsx from 'clsx';

import { IUser } from 'store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';
import { useImageSrc } from 'hooks/useImageSrc';

import { Button, ButtonVariant, ButtonSize } from 'components/Button';
import { PolyExtends } from '../../utils/types';
import { Avatar, AvatarSize } from '../Avatar/Avatar';
import { Icon, IconSize } from '../Icon';
import { Icons } from '../Icons';

import s from './UserContact.module.scss';

export const UserContactDefaultComponent = 'a' as const;
export type UserContactDefaultComponentType = typeof UserContactDefaultComponent;

export enum UserContactVariant {
  info = 'info',
  profile = 'profile'
}

export interface UserContactSelfProps {
  className?: string;
  user: IUser;
  to?: To;
  href?: string;
  variant?: UserContactVariant;
}

export type UserContactProps<ComponentType extends ElementType = UserContactDefaultComponentType> = PolyExtends<
  ComponentType,
  UserContactSelfProps,
  ComponentProps<ComponentType>
>;

export function UserContact(props: UserContactProps<'button'>): JSX.Element;
export function UserContact<ComponentType extends ElementType>(props: UserContactProps<ComponentType>): JSX.Element;
export function UserContact<ComponentType extends ElementType = UserContactDefaultComponentType>({
  component,
  className,
  user,
  variant = UserContactVariant.info,
  ...props
}: UserContactProps<ComponentType>) {
  const Component = (component || UserContactDefaultComponent) as ElementType;

  const clickable = !!(props?.onClick || props?.to);
  const imageSrc = useImageSrc(user?.profileimageurl);

  return (
    <Component
      className={clsx(s.UserContact, className, s[`UserContact_variant_${variant}`], {
        [s.UserContact_clickable]: clickable
      })}
      {...props}>
      <Avatar className={s.UserContact__avatar} size={AvatarSize.XLarge} imageSrc={imageSrc} userName={user.fullname} />
      <div className={s.UserContact__info}>
        <div className={s.UserContact__name}>{user.fullname}</div>
        {variant === 'info' ? (
          <div className={s.UserContact__email}>
            <Icon icon={Icons.MAIL_SMALL} size={IconSize.small} />
            {user.email}
          </div>
        ) : (
          <Button
            component={'a'}
            href={`mailto: ${user.email}`}
            variant={ButtonVariant.tertiary}
            leftIcon={Icons.MAIL_SMALL}
            size={ButtonSize.small}
            iconSize={IconSize.small}
            className={s.UserContact__button}>
            Написать сообщение
          </Button>
        )}
      </div>
    </Component>
  );
}
