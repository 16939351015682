import React, { ComponentType, Fragment, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { useIsTablet } from 'hooks/useIsTablet';
import { Loader, LoaderSize } from 'components/Loader';
import { useSiteInfo } from 'app/providers/siteInfo';
import { Header } from '../Header';
import { AsideNav } from '../AsideNav';
import { Footer } from '../Footer';
import s from './BaseLayout.module.scss';

export interface BaseLayoutProps {
  dataProvider?: ComponentType<PropsWithChildren>;
  rightSlot?: React.ReactNode;
}

export function BaseLayout({ dataProvider, rightSlot }: BaseLayoutProps) {
  const isTablet = useIsTablet();
  const info = useSiteInfo();

  const initLoading = !info?.data && !!info?.isLoading;

  const ProviderComp = dataProvider || Fragment;

  return (
    <div
      className={clsx(s.BaseLayout, {
        [s.BaseLayout_loading]: initLoading
      })}>
      {initLoading ? (
        <Loader size={LoaderSize.large} />
      ) : (
        <>
          <Header />

          {!isTablet && <AsideNav />}

          <ProviderComp>
            <main className={s.BaseLayout__main}>
              <div className={s.BaseLayout__mainBox}>
                <div className={s.BaseLayout__content}>
                  <Outlet />
                </div>
                <Footer />
              </div>
            </main>

            {rightSlot}
          </ProviderComp>
        </>
      )}
    </div>
  );
}
