import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Icon, IconProps } from '../Icon';
import { Divider, DividerVariant } from '../Divider';
import s from './ActionBox.module.scss';

export type ActionBoxActionType = Pick<IconProps, 'className' | 'icon' | 'size' | 'color'> & {
  onClick?: (e: React.MouseEvent) => void;
};

export interface ActionBoxProps {
  className?: string;
  actions: ActionBoxActionType[];
}

export const ActionBox = ({ className, actions }: ActionBoxProps) => {
  return (
    <div className={clsx(s.ActionBox, className)}>
      {actions.map(({ onClick, ...item }, iItem) => (
        <Fragment key={iItem}>
          {iItem > 0 && <Divider className={s.ActionBox__divider} variant={DividerVariant.Vertical} />}
          <button className={s.ActionBox__button} onClick={onClick}>
            <Icon {...item} />
          </button>
        </Fragment>
      ))}
    </div>
  );
};
