import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModPageGetPagesByCoursesData,
  ModPageGetPagesByCoursesHookArgs,
  ModPageGetPagesByCoursesQuery
} from '../moodleTypes/_functions/mod_page_get_pages_by_courses';

export function usePagesByCourses({ moodleConfig, courseids }: Partial<ModPageGetPagesByCoursesHookArgs>) {
  return useMoodleQuery<ModPageGetPagesByCoursesQuery, ModPageGetPagesByCoursesData>(
    {
      wsfunction: 'mod_page_get_pages_by_courses',
      courseids
    },
    {
      skip: !courseids?.length,
      ...moodleConfig
    }
  );
}
