import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { intervalToDuration } from 'date-fns';
import clsx from 'clsx';
import { Icons } from '../../../../components/Icons';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../../../components/IconButton';
import { Text, TextVariant } from '../../../../components/Text';
import { Icon, IconSize } from '../../../../components/Icon';
import { Heading, HeadingSize } from '../../../../components/Heading';
import { PageHelmet } from '../../../../components/PageHelmet';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import s from './QuizHeader.module.scss';

export interface QuizHeaderProps {
  onGoBack: () => void;
  time: number | null;
  moduleName?: string;
  setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
}

export function QuizHeader({ onGoBack, time, moduleName, setMobileNavOpen }: QuizHeaderProps) {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const labelElement = useMemo(
    () => (
      <Text className={s.QuizHeader__label} component={'span'} variant={TextVariant.BODY_M}>
        {moduleName}
      </Text>
    ),
    [moduleName]
  );

  return (
    <div className={s.QuizHeader}>
      <div className={s.QuizHeader__heading}>
        <IconButton
          className={s.CourseHead__backButton}
          icon={Icons.CHEVRON_CURVED_LEFT}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          rounded
          onClick={() => onGoBack()}
        />

        <PageHelmet title={'Тестирование'} />

        {!isMobile && labelElement}
      </div>

      {time !== null && (
        <div className={s.QuizHeader__timer}>
          <Icon
            className={s.QuizHeader__timerIcon}
            icon={isMobile ? Icons.CLOCK_SMALL : Icons.CLOCK}
            size={isMobile ? IconSize.small : IconSize.medium}
          />

          <div className={s.QuizHeader__timerText}>
            {!isMobile && (
              <Text component={'span'} variant={TextVariant.BODY_M}>
                Осталось времени:
              </Text>
            )}
            <Heading
              classes={{
                title: clsx(s.QuizHeader__time, {
                  [s.QuizHeader__time_bad]: time <= 60
                })
              }}
              component={'span'}
              size={HeadingSize.H4}>
              {formatDuration(time)}
            </Heading>
          </div>
        </div>
      )}

      {isTablet && (
        <IconButton
          className={s.QuizHeader__button}
          icon={Icons.QUESTIONS}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          onClick={() => setMobileNavOpen(true)}
          rounded
        />
      )}

      {isMobile && labelElement}
    </div>
  );
}

function formatDuration(seconds: number) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const zeroPad = (num: number) => String(num).padStart(2, '0');
  return `${zeroPad(duration.minutes ?? 0)}:${zeroPad(duration.seconds ?? 0)}`;
}
