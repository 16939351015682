import React from 'react';
import { generatePath } from 'react-router-dom';
import { IModule } from 'store/moodleAPI/moodleTypes/Module';
import { COURSE_MODULE_PAGE_ROUTE } from 'app/routes/routes';
import { CoursesUtils, ModuleVariant } from 'utils/courses/CoursesUtils';
import { Module } from '../Module/Module';
import s from './Modules.module.scss';

export interface ModulesProps {
  index: number | string;
  courseId?: string;
  modules?: IModule[];
  sectionLocked?: boolean;
}

export const Modules = ({ index, courseId, sectionLocked, modules }: ModulesProps) => {
  const lockedVariant = sectionLocked ? ModuleVariant.Locked : undefined;

  return (
    <div className={s.Modules}>
      <div className={s.Modules__list}>
        {modules?.map((mod, iMod) => {
          return (
            <Module
              key={iMod}
              index={`${index}.${iMod + 1}`}
              variant={lockedVariant || CoursesUtils.mapModuleCompletionToModuleVariant(mod)}
              sectionLocked={sectionLocked}
              {...mod}
              to={generatePath(COURSE_MODULE_PAGE_ROUTE, {
                courseId,
                moduleId: mod.id
              })}
            />
          );
        })}
      </div>
    </div>
  );
};
