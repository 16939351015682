import { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '../../../../../../utils/types';
import { Collapse, CollapseVariant } from '../../../../../../components/Collapse';
import { Badge, BadgeVariant } from '../../../../../../components/Badge';
import { Icon, IconSize } from '../../../../../../components/Icon';
import { Icons } from '../../../../../../components/Icons';
import s from './QuizQuestionReviewLayout.module.scss';

export interface QuizQuestionReviewLayoutProps extends DivPropsWithoutRef {
  className?: string;
  classes?: {
    content?: string;
  };
  title: string;
  state?: string; // 'gradedright' | 'gradedwrong' | 'gradedpartial' | 'gaveup' | 'needsgrading' | 'mangrright' | 'mangrpartial' | 'mangrwrong'
  grade?: string;
}

export function QuizQuestionReviewLayout({
  children,
  className,
  classes,
  title,
  state,
  grade
}: PropsWithChildren<QuizQuestionReviewLayoutProps>) {
  const badgeElement = useMemo(() => {
    switch (state) {
      case 'gradedright':
      case 'mangrright':
        return (
          <Badge variant={BadgeVariant.good} left={<Icon icon={Icons.CHECK} size={IconSize.small} />}>
            Верно
          </Badge>
        );
      case 'gradedwrong':
      case 'mangrwrong':
        return (
          <Badge variant={BadgeVariant.bad} left={<Icon icon={Icons.CANCEL} size={IconSize.small} />}>
            Неверно
          </Badge>
        );
      case 'gradedpartial':
      case 'mangrpartial':
        return <Badge variant={BadgeVariant.warning}>Частично верно</Badge>;
      case 'gaveup':
        return <Badge variant={BadgeVariant.bad}>Нет ответа</Badge>;
      case 'needsgrading':
        return <Badge variant={BadgeVariant.warning}>На проверке</Badge>;
      default:
        return null;
    }
  }, [state]);

  return (
    <Collapse
      className={clsx(s.QuizQuestionReviewLayout, className)}
      classes={{
        head: s.QuizQuestionReviewLayout__head,
        title: s.QuizQuestionReviewLayout__title,
        button: s.QuizQuestionReviewLayout__button,
        content: clsx(s.QuizQuestionReviewLayout__content, classes?.content)
      }}
      title={
        <div className={s.QuizQuestionReviewLayout__titleContent}>
          <span className={s.QuizQuestionReviewLayout__titleContentText}>{title}</span>
          {grade && <Badge variant={BadgeVariant.neutral}>{grade}</Badge>}
          {badgeElement}
        </div>
      }
      variant={CollapseVariant.Normal}
      open={true}
      icon={false}>
      {children}
    </Collapse>
  );
}
