import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Icon, IconProps, IconSize } from '../Icon';
import { Divider, DividerVariant } from '../Divider';
import s from './Label.module.scss';

export enum LabelVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export type LabelItemType = {
  label?: React.ReactNode;
  iconLeft?: IconProps['icon'];
  iconRight?: IconProps['icon'];
  // iconProps?: Pick<IconProps, 'className' | 'size' | 'color'>;
};

export interface LabelProps extends LabelItemType {
  className?: string;
  variant?: LabelVariant;
  labels?: LabelItemType[];
}

export const Label = React.memo(function Label({
  className,
  variant = LabelVariant.Primary,
  labels,
  label,
  iconLeft,
  iconRight
}: LabelProps) {
  const labelItems = labels || [];

  if (label || iconLeft || iconRight) {
    labelItems.unshift({ label, iconLeft, iconRight });
  }

  return (
    <div className={clsx(s.Label, s[`Label_${variant}`], className)}>
      {labelItems.map((item, iItem) => (
        <Fragment key={iItem}>
          {iItem > 0 && <Divider className={s.Label__divider} variant={DividerVariant.Vertical} />}
          <div className={s.LabelItem}>
            {item.iconLeft && <Icon className={s.LabelItem__icon} icon={item.iconLeft} size={IconSize.small} />}
            {item.label}
            {item.iconRight && <Icon className={s.LabelItem__icon} icon={item.iconRight} size={IconSize.small} />}
          </div>
        </Fragment>
      ))}
    </div>
  );
});
