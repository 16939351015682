import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { MathJax } from 'better-react-mathjax';
import { useSiteInfoData } from 'app/providers/siteInfo';
import { insertAccessKeyIntoUrl } from 'utils/insertAccessKeyIntoUrl';
import { copyToClipboard } from 'utils/clipboard';
import { API_URL } from '../../config';
import bqLeftSrc from './_assets/bq-left.svg';
import bqRightSrc from './_assets/bq-right.svg';
import { iconCopy } from './_components/icon-copy';
import s from './MoodleContent.module.scss';

type CallbackRefType = { nodeId: string; cbs: { onClick?: () => void } };

export interface IMoodleContentProps {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  style?: React.CSSProperties;
  // Moodle HTML
  children: string;
  wide?: boolean;
}

const parser = new DOMParser();
const serializer = new XMLSerializer();

function removeWrappingParagraphTag(node: HTMLElement) {
  const parent = node.parentElement;
  // тег p не может содержать в себе div
  if (parent && parent.tagName === 'P') {
    // передаем логику text-align: center;
    if (parent.style.textAlign === 'center') {
      node.style.marginRight = node.style.marginLeft = 'auto';
    }

    parent.after(node);
    parent.remove();
  }
}

export function MoodleContent({ className, innerRef, style, children, wide }: IMoodleContentProps) {
  const cbRefs = useRef<CallbackRefType[]>([]);

  const addCallback = useCallback((cb: CallbackRefType) => {
    const currCbRef = cbRefs.current.find((c) => c.nodeId === cb.nodeId);
    if (currCbRef) {
      currCbRef.cbs = cb.cbs;
    } else {
      cbRefs.current.push(cb);
    }
  }, []);

  const handleContentClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    const target = e.target as HTMLElement;
    const cbRef = cbRefs.current.find((cb) => cb.nodeId === target?.id);
    if (cbRef) {
      cbRef.cbs.onClick?.();
    }
  }, []);

  const handleMessage = (e: MessageEvent) => {
    // h5p embedded content is fully loaded
    if (e.data.type === 'HEIGHT') document.getElementById('h5p-content')?.setAttribute('height', e.data.payload);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  let htmlString = children
    // [1.1] заменяет все эти странные пробелы на обычные / делает результат более предсказуемым
    // eslint-disable-next-line no-irregular-whitespace
    .replace(/ |&nbsp;/g, ' ')
    // [1.2] на сайте edu.hse.ru у body установлен font-size: 0.9375rem, такой же размер установлен на рандомных блоках разметки,
    // из-за чего у нас эти блоки в неожиданных местах имеют меньший размер, если не подменить этот стиль
    .replace(/font-size: 0.9375rem;/g, 'font-size: inherit;');

  // [2] вставляет siteInfoData.userprivateaccesskey
  const { userprivateaccesskey } = useSiteInfoData();

  // [3] определяет, надо ли обрабатывать TeX формулы
  // const [hasMath, setHasMath] = useState(false);

  htmlString = insertAccessKeyIntoUrl(htmlString, userprivateaccesskey);

  const content = useMemo(() => {
    const content = parser.parseFromString(htmlString, 'text/html');

    const wrapper = content.querySelector('.no-overflow');
    if (wrapper) {
      wrapper.replaceWith(...Array.from(wrapper.children));
    }

    content.querySelectorAll('.mediaplugin').forEach((m) => {
      m.classList.add(s.MoodleContent__media);
    });

    // TODO: починить работу с ютубом (видео не воспроизводится)
    content.querySelectorAll('video').forEach((video) => {
      // [3] исправляет неправильно вставленные видео с YouTube
      if (video.innerHTML.includes('https://youtu.be/')) {
        const id = video.innerHTML.split('/')[3].split('"')[0];
        if (id) {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('src', `https://www.youtube.com/embed/${id}`);
          iframe.setAttribute('width', '100%');
          // iframe.setAttribute('height', '225');
          // prettier-ignore
          iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
          iframe.setAttribute('allowfullscreen', '');

          video.replaceWith(iframe);
        }
      }

      if (video.innerHTML.includes('youtube.com/watch?v=')) {
        let src = 'https://www.youtube.com/embed/';

        const id = video.innerHTML.split('?v=')[1];
        if (id.indexOf('"')) src += id.substring(0, id.indexOf('"'));
        if (id.indexOf('<')) src += id.substring(0, id.indexOf('<'));

        if (src !== 'https://www.youtube.com/embed/') {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('src', src);
          iframe.setAttribute('width', '100%');
          // iframe.setAttribute('height', '225');
          // prettier-ignore
          iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
          iframe.setAttribute('allowfullscreen', '');

          video.replaceWith(iframe);
        }
      }
    });

    content.querySelectorAll('iframe').forEach((iframe) => {
      // [4] оборачивает iframe в два контейнера, чтобы сохранить соотношение сторон
      const container = document.createElement('div');
      const innerContainer = document.createElement('div');

      container.setAttribute('class', s.MoodleContent__iFrameContainer);
      // container.style.maxWidth = (iframe.width || '400') + 'px';
      container.appendChild(innerContainer);

      if (iframe.width && !iframe.width.includes('%') && iframe.height && !iframe.height.includes('%')) {
        const width = parseInt(iframe.width);
        const height = parseInt(iframe.height);
        innerContainer.style.paddingBottom = (height / width) * 100 + '%';
      } else {
        innerContainer.style.paddingBottom = '56.25%';
      }

      iframe.replaceWith(container);
      innerContainer.appendChild(iframe);

      removeWrappingParagraphTag(container);
    });

    // TODO: перейти на использование непосредственно MoodlePage
    // [5] встраивает h5p-контент
    content.querySelectorAll('.h5p-placeholder').forEach((h5p) => {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', `${API_URL}/h5p/embed.php?url=${h5p.innerHTML}`);
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('width', '100%');
      // подумать над полноценной поддержкой сразу нескольких фреймов
      iframe.setAttribute('id', `h5p-content`);
      h5p.replaceWith(iframe);
    });

    // [6] не дает (совсем) сломать верстку тегами br
    content.querySelectorAll('br').forEach((br) => {
      if (!br.nextSibling || br.nextSibling.nodeName === 'BR') br.remove();
    });

    // [7] оборачивает текст в span, чтобы можно было делать относительные отступы
    content.querySelectorAll('div, p').forEach((parent) => {
      parent.childNodes.forEach((child) => {
        if (child.nodeType === 3) {
          const span = document.createElement('span');
          child.after(span);
          span.appendChild(child);
        }
      });
    });

    // [8] img: убирает img.align + добавляет контейнер с подписью
    content.querySelectorAll('img').forEach((img) => {
      img.align = '';

      const box = document.createElement('div');
      box.classList.add(s.MoodleContent__imageBox);
      /**
       * Надо подумать, что делать с флоатом,
       * т.к. выравнивание по левому/правому краю в редакторе работает через флоат
       */
      // box.style.float = img.style.float;

      const boxInner = document.createElement('div');
      boxInner.classList.add(s.MoodleContent__imageBoxInner);
      boxInner.style.maxWidth = img.width ? `${img.width}px` : '';
      boxInner.style.marginLeft = img.style.marginLeft;
      boxInner.style.marginRight = img.style.marginRight;

      box.appendChild(boxInner);
      img.replaceWith(box);

      boxInner.appendChild(img);

      if (img.alt) {
        const imgAltBox = document.createElement('div');
        imgAltBox.classList.add(s.MoodleContent__imageBoxAlt);
        imgAltBox.innerHTML = img.alt;
        boxInner.appendChild(imgAltBox);
      }

      removeWrappingParagraphTag(box);
    });

    // [9] blockquote: добавляет контейнер
    content.querySelectorAll('blockquote').forEach((bq) => {
      const box = document.createElement('div');
      box.classList.add(s.MoodleContent__blockquoteBox);

      const boxInner = document.createElement('div');
      boxInner.classList.add(s.MoodleContent__blockquoteBoxInner);

      const bqLeft = document.createElement('img');
      bqLeft.classList.add(s.MoodleContent__blockquoteImgLeft);
      bqLeft.setAttribute('src', bqLeftSrc);
      const bqRight = document.createElement('img');
      bqRight.classList.add(s.MoodleContent__blockquoteImgRight);
      bqRight.setAttribute('src', bqRightSrc);

      // Обработка автора цитаты через специальную метку *Author*
      Array.from(bq.innerText.matchAll(/\*Author\*.+\n/g)).forEach((item) => {
        const bqAuthorContent = item[0].trim();

        const bqAuthor = document.createElement('p');
        bqAuthor.classList.add(s.MoodleContent__blockquoteAuthor);

        bqAuthor.innerHTML = bqAuthorContent.replace('*Author*', '');
        bq.innerHTML = bq.innerHTML.replace(bqAuthorContent, bqAuthor.outerHTML);
      });

      box.appendChild(boxInner);
      box.appendChild(bqLeft);
      box.appendChild(bqRight);
      bq.replaceWith(box);

      boxInner.appendChild(bq);
    });

    // [10] table: добавляет контейнер
    content.querySelectorAll('table').forEach((table) => {
      const box = document.createElement('div');
      box.classList.add(s.MoodleContent__tableBox);
      const boxInner = document.createElement('div');
      boxInner.classList.add(s.MoodleContent__tableBoxInner);

      box.style.gridTemplateColumns = `minmax(min-content, ${table.style.width})`;
      table.style.width = '';

      const hasHeader = !!table.querySelector('tr:first-child')?.textContent?.trim();

      if (!hasHeader) {
        const headRow = table.querySelector('tr:first-child') as HTMLTableRowElement;
        if (headRow) {
          headRow.style.display = 'none';
        }
      }

      table.replaceWith(box);
      boxInner.appendChild(table);
      box.appendChild(boxInner);
    });

    // [11] code: добавляет контейнер + кнопку "copy"
    content.querySelectorAll('code').forEach((code, i) => {
      const box = document.createElement('div');
      box.classList.add(s.MoodleContent__codeBox);

      const copy = document.createElement('button');
      copy.id = `copy-button-${i}`;
      copy.classList.add(s.MoodleContent__codeCopyButton);
      copy.innerHTML = `${iconCopy()} <span>Copy</span>`;

      addCallback({
        nodeId: copy.id,
        cbs: {
          onClick: () => copyToClipboard(code.innerText)
        }
      });

      code.replaceWith(box);
      box.appendChild(code);
      box.appendChild(copy);
    });

    // [12] a: удаляет авто-сгенерированные ссылки (class="autolink")
    content.querySelectorAll('.autolink').forEach((link) => {
      const parent = link.parentElement;
      if (parent) {
        parent.innerHTML = link.innerHTML;
      } else {
        const span = document.createElement('span');
        span.innerHTML = link.innerHTML;
        link.after(span);
      }
      link.remove();
    });

    // [13] рекурсивно удаляет все пустые элементы
    (function removeEmptyElements() {
      let done = true;

      content.querySelectorAll('div:empty, p:empty, span:empty').forEach((element) => {
        element.remove();
        done = false;
      });

      if (!done) removeEmptyElements();
    })();

    // [14] включает обработчик математических формул, если они есть
    if (content.querySelector('.filter_mathjaxloader_equation')) {
      // Временно отключил в попытке исправить баг
      // setHasMath(true);
    }

    return serializer.serializeToString(content);
  }, [htmlString, addCallback]);

  // Временно отключил в попытке исправить баг
  // const RootComponent = hasMath ? MathJax : 'div';

  return (
    <MathJax
      className={clsx(s.MoodleContent, className, {
        [s.MoodleContent_wide]: wide
      })}
      dynamic={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={innerRef}
      style={style}
      onClick={handleContentClick}
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  );
}
