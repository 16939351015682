import clsx from 'clsx';
import { Icon, IconSize } from '../../../../../../components/Icon';
import { Icons } from '../../../../../../components/Icons';
import { MoodleContent } from '../../../../../../components/MoodleContent/MoodleContent';
import { Text } from '../../../../../../components/Text';
import s from './QuizQuestionReviewComment.module.scss';

export enum QuizQuestionReviewCommentVariant {
  answer = 'answer',
  comment = 'comment'
}

export interface QuizQuestionReviewCommentProps {
  className?: string;
  text?: string;
  html?: string;
  variant?: QuizQuestionReviewCommentVariant;
}

export function QuizQuestionReviewComment({
  className,
  text,
  html,
  variant = QuizQuestionReviewCommentVariant.answer
}: QuizQuestionReviewCommentProps) {
  return (
    <div className={clsx(s.QuizQuestionReviewComment, className)}>
      <Icon
        className={clsx(s.QuizQuestionReviewComment__icon, s[`QuizQuestionReviewComment__icon_${variant}`])}
        icon={Icons.ADVICE}
        size={IconSize.large}
      />

      <div className={s.QuizQuestionReviewComment__content}>
        {html ? (
          <MoodleContent className={s.QuizQuestionReviewComment__html} wide>
            {html}
          </MoodleContent>
        ) : text ? (
          <Text className={s.QuizQuestionReviewComment__text}>{text}</Text>
        ) : null}
      </div>
    </div>
  );
}
