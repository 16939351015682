import MoodleMessage from '@skolca/moodle-hook';
import { API_URL } from '../../config';
import { MoodleIframeBridge } from './message-base';

export function getMoodleRoute(pathname: string) {
  // return API_URL + pathname.replace(/(\?(.*))?$/, `?v=${Date.now()}&$2`).replace(/&$/, '');
  return API_URL + pathname;
}

export function toMoodleRoute(
  pathname: string,
  target?: Window | null,
  force = true
): Promise<MoodleMessage.PageOpenResponse> {
  return new MoodleIframeBridge<MoodleMessage.PageOpenRequest, MoodleMessage.PageOpenResponse>(
    target ?? window,
    'OPEN_PAGE', // Чтобы не перерабатывать модуль тут проставляем событие, которого нет, т.к. запрос всё равно не будет отправляться, только ожидаться сообщение из iframe
    'LOAD'
  ).send({
    location: getMoodleRoute(pathname),
    // get location() {
    //   const url = getMoodleRoute(pathname);
    //   if (target !== window && target) {
    //     // Тут палка о 2-х концах:
    //     //  - С одной стороны: есть проблема с перенаправлением через URL, поэтому отправляем соотв. запрос в iframe
    //     //  - С другой стороны: там бывают ошибки, из-за которых страница отваливается и, соотв., там moodle.js
    //     //    не грузится - тогда срабатывает именно этот мех-м
    //     target.location = url;
    //   }
    //
    //   return url;
    // },
    force
  });
}
