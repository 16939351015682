import { useEffect } from 'react';
import { QuizQuestionTypeProps } from './types';

export type UseProcessQuestionProps = Pick<QuizQuestionTypeProps, 'setPageProcessData' | 'sequenceCheck'> & {
  answers: { name: string; value: string }[];
};

export function useProcessQuestion({ answers, setPageProcessData, sequenceCheck }: UseProcessQuestionProps) {
  useEffect(() => {
    setPageProcessData((data) => [
      ...data.filter((i) => !answers.some((j) => i.name === j.name || i.name === sequenceCheck?.name)),
      ...answers,
      ...(sequenceCheck ? [sequenceCheck] : [])
    ]);
  }, [answers, setPageProcessData, sequenceCheck]);
}
