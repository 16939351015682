import clsx from 'clsx';
import { CoreEnrolGetUsersCoursesData } from 'store/moodleAPI/moodleTypes/_functions/core_enrol_get_users_courses';
import { ICategory } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_categories';
import { Heading, HeadingSize } from 'components/Heading';
import { Loader, LoaderSize } from 'components/Loader';
import { DataPlaceholder } from 'components/DataPlaceholder/DataPlaceholder';
import { Icons } from 'components/Icons';
import { CourseCard } from 'routes/home/_components/CourseCard/CourseCard';
import s from './TeacherCourses.module.scss';

export interface TeacherCoursesProps {
  courses: CoreEnrolGetUsersCoursesData | undefined;
  categories: (ICategory | undefined)[] | undefined;
  isLoading: boolean;
}

export const TeacherCourses = ({ courses, isLoading, categories }: TeacherCoursesProps) => {
  const noData = !courses?.length;

  return (
    <div className={s.TeacherCourses}>
      <Heading className={s.TeacherCourses__title} component={'h2'} size={HeadingSize.H5}>
        Курсы преподавателя
      </Heading>
      <div
        className={clsx(s.TeacherCourses__list, {
          [s.TeacherCourses__list_centered]: isLoading
        })}>
        {isLoading ? (
          <Loader size={LoaderSize.large} />
        ) : noData ? (
          <DataPlaceholder icon={Icons.PLACEHOLDER_SEARCH} description="Курсы не найдены" />
        ) : (
          courses.map((item, i) => (
            <CourseCard
              key={item.id}
              id={item.id}
              courseimage={item.courseimage}
              shortname={item.shortname}
              progress={item.progress as number}
              hidden={!!item.hidden}
              isfavourite={!!item.isfavourite}
              coursecategory={categories?.[i]?.name}
            />
          ))
        )}
      </div>
    </div>
  );
};
