import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useUsersById } from 'store/moodleAPI/hooks/useUsersById';
import { useCoursesByUserId } from 'store/moodleAPI/hooks/useCoursesByUserId';
import { useCoursesCategories } from 'store/moodleAPI/hooks/useCoursesCategories';
import { Container } from 'components/Container';
import { UserContact, UserContactVariant } from 'components/UserContact/UserContact';
import { PageHelmet } from 'components/PageHelmet';
import { NOT_FOUND_PAGE_ROUTE } from 'app/routes/routes';
import { Loader, LoaderSize } from 'components/Loader';
import { TeacherInfo } from './_components/TeacherInfo/TeacherInfo';
import { TeacherInterests } from './_components/TeacherInterests/TeacherInterests';
import { TeacherCourses } from './_components/TeacherCourses/TeacherCourses';
import cornerSrc from './_assets/teacher-head-corner.svg';
import s from './TeacherPage.module.scss';

export function TeacherPage() {
  const teacherId: string = useParams<{ teacherId: string }>().teacherId ?? '';

  const { data: teacherData, isLoading: teacherLoading } = useUsersById({ ids: [teacherId].filter(Boolean) });

  const teacher = teacherData?.[0];

  const { data: coursesData, isLoading: coursesLoading } = useCoursesByUserId({ userid: +teacherId });

  const categoriesIds = coursesData
    ?.map((course) => {
      return course.category?.toString();
    })
    .join(',');

  const { data: coursesCategoriesData, isLoading: coursesCategoriesLoading } = useCoursesCategories({
    criteria: [
      {
        key: 'ids',
        value: categoriesIds || ''
      }
    ],
    moodleConfig: { skip: !categoriesIds?.length }
  });

  const categories = coursesData?.map((course) => {
    return coursesCategoriesData?.find((item) => item.id === course.category);
  });

  const teacherDescription = teacher?.description;
  const teacherTags = teacher?.interests;

  const teacherCoursesLoading = coursesLoading || coursesCategoriesLoading;

  return (
    <Container
      className={clsx(s.TeacherPage, {
        [s.TeacherPage_centered]: teacherLoading
      })}>
      <PageHelmet title={'Преподаватель'} />

      <img className={s.TeacherPage__corner} src={cornerSrc} alt="" />

      {teacherLoading ? (
        <Loader size={LoaderSize.large} />
      ) : !teacher ? (
        <Navigate to={NOT_FOUND_PAGE_ROUTE} replace />
      ) : (
        <>
          <UserContact
            user={teacher}
            component="div"
            href={`mailto:${teacher.email}`}
            variant={UserContactVariant.profile}
          />
          {teacherDescription && <TeacherInfo description={teacherDescription} />}
          {teacherTags && <TeacherInterests tags={teacherTags} />}
          <TeacherCourses courses={coursesData} isLoading={teacherCoursesLoading} categories={categories} />
        </>
      )}
    </Container>
  );
}
