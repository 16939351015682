import { isNil } from 'lodash-es';
import { useMoodleQuery } from '../useMoodleQuery';
import {
  ModQuizGetAttemptDataData,
  ModQuizGetAttemptDataHookArgs,
  ModQuizGetAttemptDataQuery
} from '../moodleTypes/_functions/mod_quiz_get_attempt_data';

export function useQuizAttemptData({
  moodleConfig,
  attemptid,
  page,
  ...props
}: Partial<ModQuizGetAttemptDataHookArgs> = {}) {
  return useMoodleQuery<ModQuizGetAttemptDataQuery, ModQuizGetAttemptDataData>(
    {
      wsfunction: 'mod_quiz_get_attempt_data',
      attemptid: attemptid ?? 0,
      page: page ?? 0,
      ...props
    },
    {
      ...moodleConfig,
      skip: isNil(attemptid) || isNil(page) || moodleConfig?.skip
    }
  );
}
