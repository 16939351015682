import { Heading, HeadingSize } from 'components/Heading';
import cornerSrc from '../../_assets/profile-head-corner.svg';

import s from './ProfileHead.module.scss';

export interface ProfileHeadProps {
  name: string | undefined;
}

export const ProfileHead: React.FC<ProfileHeadProps> = ({ name }) => {
  return (
    <div className={s.ProfileHead}>
      <Heading className={s.ProfileHead__title} component={'h2'} size={HeadingSize.H2} pageTitle={'Личный кабинет'}>
        Личный кабинет <br /> <span>{name}</span>
      </Heading>
      <img className={s.ProfileHead__corner} src={cornerSrc} alt="" />
    </div>
  );
};
