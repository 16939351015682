import React, { ElementType, JSX, MouseEvent, useMemo } from 'react';
import clsx from 'clsx';
import { PolyExtends } from 'utils/types';
import { Icon, IconProps, IconSize } from 'components/Icon';
import s from './Link.module.scss';

export const LinkDefaultComponent = 'a';
export type LinkDefaultComponentType = typeof LinkDefaultComponent;

export enum LinkVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export enum LinkSize {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export interface LinkPropsSelf<ComponentType extends React.ElementType> {
  /**
   * Дополнительный css-класс корневого элемента
   */
  className?: string;
  /**
   * Основной слот
   */
  children?: React.ReactNode;
  /**
   * Вариант оформления ссылки ("primary", "secondary")
   */
  variant?: LinkVariant;
  /**
   * Вариант размера ссылки ("large", "medium", "small")
   */
  size?: LinkSize;
  /**
   * hover состояние
   */
  hover?: boolean;
  /**
   * Состояние неактивной ссылки. Компонент недоступен для действий пользователя
   */
  disabled?: boolean;
  /**
   * Применять ли фиксированные свойства шрифта.
   * Если не передать standalone, то свойства шрифта наследуются от родителя.
   * Полезно в случае, если Link используется самостоятельно вне текстового блока.
   */
  standalone?: boolean;
  /**
   * Подчеркивание ссылки
   */
  underlined?: boolean;
  /**
   * Реф на корневой элемент
   */
  innerRef?: React.ComponentProps<ComponentType>['ref'];
  /**
   * Размер иконки
   */
  iconSize?: IconSize;
  /**
   * Иконка слева
   */
  leftIcon?: IconProps['icon'];
  /**
   * Иконка справа
   */
  rightIcon?: IconProps['icon'];
  /**
   * Событие клика
   * @param e - объект события
   */
  onClick?: (e: MouseEvent) => void;
}

export interface LinkComponentProps {
  className: string;
  onClick: (e: MouseEvent) => void;
}

export type LinkProps<ComponentType extends React.ElementType<LinkComponentProps> = LinkDefaultComponentType> =
  PolyExtends<ComponentType, LinkPropsSelf<ComponentType>, React.ComponentProps<ComponentType>>;

export function Link(props: LinkProps<'a'>): JSX.Element;
export function Link<ComponentType extends ElementType>(props: LinkProps<ComponentType>): JSX.Element;
export function Link<ComponentType extends React.ElementType<LinkComponentProps> = LinkDefaultComponentType>({
  className,
  children,
  variant = LinkVariant.primary,
  size = LinkSize.medium,
  disabled,
  standalone,
  underlined = true,
  onClick,
  component,
  innerRef,
  leftIcon,
  rightIcon,
  iconSize = IconSize.small,
  hover,
  ...props
}: LinkProps<ComponentType>) {
  const handleClick = (e: MouseEvent) => {
    if (disabled) e.preventDefault();
    onClick?.(e);
  };

  const Component = component || LinkDefaultComponent;

  const iconProps: IconProps = useMemo(
    () => ({
      size: iconSize
    }),
    [iconSize]
  );

  // Тайпскрипт жалуется на сложный union тип, поэтому используем createElement
  return React.createElement(
    Component,
    {
      ref: innerRef,
      className: clsx(
        s.Link,
        s[`Link_variant_${variant}`],
        s[`Link_size_${size}`],
        {
          [s.Link_underlined]: underlined,
          [s.Link_disabled]: disabled,
          [s.Link_standalone]: standalone,
          [s.Link_hover]: hover
        },
        className
      ),
      onClick: handleClick,
      ...props
    },
    <>
      {leftIcon && <Icon className={clsx(s.Link__icon, s.Link__icon_left)} icon={leftIcon} {...iconProps} />}
      <span className={s.Link__content}>{children}</span>
      {rightIcon && <Icon className={clsx(s.Link__icon, s.Link__icon_right)} icon={rightIcon} {...iconProps} />}
    </>
  );
}
