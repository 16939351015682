import { memo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import { Icons } from 'components/Icons';
import s from './Loader.module.scss';

export enum LoaderSize {
  /**
   * Размер лоадера 16х16
   */
  small = 'small',
  /**
   * Размер лоадера 24х24
   */
  medium = 'medium',
  /**
   * Размер лоадера 32х32
   */
  large = 'large'
}

export interface LoaderProps {
  size?: LoaderSize;
  className?: string;
}

export const Loader = memo((props: LoaderProps) => {
  const { className, size = LoaderSize.small } = props;

  return (
    <div className={clsx(s.Loader, className)}>
      <Icon className={clsx(s.LoaderIcon, [s[`LoaderIcon_size_${size}`]])} icon={Icons.SPINNER} />
    </div>
  );
});

Loader.displayName = 'Loader';
