import { Heading, HeadingSize } from 'components/Heading';
import { Badge, BadgeVariant, BadgeStyle, BadgeBorder, BadgeSize } from 'components/Badge';
import s from './TeacherInterests.module.scss';

export interface TeacherInterestsProps {
  tags: string;
}

export const TeacherInterests = ({ tags }: TeacherInterestsProps) => {
  return (
    <div className={s.TeacherInterests}>
      <Heading className={s.TeacherInterests__title} component={'h2'} size={HeadingSize.H5}>
        Интересы
      </Heading>
      <div className={s.TeacherInterests__tags}>
        {tags.split(',').map((tag, index) => (
          <Badge
            key={index}
            variant={BadgeVariant.neutral}
            style={BadgeStyle.filled}
            border={BadgeBorder.round}
            size={BadgeSize.L}>
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );
};
