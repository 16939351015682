import React from 'react';
import clsx from 'clsx';
import { AsideContainer, AsideContainerVariant } from 'components/AsideContainer/AsideContainer';
import { Heading, HeadingSize } from 'components/Heading';
import { useIsTablet } from 'hooks/useIsTablet';
import { useModulePage } from './ModulePageProvider';
import { CourseProgram } from './_components/CourseProgram/CourseProgram';
import s from './ModulePage.module.scss';

export const ModulePageAside = () => {
  const isTablet = useIsTablet();
  const { data } = useModulePage();

  if (isTablet) return null;

  const content = (() => {
    return <CourseProgram data={data} />;
    // # Если понадобавится разделять по типу модуля
    // switch (module?.modname) {
    //   case ModuleName.lesson:
    //     return <>CONTENT</>;
    //   default:
    //     return null;
    // }
  })();

  if (!content) return null;

  return (
    <AsideContainer
      classes={{
        root: (extended) => {
          return clsx(s.ModulePageAside, {
            [s.ModulePageAside_extended]: extended
          });
        },
        toggle: (extended) => {
          return clsx(s.ModulePageAside__toggle, {
            [s.ModulePageAside__toggle_extended]: extended
          });
        },
        content: () => s.ModulePageAside__content
      }}
      variant={AsideContainerVariant.Right}
      defaultExtended>
      {(extended) =>
        extended && (
          <>
            <Heading className={s.ModulePageAside__heading} component={'h4'} size={HeadingSize.H4}>
              Структура курса
            </Heading>
            <div className={s.ModulePageAside__moduleContent}>{content}</div>
          </>
        )
      }
    </AsideContainer>
  );
};
