import React, { PropsWithChildren } from 'react';
import { Collapse, CollapseProps, CollapseSize, CollapseVariant } from 'components/Collapse';
import { CoursesUtils } from 'utils/courses/CoursesUtils';
import { useTooltip } from 'components/Tooltip';
import { ISection } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_contents';
import { htmlToInlineText } from 'utils/html';
import { ModulePageContextType } from '../../ModulePageProvider';
import { Modules } from '../Modules/Modules';
import s from './CourseProgram.module.scss';

interface SectionCollapseProps extends CollapseProps {
  section: ISection;
}

const SectionCollapse = ({ section, ...props }: PropsWithChildren<SectionCollapseProps>) => {
  const locked = props.variant === CollapseVariant.Locked;

  const { component: tooltip, setTargetRef } = useTooltip({
    placement: 'bottom',
    title: 'Заблокирован',
    description: htmlToInlineText(section.availabilityinfo),
    enabled: locked
  });

  return (
    <>
      <Collapse ref={setTargetRef} {...props} />
      {tooltip}
    </>
  );
};

export interface CourseProgramProps {
  data: ModulePageContextType['data'];
}

export const CourseProgram = ({ data }: CourseProgramProps) => {
  const { moduleId, course, contents } = data;

  return (
    <div className={s.CourseProgram}>
      {contents?.map((item, iItem) => {
        const variant = CoursesUtils.mapCourseContentToCollapseVariant(item);
        return (
          <SectionCollapse
            key={iItem}
            index={iItem + 1}
            title={item.name}
            variant={variant}
            size={CollapseSize.Small}
            open={!!moduleId && item.modules.some((m) => m.id === +moduleId)}
            section={item}>
            <Modules
              index={iItem + 1}
              courseId={String(course?.id)}
              modules={item.modules}
              sectionLocked={variant === CollapseVariant.Locked}
            />
          </SectionCollapse>
        );
      })}
    </div>
  );
};
