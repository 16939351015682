import { useEnrolledCourses } from 'store/moodleAPI/hooks';
import { useUsersById } from 'store/moodleAPI/hooks/useUsersById';
import { CoreCourseGetEnrolledCoursesClassification } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';
import { MoodleQueryBaseWithDataReturn } from 'store/moodleAPI';
import { CoreUserGetUsersByFieldData } from 'store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';
import { useCourseContentData, UseCourseContentDataReturnType } from './useCourseContentData';

export type CourseDataCustomFields = {
  favourite: boolean;
  hidden: boolean;
};

export type UseCourseDataReturnType = MoodleQueryBaseWithDataReturn<
  any,
  UseCourseContentDataReturnType['data'] & {
    schedule?: any;
    teachers?: CoreUserGetUsersByFieldData;
    customFields?: {
      favourite: boolean;
      hidden: boolean;
    };
  }
>;

export function useCourseData(id?: string | number): UseCourseDataReturnType {
  const {
    data: { course, contents },
    isLoading: contentLoading,
    error: contentError
  } = useCourseContentData(id);

  /**
   * useEnrolledCourses - нужен для получения доп. полей (customFields) о курсе (isfavourite, hidden, etc..)
   */
  const {
    data: enrolledCourses,
    isLoading: enrolledCoursesLoading,
    error: enrolledCoursesError
  } = useEnrolledCourses({
    classification: CoreCourseGetEnrolledCoursesClassification.Search,
    searchvalue: course?.fullname,
    moodleConfig: {
      skip: !course
    }
  });
  const enrolledCourse = enrolledCourses?.courses?.find((c) => c.id === course?.id);

  const customFields = !enrolledCourse
    ? undefined
    : ({
        favourite: enrolledCourse.isfavourite,
        hidden: enrolledCourse.hidden
      } as CourseDataCustomFields);

  const {
    data: teachers,
    isLoading: teachersLoading,
    error: teachersError
  } = useUsersById({
    ids: course?.contacts.map((c) => c.id.toString())
  });

  const isLoading = contentLoading || enrolledCoursesLoading || teachersLoading;
  const error = contentError || enrolledCoursesError || teachersError;

  return {
    data: {
      course,
      contents,
      schedule: undefined,
      teachers,
      customFields
    },
    isLoading,
    error
  };
}
