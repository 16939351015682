import { isNil } from 'lodash-es';
import { useCoursesByField } from 'store/moodleAPI/hooks/useCoursesByField';
import { useCourseContents } from 'store/moodleAPI/hooks/useCourseContents';
import { MoodleQueryBaseWithDataReturn } from 'store/moodleAPI';
import { ArrayElement } from 'utils/types';
import { CoreCourseGetCoursesByFieldData } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';
import { CoreCourseGetContentsData } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_contents';

export type UseCourseContentDataReturnType = MoodleQueryBaseWithDataReturn<
  any,
  {
    course?: ArrayElement<CoreCourseGetCoursesByFieldData['courses']>;
    contents?: CoreCourseGetContentsData;
  }
> & {
  refetchCourses: () => void;
  refetchContents: () => void;
};

export function useCourseContentData(id?: string | number): UseCourseContentDataReturnType {
  const {
    data: courses,
    isLoading: coursesLoading,
    error: coursesError,
    mutate: coursesMutate
  } = useCoursesByField({
    field: isNil(id) ? undefined : 'id',
    value: id
  });
  const course = courses?.courses?.[0];

  const {
    data: contents,
    isLoading: contentsLoading,
    error: contentsError,
    mutate: contentsMutate
  } = useCourseContents({
    courseid: id
  });

  const isLoading = coursesLoading || contentsLoading;
  const error = coursesError || contentsError;

  return {
    data: {
      course,
      contents
    },
    isLoading,
    error,
    refetchCourses: coursesMutate,
    refetchContents: contentsMutate
  };
}
